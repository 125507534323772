import { createAsyncThunk } from '@reduxjs/toolkit';

import { chargebacksResource } from './api';

import { getPage, getRowsPerPage, getSort } from './selectors';
import { LoadItems } from 'entities';
import { isMerchantSelector } from '../../account/selectors';
import { RootStateType } from 'store/store';

export const loadItems: any = createAsyncThunk(
  'drChargebacks/loadItems',
  async (options: LoadItems, thunkApi) => {
    try {
      const { getState } = thunkApi;

      const page = getPage(getState() as RootStateType);
      const sort = getSort(getState() as RootStateType);
      const rowsPerPage = getRowsPerPage(getState() as RootStateType);
      const isMerchantRole = isMerchantSelector(getState() as RootStateType);

      const params = {
        page,
        offset: (page - 1) * rowsPerPage,
        limit: rowsPerPage,
        sort: sort,
        ...(options || {}),
      };

      return !isMerchantRole
        ? await chargebacksResource.chargebacksList(params)
        : await chargebacksResource.merchantChargebacksList(params);
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

export const fetchDetails: any = createAsyncThunk(
  'drChargebacks/fetchDetails',
  async (data: { id: string }, thunkApi) => {
    try {
      const { getState } = thunkApi;
      const isMerchantRole = isMerchantSelector(getState() as RootStateType);

      const response = !isMerchantRole
        ? await chargebacksResource.fetchDetails(data)
        : await chargebacksResource.fetchMerchantDetails(data);
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
);
