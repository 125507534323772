import { createAsyncThunk } from '@reduxjs/toolkit';

import { programsResource } from './api';
import { getAppliedFilters, getPage, getRowsPerPage } from './selectors';
import { dateTimeZone } from 'domain/account/selectors';
import { dateTimeFormatUtil } from 'components/dateTimeFormatUtil';
import { RootStateType } from 'store/store';

export const fetchServiceMonitor: any = createAsyncThunk(
  'serviceMonitorStore/fetchServiceMonitor',
  async (data: any, thunkApi) => {
    try {
      const { getState } = thunkApi;
      const page = getPage(getState() as RootStateType);
      const rowsPerPage = getRowsPerPage(getState() as RootStateType);
      const appliedFilters = getAppliedFilters(getState() as RootStateType);

      const dateZone = dateTimeZone(getState() as RootStateType);
      const dateFormatVal = (value: any) =>
        dateTimeFormatUtil(value, 'YYYY-MM-DD', dateZone);

      const params = {
        page,
        limit: rowsPerPage,
        day: dateFormatVal(appliedFilters.day?.from),
        offset: (page - 1) * rowsPerPage,
      };

      return await programsResource.serviceMonitorListPost(params);
    } catch (e) {
      return Promise.reject(e);
    }
  }
);
