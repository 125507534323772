import ChevronLeft from '@mui/icons-material/ChevronLeft';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

import { ACCESS_TOKEN_HEADER_KEY } from 'constants/accessTokenHeaderKey';
import { checkResetPasswordToken } from 'domain/account/thunks';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { FormChangePassword } from './FormChangePassword';
import { getInvalidToken, getIsLoading } from 'domain/account/selectors';
import { Loading } from 'components/Loading';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const BackToLogin = () => {
  const { t } = useTranslation();

  const backMessage = sessionStorage.getItem(ACCESS_TOKEN_HEADER_KEY)
    ? t('changePassword.goBackMainPage')
    : t('resetPasswordForm.backToLogin');

  return (
    <Link
      to="/login"
      className="mt-5 text-sm font-semibold text-blue-500 flex items-center"
    >
      <ChevronLeft className="mr-2" fontSize="small" />
      <span className="block h-5">{backMessage}</span>
    </Link>
  );
};

export const Container = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const invalidToken = useAppSelector(getInvalidToken);
  const isLoading = useAppSelector(getIsLoading);

  const [submitted, setSubmitted] = useState<boolean>(false);

  const { token } = useParams<{ token: string }>();

  useEffect(() => {
    if (token) {
      dispatch(
        checkResetPasswordToken({
          confirmationCode: token,
        })
      );
    }
  }, [dispatch, token]);

  if (
    (token && (isLoading || invalidToken)) ||
    (!sessionStorage.getItem(ACCESS_TOKEN_HEADER_KEY) && !token)
  ) {
    return (
      <div className="flex items-center justify-center w-full min-h-screen">
        {isLoading && <Loading className="text-blue-500" />}
        {!isLoading && (
          <div className="text-center">
            <p className="text-sm font-medium text-red-500">
              {t('changePassword.cantChangePass')}
            </p>
            <BackToLogin />
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="flex justify-center w-full min-h-screen py-12">
      <div className="flex flex-col items-center lg:justify-center w-full max-w-sm px-3">
        <h3 className="mb-8 text-2xl font-bold text-gray-900">
          {submitted
            ? t('resetPasswordForm.submittedTitle')
            : t('changePassword.title')}
        </h3>
        {!submitted && (
          <>
            {token ? (
              <GoogleReCaptchaProvider
                reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ''}
              >
                <FormChangePassword
                  token={token}
                  onSubmit={() => {
                    setSubmitted(true);
                  }}
                />
              </GoogleReCaptchaProvider>
            ) : (
              <FormChangePassword
                token={token}
                onSubmit={() => {
                  setSubmitted(true);
                }}
              />
            )}
            <BackToLogin />
          </>
        )}
      </div>
    </div>
  );
};
