import { useTranslation } from 'react-i18next';
// import { toast } from 'react-toastify';
import React, { useEffect, useState } from 'react';

import { menuItemsMap, menuSubItemsMap } from 'layouts/app/utils';
import { FilterSelect } from 'components/filters/FilterSelect';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const formatGroupLabel = ({ label }: any) => {
  return (
    <div>
      <span>{menuItemsMap[label].label}</span>
    </div>
  );
};

interface SelectUiTabsProps {
  items: any;
  defaultItems?: any;
  control: any;
  error: any;
}

const SelectUiTabs: React.FC<SelectUiTabsProps> = ({
  items,
  defaultItems,
  control,
  error,
}) => {
  const { t } = useTranslation();

  const [snackbars, setSnackbars] = useState<
    { message: string; key: number }[]
  >([]);
  const handleCloseSnackbar = (key: number) => {
    setSnackbars((prev) => prev.filter((snackbar) => snackbar.key !== key));
  };

  useEffect(() => {
    const missingOptions = defaultItems.filter(
      (defaultItem) => !menuSubItemsMap[defaultItem.value]
    );

    if (missingOptions.length) {
      missingOptions.forEach((option) => {
        setSnackbars((prev) => [
          ...prev,
          {
            message: `${option.value} is no longer available in Tab field`,
            key: Date.now(),
          },
        ]);
      });
    }
  }, [defaultItems]);

  return (
    <div>
      <label
        className="relative block text-gray-700 font-medium tracking-wide text-xs"
        htmlFor="select-controller-tabs"
      >
        {t('userManagement.roleDetails.tabs')}
        {error && (
          <span className="absolute right-0 mt-1 text-xs font-medium text-red-500">
            {t('validations.required')}
          </span>
        )}
      </label>
      <FilterSelect
        key={defaultItems?.length}
        labelClassName="font-medium tracking-wide text-xs text-gray-700"
        name="tabs"
        control={control}
        options={items}
        error={error}
        formatGroupLabel={formatGroupLabel}
        getOptionLabel={(option) => {
          return menuSubItemsMap[option.value]
            ? menuSubItemsMap[option.value].label
            : menuSubItemsMap.TAB_VALUE_DOES_NOT_EXIST.label;
        }}
      />
      {snackbars.map(({ message, key }) => (
        <Snackbar
          key={key}
          open
          autoHideDuration={3000}
          onClose={() => handleCloseSnackbar(key)}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert
            onClose={() => handleCloseSnackbar(key)}
            severity="warning"
            sx={{ wordBreak: 'break-word', whiteSpace: 'break-spaces' }}
          >
            {message}
          </Alert>
        </Snackbar>
      ))}
    </div>
  );
};

export default SelectUiTabs;
