import React, { useEffect, useState } from 'react';
import Select from 'react-select';

import { ItemAttributes } from 'components/itemAttributeType';
import { useAppDispatch } from 'hooks/redux';
import { Label } from 'components/inputs';
import {
  SelectorInput,
  SelectorOption,
  stylesStyles,
} from 'components/inputs/SelectHelper';

interface SelectControllerProps {
  name: string;
  value: ItemAttributes | null;
  label?: string | React.ReactElement;
  placeholder?: string;
  loadOptions?: any;
  error?: any;
  height?: string;
  options?: ItemAttributes[];
  onSelect?: (option: any) => void;
  isMulti?: boolean;
  isClearable?: boolean;
  closeMenuOnSelect?: boolean;
  className?: string;
  isDisabled?: boolean;
  required?: boolean;
  onChange?: (options: any) => void;
  loadOptionsParam?: any;
}

export const SelectNoController: React.FC<SelectControllerProps> = ({
  name,
  label = '',
  value,
  height = '',
  placeholder = ' ',
  loadOptions,
  loadOptionsParam,
  error,
  options = [],
  isMulti = false,
  isClearable = true,
  closeMenuOnSelect = false,
  className = '',
  isDisabled = false,
  onChange,
}) => {
  const dispatch = useAppDispatch();

  const [isLoading, setLoading] = useState<boolean>(false);
  const [list, setList] = useState<ItemAttributes[]>(options);

  useEffect(() => {
    if (loadOptions) {
      const fetchOptions = async () => {
        setLoading(true);
        const data = await loadOptions();
        setList(data);
        setLoading(false);
      };

      fetchOptions();
    }

    if (loadOptionsParam) {
      const fetchOptions = async () => {
        setLoading(true);
        const data = await loadOptionsParam;
        setList(data);
        setLoading(false);
      };

      fetchOptions();
    }
  }, [dispatch, loadOptions, loadOptionsParam]);

  return (
    <Label name="roleId" label={label} error={error}>
      <Select
        inputId={`select-controller-${name}`}
        components={{ Option: SelectorOption, Input: SelectorInput }}
        placeholder={
          <span className="text-gray-600 cursor-pointer text-xs font-bold">
            {placeholder}
          </span>
        }
        onChange={onChange}
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
        openMenuOnFocus={false}
        className={className}
        styles={stylesStyles({
          error,
          label,
          height,
          singleValue: {
            fontWeight: '700',
            fontSize: '0.75rem',
          },
        })}
        closeMenuOnSelect={closeMenuOnSelect}
        menuPortalTarget={document.body}
        hideSelectedOptions={false}
        isClearable={isClearable}
        isLoading={isLoading}
        menuPosition="fixed"
        menuPlacement="auto"
        isMulti={isMulti}
        options={list}
        value={value}
        isDisabled={isDisabled}
      />
    </Label>
  );
};
