import { createSelector } from '@reduxjs/toolkit';
import { storeName } from './complianceAuditSlice';
import { RootStateType } from 'store/store';

export const getRoot = (state: RootStateType) => state[storeName];

export const getTotalSalesItems = createSelector(
  [getRoot],
  ({ totalSalesItems }) => totalSalesItems
);

export const getItemsLoadingComputed = createSelector(
  [getRoot],
  ({ itemsLoading }) => itemsLoading
);

export const getTotalSalesPerDayLoading = createSelector(
  [getRoot],
  ({ totalSalesPerDayLoading }) => totalSalesPerDayLoading
);

export const getTotalSalesPerMonthLoading = createSelector(
  [getRoot],
  ({ totalSalesPerMonthLoading }) => totalSalesPerMonthLoading
);

export const getTotalSalesRollingPer7DayLoading = createSelector(
  [getRoot],
  ({ totalSalesRollingPer7DayLoading }) => totalSalesRollingPer7DayLoading
);

export const getTotalSalesRollingPer30DayLoading = createSelector(
  [getRoot],
  ({ totalSalesRollingPer30DayLoading }) => totalSalesRollingPer30DayLoading
);

export const getTotalSalesRollingPerMonthLoading = createSelector(
  [getRoot],
  ({ totalSalesRollingPerMonthLoading }) => totalSalesRollingPerMonthLoading
);
