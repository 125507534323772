import { Children, cloneElement, ReactElement, useState } from 'react';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { useTranslation } from 'react-i18next';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';

const GenerateDrawer = ({ children }) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="px-3 py-2 flex-1">
      <Button onClick={toggleDrawer} startIcon={<NoteAddIcon />}>
        <span className="normal-case">{t('generate.generate')}</span>
      </Button>
      <SwipeableDrawer
        anchor="right"
        open={isOpen}
        onClose={toggleDrawer}
        onOpen={toggleDrawer}
      >
        <div className="p-6 sm:w-[370px] h-full overflow-y-auto custom-scrollbar flex flex-col">
          <div className="flex items-center justify-between pb-1">
            <p className="text-lg font-semibold">{t('generate.title')}</p>
            <IconButton aria-label="close" onClick={toggleDrawer} size="small">
              <CloseIcon />
            </IconButton>
          </div>
          <Divider />
          <div className="mt-4 flex-1">
            {Children.map(children, (child: ReactElement) =>
              cloneElement(child, { ...toggleDrawer })
            )}
          </div>
        </div>
      </SwipeableDrawer>
    </div>
  );
};

export default GenerateDrawer;
