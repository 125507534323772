import { useMemo } from 'react';
import { ReportData, ReportTypes } from '../../utils';
import { useReportMerchantsColumns } from './cols/useReportMerchantsColumns';
import { useReportColumns } from './useReportColumns';
import moment from 'moment/moment';

export const useSelectedReport = (reportUrl: string) => {
  const merchantColumnProps = useReportMerchantsColumns();
  const dailyCols = useReportColumns([]);
  const dailyMerchantCols = useReportColumns([merchantColumnProps]);
  const maxDate = moment().subtract(1, 'months').toDate();
  const maxDateNow = moment().toDate();

  const maxDate7Days =
    moment().date() > 7
      ? moment().subtract(1, 'months').toDate()
      : moment().subtract(2, 'months').toDate();

  const maxDate7DayExtract = moment().subtract(7, 'days').toDate();
  const maxDate1Subtract = moment().subtract(1, 'days').toDate();

  const maxDateExecutive =
    moment().date() < 22
      ? moment().subtract(1, 'months').toDate()
      : moment().toDate();

  return useMemo((): ReportData | undefined => {
    switch (true) {
      case reportUrl.includes(ReportTypes.MERCHANT_MONTHLY_CLIENT.link):
        return {
          reportType: ReportTypes.MERCHANT_MONTHLY_CLIENT.typeName,
          reportName: ReportTypes.MERCHANT_MONTHLY_CLIENT.name,
          reportCols: dailyMerchantCols,
          hasMerchant: true,
          isYearDate: true,
          generateMaxDate: maxDate,
          filterMaxDate: maxDate,
        };
      case reportUrl.includes(
        ReportTypes.MONTHLY_INTERNAL_MERCHANT_PORTFOLIO.link
      ):
        return {
          reportType: ReportTypes.MONTHLY_INTERNAL_MERCHANT_PORTFOLIO.typeName,
          reportName: ReportTypes.MONTHLY_INTERNAL_MERCHANT_PORTFOLIO.name,
          reportCols: dailyCols,
          isYearDate: true,
          generateMaxDate: maxDate7Days,
          filterMaxDate: maxDate7Days,
          hasMultipleDownloads: true,
        };
      case reportUrl.includes(ReportTypes.MONTHLY_EXCESSIVE_ACTIVITY.link):
        return {
          reportType: ReportTypes.MONTHLY_EXCESSIVE_ACTIVITY.typeName,
          reportName: ReportTypes.MONTHLY_EXCESSIVE_ACTIVITY.name,
          reportCols: dailyCols,
          isYearDate: true,
          generateMaxDate: maxDateExecutive,
          filterMaxDate: maxDate,
        };
      case reportUrl.includes(ReportTypes.PORTFOLIO_TRANSACTION_SUMMARY.link):
        return {
          reportType: ReportTypes.PORTFOLIO_TRANSACTION_SUMMARY.typeName,
          reportName: ReportTypes.PORTFOLIO_TRANSACTION_SUMMARY.name,
          reportCols: dailyCols,
          hasMerchant: true,
          isYearDate: true,
          generateMaxDate: maxDateExecutive,
          filterMaxDate: maxDateExecutive,
        };
      case reportUrl.includes(ReportTypes.GENERAL_ACCOUNT_DATA.link):
        return {
          reportType: ReportTypes.GENERAL_ACCOUNT_DATA.typeName,
          reportName: ReportTypes.GENERAL_ACCOUNT_DATA.name,
          reportCols: dailyMerchantCols,
          hasMerchant: true,
          generateMaxDate: maxDateNow,
          filterMaxDate: maxDateNow,
        };
      case reportUrl.includes(
        ReportTypes.DAILY_EXCEPTION_EXCESSIVE_ACTIVITY_LIVE.link
      ):
        return {
          reportType:
            ReportTypes.DAILY_EXCEPTION_EXCESSIVE_ACTIVITY_LIVE.typeName,
          reportName: ReportTypes.DAILY_EXCEPTION_EXCESSIVE_ACTIVITY_LIVE.name,
          reportCols: dailyCols,
          generateMaxDate: maxDate1Subtract,
          filterMaxDate: maxDate1Subtract,
          hasMultipleDownloads: true,
        };
      case reportUrl.includes(
        ReportTypes.DAILY_EXCEPTION_EXCESSIVE_ACTIVITY.link
      ):
        return {
          reportType: ReportTypes.DAILY_EXCEPTION_EXCESSIVE_ACTIVITY.typeName,
          reportName: ReportTypes.DAILY_EXCEPTION_EXCESSIVE_ACTIVITY.name,
          reportCols: dailyCols,
          generateMaxDate: maxDate7DayExtract,
          filterMaxDate: maxDate7DayExtract,
          hasMultipleDownloads: true,
        };
      case reportUrl.includes(ReportTypes.DAILY_TRANSACTIONS.link):
        return {
          reportType: ReportTypes.DAILY_TRANSACTIONS.typeName,
          reportName: ReportTypes.DAILY_TRANSACTIONS.name,
          reportCols: dailyMerchantCols,
          hasMerchant: true,
          hasMultipleMerchant: true,
          generateMaxDate: maxDateNow,
          filterMaxDate: maxDateNow,
        };
      case reportUrl.includes(ReportTypes.DAILY_TRANSACTION_SUMMARY.link):
        return {
          reportType: ReportTypes.DAILY_TRANSACTION_SUMMARY.typeName,
          reportName: ReportTypes.DAILY_TRANSACTION_SUMMARY.name,
          reportCols: dailyMerchantCols,
          hasMerchant: true,
          hasMultipleMerchant: true,
          generateMaxDate: maxDateNow,
          filterMaxDate: maxDateNow,
        };
      case reportUrl.includes(ReportTypes.DAILY_EXCESSIVE_ACTIVITY.link):
        return {
          reportType: ReportTypes.DAILY_EXCESSIVE_ACTIVITY.typeName,
          reportName: ReportTypes.DAILY_EXCESSIVE_ACTIVITY.name,
          reportCols: dailyMerchantCols,
          hasMerchant: true,
          hasMultipleMerchant: true,
          generateMaxDate: maxDateNow,
          filterMaxDate: maxDateNow,
        };
      case reportUrl.includes(ReportTypes.DAILY_EXCEPTION_REFUND.link):
        return {
          reportType: ReportTypes.DAILY_EXCEPTION_REFUND.typeName,
          reportName: ReportTypes.DAILY_EXCEPTION_REFUND.name,
          reportCols: dailyMerchantCols,
          hasMerchant: true,
          generateMaxDate: maxDateNow,
          filterMaxDate: maxDateNow,
        };
      case reportUrl.includes(ReportTypes.DAILY_EXCEPTION_BATCH.link):
        return {
          reportType: ReportTypes.DAILY_EXCEPTION_BATCH.typeName,
          reportName: ReportTypes.DAILY_EXCEPTION_BATCH.name,
          reportCols: dailyMerchantCols,
          hasMerchant: true,
          generateMaxDate: maxDateNow,
          filterMaxDate: maxDateNow,
        };
      case reportUrl.includes(ReportTypes.DAILY_EXCEPTION_TICKET.link):
        return {
          reportType: ReportTypes.DAILY_EXCEPTION_TICKET.typeName,
          reportName: ReportTypes.DAILY_EXCEPTION_TICKET.name,
          reportCols: dailyMerchantCols,
          hasMerchant: true,
          generateMaxDate: maxDateNow,
          filterMaxDate: maxDateNow,
        };
      case reportUrl.includes(ReportTypes.DUPLICATE_TRANSACTION.link):
        return {
          reportType: ReportTypes.DUPLICATE_TRANSACTION.typeName,
          reportName: ReportTypes.DUPLICATE_TRANSACTION.name,
          reportCols: dailyCols,
          generateMaxDate: maxDateNow,
          filterMaxDate: maxDateNow,
        };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportUrl]);
};
