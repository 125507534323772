import { createSelector } from '@reduxjs/toolkit';
import { getSidebar } from 'domain/account/selectors';

import { storeName } from './rolesSlice';
import { mappedUiTabs } from './utils';
import { RootStateType } from 'store/store';

export const getRoot = (state: RootStateType) => state[storeName];

export const getPage = createSelector([getRoot], ({ page }) => page);

export const getSort = createSelector([getRoot], ({ sort }) => sort);
export const getAppliedFilters = createSelector(
  [getRoot],
  ({ appliedFilters }) => appliedFilters
);

export const getRowsPerPage = createSelector(
  [getRoot],
  ({ rowsPerPage }) => rowsPerPage
);

export const getItems = createSelector([getRoot], ({ items }) => items);
export const getTotalNumber = createSelector(
  [getRoot],
  ({ totalNumber }) => totalNumber
);

export const getPagination = createSelector(
  [getRoot],
  ({
    items,
    page,
    endReached,
    rowsPerPage,
    paginationLoading,
    itemsLoading,
  }) => ({
    total: items.length,
    page,
    endReached,
    rowsPerPage,
    paginationLoading,
    itemsLoading,
  })
);

export const getDetails = createSelector(
  [getRoot, getSidebar],
  ({ details }) => details
);

export const getRoleSidebar = createSelector([getSidebar], ({ sidebar }) =>
  mappedUiTabs(sidebar)
);

export const getItemsLoadingComputed = createSelector(
  [getRoot],
  ({ itemsLoading }) => itemsLoading
);
export const getisDetailsLoading = createSelector(
  [getRoot],
  ({ isDetailsLoading }) => isDetailsLoading
);
