import { Controller } from 'react-hook-form';
import { DatePickerDropDown } from 'components/DatePickerDropDown';
import { RequiredFieldComponent } from 'components/RequiredFiledComponent';

import React from 'react';
import { useSelector } from 'react-redux';
import { dateTimeFormat, dateTimeZone } from 'domain/account/selectors';
import moment from 'moment-timezone';

interface DateTimeComponentProps {
  name: string;
  control: any;
  labelText: string;
  placeholder: string;
  isRequired?: boolean;
  minDate?: Date;
  maxDate?: Date;
}
export const DateTimeComponent: React.FC<DateTimeComponentProps> = ({
  name,
  control,
  labelText,
  placeholder,
  isRequired = false,
  minDate,
  maxDate,
}) => {
  const dateFormat = useSelector(dateTimeFormat);
  const dateZone = useSelector(dateTimeZone);
  const generationDate = (value: any) =>
    value ? moment(value).tz(dateZone).format(dateFormat) : '';

  return (
    <div className="w-full">
      <Controller
        name={name}
        control={{ ...control }}
        render={({ field: { value, onChange } }) => (
          <DatePickerDropDown
            title={'Select Date'}
            minDate={minDate}
            maxDate={maxDate}
            label={
              <RequiredFieldComponent
                isRequired={isRequired}
                labelText={labelText}
              />
            }
            labelClass="font-medium tracking-wide text-xxs text-gray-700"
            placeholder={placeholder}
            generationDate={generationDate(value)}
            footer={<></>}
            inputHeightClassName="h-8"
            handleDateChange={(date) =>
              onChange(moment(date).tz(dateZone).format('yyyy-MM-DD'))
            }
            isMonthYearPicker={false}
            isBirthdatePicker={true}
          />
        )}
      />
    </div>
  );
};
