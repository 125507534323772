import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'hooks/redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  accountHolderTypeList,
  accountTypeList,
  conditionResultList,
  countryStateList,
  getMerchantDetailsIconColor,
  internalRiskRatingList,
  localeList,
  merchantLimitsDetails,
  merchantsConfigDetails,
  merchantStatusList,
  merchantTypeList,
  rapidConnectMerchantDetails,
  rapidConnectStatusList,
  reportsConfigDetails,
  reportsTypeList,
  stateList,
  storageTypeList,
  timeZoneList,
} from '../utils';
import { useEffect, useMemo, useRef, useState } from 'react';
import { UpdateDetailsComponent } from './components/UpdateDetailsComponent';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { AccountHolderDetailsInputComponent } from './components/AccountHolderDetailsInputComponent';
import {
  createRapidConnectDetails,
  updateAccountHolderDetails,
  updateMerchantConfigDetails,
  updateMerchantConfigLimitDetails,
  updateMerchantDetails,
  updateMerchantToggleStatus as updateMerchant,
  updateRapidConnectDetails,
  updateReportsConfigDetails,
} from '../thunks';
import { ReportConfigurationDetailsInputComponent } from './components/ReportConfigurationDetailsInputComponent';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import { MerchantConfigDetailsInputComponent } from './components/MerchantConfigDetailsInputComponent.tsx';
import StorefrontIcon from '@mui/icons-material/Storefront';
import { MerchantDetailsInputComponent } from './components/MerchantDetailsInputComponent';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { MerchantToggleStatusComponent } from './components/MerchantToggleStatusComponent.tsx';
import moment from 'moment-timezone';
import { isEqual } from 'lodash';
import { MerchantRapidConnectComponent } from './components/MerchantRapidConnectComponent';
import MemoryIcon from '@mui/icons-material/Memory';
import { useSelector } from 'react-redux';
import {
  getCountryCodes,
  getDetails,
  getIsDetailsLoading,
  getItemNotFound,
} from '../selectors';

type EditProps = {
  pageName: string;
  iconColor: string;
  componentUpdate: any;
  icon: any;
  iconText: string;
  updateMethod: any;
};

const editPropsDetails = {
  pageName: 'No Details',
  iconColor: '',
  componentUpdate: <></>,
  icon: <></>,
  iconText: '',
  updateMethod: undefined,
};
export const EditMerchantDetails = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { component } = useParams<{ component: string }>();

  const [editProps, setEditProps] = useState<EditProps>(editPropsDetails);
  const [currentErrors, setCurrentErrors] = useState<any>({});

  const history = useHistory();

  const merchantDetails = useSelector(getDetails);
  const isDetailsLoading = useSelector(getIsDetailsLoading);
  const itemNotFound = useSelector(getItemNotFound);
  const countryCodes = useSelector(getCountryCodes);

  const merchantStatus = merchantDetails.merchantStatusDetails;
  const accountHolder = merchantDetails.accountHolder;
  const reportsConf = merchantDetails.reportsConfiguration
    ? merchantDetails.reportsConfiguration
    : reportsConfigDetails;
  const merchantConfig = merchantDetails.merchantConfiguration
    ? merchantDetails.merchantConfiguration
    : merchantsConfigDetails;

  const merchantLimitsConfig = merchantDetails.merchantLimits
    ? merchantDetails.merchantLimits
    : merchantLimitsDetails;

  const merchantRapidConnect = merchantDetails.rapidConnectMerchant
    ? merchantDetails.rapidConnectMerchant
    : rapidConnectMerchantDetails;

  const getMerchantCountry = useMemo(
    () =>
      countryCodes.filter((it) => it.value === merchantDetails.countryCode)[0],
    [merchantDetails.countryCode, countryCodes]
  );

  const getAccountHolderCountry = useMemo(
    () =>
      countryCodes.filter((it) => it.value === accountHolder.countryCode)[0],
    [accountHolder.countryCode, countryCodes]
  );

  const getBillingAddressCountryCode = useMemo(
    () =>
      countryCodes.filter(
        (it) => it.value === accountHolder.billingAddressCountryCode
      )[0],
    [accountHolder.billingAddressCountryCode, countryCodes]
  );

  const merchantStatusUsed =
    merchantStatus.currentStatus === 'APPROVED'
      ? 'ACTIVE'
      : merchantStatus.currentStatus;

  const rcDefaultStatus =
    merchantRapidConnect.status && merchantRapidConnect.status.length
      ? merchantRapidConnect.status
      : 'UNREGISTERED';

  const {
    register,
    handleSubmit,
    formState: { isDirty, isSubmitting },
    formState,
    getValues,
    setValue,
    control,
    watch,
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      name: merchantDetails.name,
      logoUrl: merchantDetails.logoUrl,
      externalMerchantId: merchantDetails.externalMerchantId,
      currency: merchantDetails.currency,
      address1: merchantDetails.address1,
      address2: merchantDetails.address2,
      city: merchantDetails.city,
      merchantCountry: getMerchantCountry,
      stateUS: stateList.filter((it) => it.value === merchantDetails.state)[0],
      state: merchantDetails.state,
      postal: merchantDetails.postal,
      url: merchantDetails.url,
      timezoneId: timeZoneList.filter(
        (it) => it.label === merchantDetails.timezoneId
      )[0],
      accountNumber: merchantDetails.accountNumber,
      routingNumber: merchantDetails.routingNumber,
      username: merchantDetails.username,
      feePlan: merchantDetails.feePlan,
      locale: localeList.filter((it) => it.label === merchantDetails.locale)[0],
      contactFirstName: merchantDetails.contactFirstName,
      contactLastName: merchantDetails.contactLastName,
      contactPhone: merchantDetails.contactPhone,
      contactEmail: merchantDetails.contactEmail,
      accountType: accountTypeList.filter(
        (it) => it.value === merchantDetails.accountType
      )[0],
      accountHolderType: accountHolderTypeList.filter(
        (it) => it.value === merchantDetails.accountHolderType
      )[0],
      merchantType: merchantTypeList.filter(
        (it) => it.value === merchantDetails.merchantType
      )[0],
      captureHigherThanAuthed: conditionResultList.filter(
        (it) => it.value === String(merchantDetails.captureHigherThanAuthed)
      )[0],
      allowPaymentFacilitatorFields: conditionResultList.filter(
        (it) =>
          it.value === String(merchantDetails.allowPaymentFacilitatorFields)
      )[0],
      //status toggle
      status: merchantStatusList.filter(
        (it) => it.value === merchantStatusUsed
      )[0],
      fromDate: null,
      reasonChangeStatus: '',
      //rapid_connect
      terminalId: merchantRapidConnect.terminalId,
      groupId: merchantRapidConnect.groupId,
      merchantExternalId: merchantRapidConnect.merchantId,
      primaryServiceUrl: merchantRapidConnect.primaryServiceUrl,
      secondaryServiceUrl: merchantRapidConnect.secondaryServiceUrl,
      merchantCategoryCode: merchantRapidConnect.merchantCategoryCode,
      ecommerceUrl: merchantRapidConnect.ecommerceUrl,
      datawireId: merchantRapidConnect.datawireId,
      rcStatus: rapidConnectStatusList.filter(
        (it) => it.value === rcDefaultStatus
      )[0],
      //account holder
      countryCode: getAccountHolderCountry,
      firstName: accountHolder.firstName,
      lastName: accountHolder.lastName,
      email: accountHolder.email,
      phone: accountHolder.phone,
      legalEntityType: accountHolder.legal_entity_type,
      birthDate: accountHolder.birthDate,
      billingAddressCountryCode: getBillingAddressCountryCode,
      billingAddressSubDivisionCode:
        accountHolder.billingAddressSubDivisionCode,
      billingAddressCity: accountHolder.billingAddressCity,
      billingAddressPostalCode: accountHolder.billingAddressPostalCode,
      billingAddressAddressLine1: accountHolder.billingAddressAddressLine1,
      reports: reportsTypeList.filter((it) =>
        reportsConf.reports.includes(it.value)
      ),
      storageTypes: storageTypeList.filter((it) =>
        reportsConf.storageTypes.includes(it.value)
      ),
      folderName: reportsConf.folderName,
      // merchant config,
      clientVertical: merchantConfig.clientVertical,
      creditLimit: merchantConfig.creditLimit,
      approvalDate: merchantConfig.approvalDate
        ? merchantConfig.approvalDate
        : '',
      expiryDate: merchantConfig.expiryDate,
      internalRiskRating: internalRiskRatingList.filter(
        (it) => it.label === merchantConfig.internalRiskRating
      )[0],
      merchantAdvisedLowestTicket: merchantConfig.merchantAdvisedLowestTicket,
      merchantAdvisedLowestTicketCount:
        merchantConfig.merchantAdvisedLowestTicketCount,
      merchantAdvisedAverageTicket: merchantConfig.merchantAdvisedAverageTicket,
      merchantAdvisedHighestTicket: merchantConfig.merchantAdvisedHighestTicket,
      merchantAdvisedDailyTransactionNumber:
        merchantConfig.merchantAdvisedDailyTransactionNumber,
      merchantAdvisedDailyTransactionVolume:
        merchantConfig.merchantAdvisedDailyTransactionVolume,
      merchantAdvisedRefundRatio: merchantConfig.merchantAdvisedRefundRatio,
      merchantAdvisedChargebackRatio:
        merchantConfig.merchantAdvisedChargebackRatio,
      merchantAdvisedChargebackDays:
        merchantConfig.merchantAdvisedChargebackDays,
      merchantAdvisedDeliveryDays: merchantConfig.merchantAdvisedDeliveryDays,
      expectedMonthlyVolume: merchantConfig.expectedMonthlyVolume,
      merchantHighMonthlyVolumePercentToFlag:
        merchantConfig.merchantHighMonthlyVolumePercentToFlag,
      merchantLowMonthlyVolumePercentToFlag:
        merchantConfig.merchantLowMonthlyVolumePercentToFlag,
      highRiskAccount: conditionResultList.filter(
        (it) => it.value === String(merchantConfig.highRiskAccount)
      )[0],
      highRiskAccountDescription: merchantConfig.highRiskAccountDescription,
      merchantDailyTransactionNumberLimitPercent:
        merchantConfig.merchantDailyTransactionNumberLimitPercent,
      merchantDailyTransactionVolumeLimitPercent:
        merchantConfig.merchantDailyTransactionVolumeLimitPercent,
      dailyTicketAverage: merchantConfig.dailyTicketAverage,
      dailyTicketVolumeLimitPercent:
        merchantConfig.dailyTicketVolumeLimitPercent,
      advisedDailyRefundsNumber: merchantConfig.advisedDailyRefundsNumber,
      advisedDailyRefundsVolume: merchantConfig.advisedDailyRefundsVolume,
      advisedDailyRefundsVolumeRatio:
        merchantConfig.advisedDailyRefundsVolumeRatio,
      advisedDailyRefundsNumberLimitPercent:
        merchantConfig.advisedDailyRefundsNumberLimitPercent,
      advisedDailyChargebacksNumberRatio:
        merchantConfig.advisedDailyChargebacksNumberRatio,
      advisedDailyChargebacksNumber:
        merchantConfig.advisedDailyChargebacksNumber,
      advisedDailyChargebacksCountLimitPercent:
        merchantConfig.advisedDailyChargebacksCountLimitPercent,
      advisedDailyChargebacksVolume:
        merchantConfig.advisedDailyChargebacksVolume,
      expectedMonthlyTransactionsNumber:
        merchantConfig.expectedMonthlyTransactionsNumber,
      advisedDailyRefundsNumberRatio:
        merchantConfig.advisedDailyRefundsNumberRatio,
      advisedRepeatedCardNumberCount:
        merchantConfig.advisedRepeatedCardNumberCount,
      advisedRepeatedCardNumberVolume:
        merchantConfig.advisedRepeatedCardNumberVolume,
      advisedRepeatedBankAccountNumberCount:
        merchantConfig.advisedRepeatedBankAccountNumberCount,
      advisedRepeatedBankAccountNumberVolume:
        merchantConfig.advisedRepeatedBankAccountNumberVolume,
      advisedRepeatedIpAddressCount:
        merchantConfig.advisedRepeatedIpAddressCount,
      advisedRepeatedIpAddressVolume:
        merchantConfig.advisedRepeatedIpAddressVolume,
      advisedRepeatedCardNumberUtilizedCount:
        merchantConfig.advisedRepeatedCardNumberUtilizedCount,
      advisedRepeatedCardNumberUtilizedVolume:
        merchantConfig.advisedRepeatedCardNumberUtilizedVolume,
      advisedBeginningOperatingHour:
        merchantConfig.advisedBeginningOperatingHour,
      advisedEndingOperatingHour: merchantConfig.advisedEndingOperatingHour,
      advisedTransactionsOutsideOperatingHoursCount:
        merchantConfig.advisedTransactionsOutsideOperatingHoursCount,
      advisedTransactionsOutsideOperatingHoursVolume:
        merchantConfig.advisedTransactionsOutsideOperatingHoursVolume,
      advisedRejectedCheckedTransactionVolume:
        merchantConfig.advisedRejectedCheckedTransactionVolume,
      advisedRejectedCheckedTransactionCount:
        merchantConfig.advisedRejectedCheckedTransactionCount,
      //merchant limits
      //---annual---
      rollingAnnualTransactionVolumeLimit:
        merchantLimitsConfig.rollingAnnualTransactionVolumeLimit,
      rollingAnnualTransactionVolumeLimitNotice:
        merchantLimitsConfig.rollingAnnualTransactionVolumeLimitNotice,
      rollingAnnualTransactionVolumeLimitBlock:
        merchantLimitsConfig.rollingAnnualTransactionVolumeLimitBlock,
      //---daily---
      rolling24hTransactionVolumeLimit:
        merchantLimitsConfig.rolling24hTransactionVolumeLimit,
      rolling24hTransactionVolumeLimitNotice:
        merchantLimitsConfig.rolling24hTransactionVolumeLimitNotice,
      rolling24hTransactionVolumeLimitBlock:
        merchantLimitsConfig.rolling24hTransactionVolumeLimitBlock,
      //---7 day---
      rolling7DaysTransactionVolumeLimit:
        merchantLimitsConfig.rolling7DaysTransactionVolumeLimit,
      rolling7DaysTransactionVolumeLimitNotice:
        merchantLimitsConfig.rolling7DaysTransactionVolumeLimitNotice,
      rolling7DaysTransactionVolumeLimitBlock:
        merchantLimitsConfig.rolling7DaysTransactionVolumeLimitBlock,
      //---30 day---
      rolling30DaysTransactionVolumeLimit:
        merchantLimitsConfig.rolling30DaysTransactionVolumeLimit,
      rolling30DaysTransactionVolumeLimitNotice:
        merchantLimitsConfig.rolling30DaysTransactionVolumeLimitNotice,
      rolling30DaysTransactionVolumeLimitBlock:
        merchantLimitsConfig.rolling30DaysTransactionVolumeLimitBlock,
      //+++refund+++
      rollingAnnualRefundVolumeLimit:
        merchantLimitsConfig.rollingAnnualRefundVolumeLimit,
      rollingAnnualRefundVolumePercentLimit:
        merchantLimitsConfig.rollingAnnualRefundVolumePercentLimit,
      rollingAnnualRefundLimitNotice:
        merchantLimitsConfig.rollingAnnualRefundLimitNotice,
      rollingAnnualRefundPercentLimitNotice:
        merchantLimitsConfig.rollingAnnualRefundPercentLimitNotice,
      rollingAnnualRefundLimitBlock:
        merchantLimitsConfig.rollingAnnualRefundLimitBlock,
      rollingAnnualRefundPercentLimitBlock:
        merchantLimitsConfig.rollingAnnualRefundPercentLimitBlock,
      //+++chargeback+++
      rollingAnnualChargebackVolumeLimit:
        merchantLimitsConfig.rollingAnnualChargebackVolumeLimit,
      rollingAnnualChargebackVolumePercentLimit:
        merchantLimitsConfig.rollingAnnualChargebackVolumePercentLimit,
      rollingAnnualChargebackLimitNotice:
        merchantLimitsConfig.rollingAnnualChargebackLimitNotice,
      rollingAnnualChargebackPercentLimitNotice:
        merchantLimitsConfig.rollingAnnualChargebackPercentLimitNotice,
      rollingAnnualChargebackPercentLimitBlock:
        merchantLimitsConfig.rollingAnnualChargebackPercentLimitBlock,
      rollingAnnualChargebackLimitBlock:
        merchantLimitsConfig.rollingAnnualChargebackLimitBlock,
    },
  });

  const {
    merchantCountry,
    rollingAnnualTransactionVolumeLimit,
    rollingAnnualTransactionVolumeLimitNotice,
    rollingAnnualTransactionVolumeLimitBlock,
    rollingAnnualRefundVolumePercentLimit,
    rollingAnnualRefundVolumeLimit,
    rollingAnnualRefundPercentLimitNotice,
    rollingAnnualRefundLimitNotice,
    rollingAnnualRefundPercentLimitBlock,
    rollingAnnualRefundLimitBlock,
    rollingAnnualChargebackVolumePercentLimit,
    rollingAnnualChargebackVolumeLimit,
    rollingAnnualChargebackPercentLimitNotice,
    rollingAnnualChargebackLimitNotice,
    rollingAnnualChargebackPercentLimitBlock,
    rollingAnnualChargebackLimitBlock,
  } = watch();

  const calcPeriod = (parameter: any, valueDivide: number) =>
    Number((parameter / valueDivide).toFixed(2));

  const annualLimit = useRef(rollingAnnualTransactionVolumeLimit);
  const annualLimitNotice = useRef(rollingAnnualTransactionVolumeLimitNotice);
  const annualLimitBlock = useRef(rollingAnnualTransactionVolumeLimitBlock);

  //annual limits notice/block
  useEffect(() => {
    if (rollingAnnualTransactionVolumeLimit !== annualLimit.current) {
      setValue('rollingAnnualTransactionVolumeLimitNotice', null);
      setValue('rollingAnnualTransactionVolumeLimitBlock', null);
      if (rollingAnnualTransactionVolumeLimit) {
        setValue(
          'rolling30DaysTransactionVolumeLimit',
          calcPeriod(rollingAnnualTransactionVolumeLimit, 12)
        );
        setValue(
          'rolling7DaysTransactionVolumeLimit',
          calcPeriod(rollingAnnualTransactionVolumeLimit, 52)
        );
        setValue(
          'rolling24hTransactionVolumeLimit',
          calcPeriod(rollingAnnualTransactionVolumeLimit, 365)
        );
      } else {
        setValue('rolling30DaysTransactionVolumeLimit', null);
        setValue('rolling7DaysTransactionVolumeLimit', null);
        setValue('rolling24hTransactionVolumeLimit', null);
      }
    }
  }, [rollingAnnualTransactionVolumeLimit, setValue]);

  //annual limits notice
  useEffect(() => {
    if (
      rollingAnnualTransactionVolumeLimitNotice !== annualLimitNotice.current
    ) {
      if (rollingAnnualTransactionVolumeLimitNotice) {
        setValue(
          'rolling30DaysTransactionVolumeLimitNotice',
          calcPeriod(rollingAnnualTransactionVolumeLimitNotice, 12)
        );
        setValue(
          'rolling7DaysTransactionVolumeLimitNotice',
          calcPeriod(rollingAnnualTransactionVolumeLimitNotice, 52)
        );
        setValue(
          'rolling24hTransactionVolumeLimitNotice',
          calcPeriod(rollingAnnualTransactionVolumeLimitNotice, 365)
        );
      } else {
        setValue('rolling30DaysTransactionVolumeLimitNotice', null);
        setValue('rolling7DaysTransactionVolumeLimitNotice', null);
        setValue('rolling24hTransactionVolumeLimitNotice', null);
      }
    }
  }, [rollingAnnualTransactionVolumeLimitNotice, setValue]);

  //annual limits block
  useEffect(() => {
    if (rollingAnnualTransactionVolumeLimitBlock !== annualLimitBlock.current) {
      if (rollingAnnualTransactionVolumeLimitBlock) {
        setValue(
          'rolling30DaysTransactionVolumeLimitBlock',
          calcPeriod(rollingAnnualTransactionVolumeLimitBlock, 12)
        );
        setValue(
          'rolling7DaysTransactionVolumeLimitBlock',
          calcPeriod(rollingAnnualTransactionVolumeLimitBlock, 52)
        );
        setValue(
          'rolling24hTransactionVolumeLimitBlock',
          calcPeriod(rollingAnnualTransactionVolumeLimitBlock, 365)
        );
      } else {
        setValue('rolling30DaysTransactionVolumeLimitBlock', null);
        setValue('rolling7DaysTransactionVolumeLimitBlock', null);
        setValue('rolling24hTransactionVolumeLimitBlock', null);
      }
    }
  }, [rollingAnnualTransactionVolumeLimitBlock, setValue]);

  //refund limit
  const refundAnnualLimitPercent = useRef(
    rollingAnnualRefundVolumePercentLimit
  );
  const refundAnnualLimit = useRef(rollingAnnualRefundVolumeLimit);

  useEffect(() => {
    if (rollingAnnualTransactionVolumeLimit) {
      if (rollingAnnualTransactionVolumeLimit === annualLimit.current) {
        if (
          rollingAnnualRefundVolumePercentLimit &&
          rollingAnnualRefundVolumePercentLimit !==
            refundAnnualLimitPercent.current
        ) {
          refundAnnualLimitPercent.current =
            rollingAnnualRefundVolumePercentLimit;
          const count = Number(
            (
              (rollingAnnualTransactionVolumeLimit *
                rollingAnnualRefundVolumePercentLimit) /
              100
            ).toFixed(1)
          );
          setValue('rollingAnnualRefundVolumeLimit', count);
          refundAnnualLimit.current = count;
        } else if (
          rollingAnnualRefundVolumeLimit &&
          rollingAnnualRefundVolumeLimit !== refundAnnualLimit.current
        ) {
          refundAnnualLimit.current = rollingAnnualRefundVolumeLimit;
          const count = Number(
            (
              (100 * rollingAnnualRefundVolumeLimit) /
              rollingAnnualTransactionVolumeLimit
            ).toFixed(1)
          );
          setValue('rollingAnnualRefundVolumePercentLimit', count);
          refundAnnualLimitPercent.current = count;
        }
      } else {
        if (rollingAnnualRefundVolumePercentLimit) {
          annualLimit.current = rollingAnnualTransactionVolumeLimit;
          refundAnnualLimitPercent.current =
            rollingAnnualRefundVolumePercentLimit;
          const count = Number(
            (
              (rollingAnnualTransactionVolumeLimit *
                rollingAnnualRefundVolumePercentLimit) /
              100
            ).toFixed(1)
          );
          setValue('rollingAnnualRefundVolumeLimit', count);
          refundAnnualLimit.current = count;
        } else if (rollingAnnualRefundVolumeLimit) {
          refundAnnualLimit.current = rollingAnnualRefundVolumeLimit;
          const count = Number(
            (
              (100 * rollingAnnualRefundVolumeLimit) /
              rollingAnnualTransactionVolumeLimit
            ).toFixed(1)
          );
          setValue('rollingAnnualRefundVolumePercentLimit', count);
          refundAnnualLimitPercent.current = count;
        }
      }
    } else {
      setValue('rollingAnnualRefundVolumePercentLimit', null);
      refundAnnualLimitPercent.current = null;
      setValue('rollingAnnualRefundVolumeLimit', null);
      refundAnnualLimit.current = null;
    }
  }, [
    rollingAnnualRefundVolumePercentLimit,
    rollingAnnualRefundVolumeLimit,
    rollingAnnualTransactionVolumeLimit,
    setValue,
  ]);

  //refund notice
  const refundAnnualNoticePercent = useRef(
    rollingAnnualRefundPercentLimitNotice
  );
  const refundAnnualNoticeLimit = useRef(rollingAnnualRefundLimitNotice);

  useEffect(() => {
    if (rollingAnnualTransactionVolumeLimitNotice) {
      if (
        rollingAnnualTransactionVolumeLimitNotice === annualLimitNotice.current
      ) {
        if (
          rollingAnnualRefundPercentLimitNotice &&
          rollingAnnualRefundPercentLimitNotice !==
            refundAnnualNoticePercent.current
        ) {
          refundAnnualNoticePercent.current =
            rollingAnnualRefundPercentLimitNotice;
          const count = Number(
            (
              (rollingAnnualTransactionVolumeLimitNotice *
                rollingAnnualRefundPercentLimitNotice) /
              100
            ).toFixed(1)
          );
          setValue('rollingAnnualRefundLimitNotice', count);
          refundAnnualNoticeLimit.current = count;
        } else if (
          rollingAnnualRefundLimitNotice &&
          rollingAnnualRefundLimitNotice !== refundAnnualNoticeLimit.current
        ) {
          refundAnnualNoticeLimit.current = rollingAnnualRefundLimitNotice;
          const count = Number(
            (
              (100 * rollingAnnualRefundLimitNotice) /
              rollingAnnualTransactionVolumeLimitNotice
            ).toFixed(1)
          );
          setValue('rollingAnnualRefundPercentLimitNotice', count);
          refundAnnualNoticePercent.current = count;
        }
      } else {
        if (rollingAnnualRefundPercentLimitNotice) {
          annualLimitNotice.current = rollingAnnualTransactionVolumeLimitNotice;
          refundAnnualNoticePercent.current =
            rollingAnnualRefundPercentLimitNotice;
          const count = Number(
            (
              (rollingAnnualTransactionVolumeLimitNotice *
                rollingAnnualRefundPercentLimitNotice) /
              100
            ).toFixed(1)
          );
          setValue('rollingAnnualRefundLimitNotice', count);
          refundAnnualNoticeLimit.current = count;
        } else if (rollingAnnualRefundLimitNotice) {
          refundAnnualNoticeLimit.current = rollingAnnualRefundLimitNotice;
          const count = Number(
            (
              (100 * rollingAnnualRefundLimitNotice) /
              rollingAnnualTransactionVolumeLimitNotice
            ).toFixed(1)
          );
          setValue('rollingAnnualRefundPercentLimitNotice', count);
          refundAnnualNoticePercent.current = count;
        }
      }
    } else {
      setValue('rollingAnnualRefundPercentLimitNotice', null);
      refundAnnualNoticePercent.current = null;
      setValue('rollingAnnualRefundLimitNotice', null);
      refundAnnualNoticeLimit.current = null;
    }
  }, [
    rollingAnnualRefundPercentLimitNotice,
    rollingAnnualRefundLimitNotice,
    rollingAnnualTransactionVolumeLimitNotice,
    setValue,
  ]);

  //refund block
  const refundAnnualBlockPercent = useRef(rollingAnnualRefundPercentLimitBlock);
  const refundAnnualBlockLimit = useRef(rollingAnnualRefundLimitBlock);

  useEffect(() => {
    if (rollingAnnualTransactionVolumeLimitBlock) {
      if (
        rollingAnnualTransactionVolumeLimitBlock === annualLimitBlock.current
      ) {
        if (
          rollingAnnualRefundPercentLimitBlock &&
          rollingAnnualRefundPercentLimitBlock !==
            refundAnnualBlockPercent.current
        ) {
          refundAnnualBlockPercent.current =
            rollingAnnualRefundPercentLimitBlock;
          const count = Number(
            (
              (rollingAnnualTransactionVolumeLimitBlock *
                rollingAnnualRefundPercentLimitBlock) /
              100
            ).toFixed(1)
          );
          setValue('rollingAnnualRefundLimitBlock', count);
          refundAnnualBlockLimit.current = count;
        } else if (
          rollingAnnualRefundLimitBlock &&
          rollingAnnualRefundLimitBlock !== refundAnnualBlockLimit.current
        ) {
          refundAnnualBlockLimit.current = rollingAnnualRefundLimitBlock;
          const count = Number(
            (
              (100 * rollingAnnualRefundLimitBlock) /
              rollingAnnualTransactionVolumeLimitBlock
            ).toFixed(1)
          );
          setValue('rollingAnnualRefundPercentLimitBlock', count);
          refundAnnualBlockPercent.current = count;
        }
      } else {
        if (rollingAnnualRefundPercentLimitBlock) {
          annualLimitBlock.current = rollingAnnualTransactionVolumeLimitBlock;
          refundAnnualBlockPercent.current =
            rollingAnnualRefundPercentLimitBlock;
          const count = Number(
            (
              (rollingAnnualTransactionVolumeLimitBlock *
                rollingAnnualRefundPercentLimitBlock) /
              100
            ).toFixed(1)
          );
          setValue('rollingAnnualRefundLimitBlock', count);
          refundAnnualBlockLimit.current = count;
        } else if (rollingAnnualRefundLimitBlock) {
          refundAnnualBlockLimit.current = rollingAnnualRefundLimitBlock;
          const count = Number(
            (
              (100 * rollingAnnualRefundLimitBlock) /
              rollingAnnualTransactionVolumeLimitBlock
            ).toFixed(1)
          );
          setValue('rollingAnnualRefundPercentLimitBlock', count);
          refundAnnualBlockPercent.current = count;
        }
      }
    } else {
      setValue('rollingAnnualRefundPercentLimitBlock', null);
      refundAnnualBlockPercent.current = null;
      setValue('rollingAnnualRefundLimitBlock', null);
      refundAnnualBlockLimit.current = null;
    }
  }, [
    rollingAnnualRefundPercentLimitBlock,
    rollingAnnualRefundLimitBlock,
    rollingAnnualTransactionVolumeLimitBlock,
    setValue,
  ]);

  //chargeback limit
  const chargebackAnnualLimitPercent = useRef(
    rollingAnnualChargebackVolumePercentLimit
  );
  const chargebackAnnualLimit = useRef(rollingAnnualChargebackVolumeLimit);

  useEffect(() => {
    if (rollingAnnualTransactionVolumeLimit) {
      if (rollingAnnualTransactionVolumeLimit === annualLimit.current) {
        if (
          rollingAnnualChargebackVolumePercentLimit &&
          rollingAnnualChargebackVolumePercentLimit !==
            chargebackAnnualLimitPercent.current
        ) {
          chargebackAnnualLimitPercent.current =
            rollingAnnualChargebackVolumePercentLimit;
          const count = Number(
            (
              (rollingAnnualTransactionVolumeLimit *
                rollingAnnualChargebackVolumePercentLimit) /
              100
            ).toFixed(1)
          );
          setValue('rollingAnnualChargebackVolumeLimit', count);
          chargebackAnnualLimit.current = count;
        } else if (
          rollingAnnualChargebackVolumeLimit &&
          rollingAnnualChargebackVolumeLimit !== chargebackAnnualLimit.current
        ) {
          chargebackAnnualLimit.current = rollingAnnualChargebackVolumeLimit;
          const count = Number(
            (
              (100 * rollingAnnualChargebackVolumeLimit) /
              rollingAnnualTransactionVolumeLimit
            ).toFixed(1)
          );
          setValue('rollingAnnualChargebackVolumePercentLimit', count);
          chargebackAnnualLimitPercent.current = count;
        }
      } else {
        if (rollingAnnualChargebackVolumePercentLimit) {
          annualLimit.current = rollingAnnualTransactionVolumeLimit;
          chargebackAnnualLimitPercent.current =
            rollingAnnualChargebackVolumePercentLimit;
          const count = Number(
            (
              (rollingAnnualTransactionVolumeLimit *
                rollingAnnualChargebackVolumePercentLimit) /
              100
            ).toFixed(1)
          );
          setValue('rollingAnnualChargebackVolumeLimit', count);
          chargebackAnnualLimit.current = count;
        } else if (rollingAnnualChargebackVolumeLimit) {
          chargebackAnnualLimit.current = rollingAnnualChargebackVolumeLimit;
          const count = Number(
            (
              (100 * rollingAnnualChargebackVolumeLimit) /
              rollingAnnualTransactionVolumeLimit
            ).toFixed(1)
          );
          setValue('rollingAnnualChargebackVolumePercentLimit', count);
          chargebackAnnualLimitPercent.current = count;
        }
      }
    } else {
      setValue('rollingAnnualChargebackVolumePercentLimit', null);
      chargebackAnnualLimitPercent.current = null;
      setValue('rollingAnnualChargebackVolumeLimit', null);
      chargebackAnnualLimit.current = null;
    }
  }, [
    rollingAnnualChargebackVolumePercentLimit,
    rollingAnnualChargebackVolumeLimit,
    rollingAnnualTransactionVolumeLimit,
    setValue,
  ]);

  //chargeback notice
  const chargebackAnnualNoticePercent = useRef(
    rollingAnnualChargebackPercentLimitNotice
  );
  const chargebackAnnualNoticeLimit = useRef(
    rollingAnnualChargebackLimitNotice
  );

  useEffect(() => {
    if (rollingAnnualTransactionVolumeLimitNotice) {
      if (
        rollingAnnualTransactionVolumeLimitNotice === annualLimitNotice.current
      ) {
        if (
          rollingAnnualChargebackPercentLimitNotice &&
          rollingAnnualChargebackPercentLimitNotice !==
            chargebackAnnualNoticePercent.current
        ) {
          chargebackAnnualNoticePercent.current =
            rollingAnnualChargebackPercentLimitNotice;
          const count = Number(
            (
              (rollingAnnualTransactionVolumeLimitNotice *
                rollingAnnualChargebackPercentLimitNotice) /
              100
            ).toFixed(1)
          );
          setValue('rollingAnnualChargebackLimitNotice', count);
          chargebackAnnualNoticeLimit.current = count;
        } else if (
          rollingAnnualChargebackLimitNotice &&
          rollingAnnualChargebackLimitNotice !==
            chargebackAnnualNoticeLimit.current
        ) {
          chargebackAnnualNoticeLimit.current =
            rollingAnnualChargebackLimitNotice;
          const count = Number(
            (
              (100 * rollingAnnualChargebackLimitNotice) /
              rollingAnnualTransactionVolumeLimitNotice
            ).toFixed(1)
          );
          setValue('rollingAnnualChargebackPercentLimitNotice', count);
          chargebackAnnualNoticePercent.current = count;
        }
      } else {
        if (rollingAnnualChargebackPercentLimitNotice) {
          annualLimitNotice.current = rollingAnnualTransactionVolumeLimitNotice;
          chargebackAnnualNoticePercent.current =
            rollingAnnualChargebackPercentLimitNotice;
          const count = Number(
            (
              (rollingAnnualTransactionVolumeLimitNotice *
                rollingAnnualChargebackPercentLimitNotice) /
              100
            ).toFixed(1)
          );
          setValue('rollingAnnualChargebackLimitNotice', count);
          chargebackAnnualNoticeLimit.current = count;
        } else if (rollingAnnualChargebackLimitNotice) {
          chargebackAnnualNoticeLimit.current =
            rollingAnnualChargebackLimitNotice;
          const count = Number(
            (
              (100 * rollingAnnualChargebackLimitNotice) /
              rollingAnnualTransactionVolumeLimitNotice
            ).toFixed(1)
          );
          setValue('rollingAnnualChargebackPercentLimitNotice', count);
          chargebackAnnualNoticePercent.current = count;
        }
      }
    } else {
      setValue('rollingAnnualChargebackPercentLimitNotice', null);
      chargebackAnnualNoticePercent.current = null;
      setValue('rollingAnnualChargebackLimitNotice', null);
      chargebackAnnualNoticeLimit.current = null;
    }
  }, [
    rollingAnnualChargebackPercentLimitNotice,
    rollingAnnualChargebackLimitNotice,
    rollingAnnualTransactionVolumeLimitNotice,
    setValue,
  ]);

  //chargeback block
  const chargebackAnnualBlockPercent = useRef(
    rollingAnnualChargebackPercentLimitBlock
  );
  const chargebackAnnualBlockLimit = useRef(rollingAnnualChargebackLimitBlock);

  useEffect(() => {
    if (rollingAnnualTransactionVolumeLimitBlock) {
      if (
        rollingAnnualTransactionVolumeLimitBlock === annualLimitBlock.current
      ) {
        if (
          rollingAnnualChargebackPercentLimitBlock &&
          rollingAnnualChargebackPercentLimitBlock !==
            chargebackAnnualBlockPercent.current
        ) {
          chargebackAnnualBlockPercent.current =
            rollingAnnualChargebackPercentLimitBlock;
          const count = Number(
            (
              (rollingAnnualTransactionVolumeLimitBlock *
                rollingAnnualChargebackPercentLimitBlock) /
              100
            ).toFixed(1)
          );
          setValue('rollingAnnualChargebackLimitBlock', count);
          chargebackAnnualBlockLimit.current = count;
        } else if (
          rollingAnnualChargebackLimitBlock &&
          rollingAnnualChargebackLimitBlock !==
            chargebackAnnualBlockLimit.current
        ) {
          chargebackAnnualBlockLimit.current =
            rollingAnnualChargebackLimitBlock;
          const count = Number(
            (
              (100 * rollingAnnualChargebackLimitBlock) /
              rollingAnnualTransactionVolumeLimitBlock
            ).toFixed(1)
          );
          setValue('rollingAnnualChargebackPercentLimitBlock', count);
          chargebackAnnualBlockPercent.current = count;
        }
      } else {
        if (rollingAnnualChargebackPercentLimitBlock) {
          annualLimitBlock.current = rollingAnnualTransactionVolumeLimitBlock;
          chargebackAnnualBlockPercent.current =
            rollingAnnualChargebackPercentLimitBlock;
          const count = Number(
            (
              (rollingAnnualTransactionVolumeLimitBlock *
                rollingAnnualChargebackPercentLimitBlock) /
              100
            ).toFixed(1)
          );
          setValue('rollingAnnualChargebackLimitBlock', count);
          chargebackAnnualBlockLimit.current = count;
        } else if (rollingAnnualChargebackLimitBlock) {
          chargebackAnnualBlockLimit.current =
            rollingAnnualChargebackLimitBlock;
          const count = Number(
            (
              (100 * rollingAnnualChargebackLimitBlock) /
              rollingAnnualTransactionVolumeLimitBlock
            ).toFixed(1)
          );
          setValue('rollingAnnualChargebackPercentLimitBlock', count);
          chargebackAnnualBlockPercent.current = count;
        }
      }
    } else {
      setValue('rollingAnnualChargebackPercentLimitBlock', null);
      chargebackAnnualBlockPercent.current = null;
      setValue('rollingAnnualChargebackLimitBlock', null);
      chargebackAnnualBlockLimit.current = null;
    }
  }, [
    rollingAnnualChargebackPercentLimitBlock,
    rollingAnnualChargebackLimitBlock,
    rollingAnnualTransactionVolumeLimitBlock,
    setValue,
  ]);

  // const adviseTimeConvert = (value: any) =>
  //   value
  //     ? (value as unknown as moment.Moment)
  //         .tz('UTC')
  //         .set({ second: 0 })
  //         .format('HH:mm:ss')
  //     : value;

  const statusValue = () => {
    const values = getValues();
    if (values.merchantCountry && values.merchantCountry.value === 'US') {
      return values.stateUS ? values.stateUS.value : '';
    }
    if (countryStateList.includes(values.merchantCountry.value)) {
      return values.state ? values.state : '';
    }
  };

  const save = () => {
    const values = getValues();
    const changedAccountNumber =
      merchantDetails.accountNumber !== values.accountNumber &&
      values.accountNumber?.trim().length
        ? values.accountNumber
        : null;

    const changedRoutingNumber =
      merchantDetails.routingNumber !== values.routingNumber &&
      values.accountNumber?.trim().length
        ? values.routingNumber
        : null;

    dispatch(
      updateMerchantDetails({
        id: merchantDetails.id,
        name: values.name,
        externalMerchantId: values.externalMerchantId,
        currency: values.currency,
        address1: values.address1,
        address2: values.address2,
        city: values.city,
        countryCode: values.merchantCountry
          ? values.merchantCountry.value
          : null,
        state: statusValue(),
        // state
        postal: values.postal,
        url: values.url,
        timezoneId: values.timezoneId ? values.timezoneId.value : null,
        accountNumber: changedAccountNumber,
        routingNumber: changedRoutingNumber,
        username: values.username,
        feePlan: values.feePlan,
        locale: values.locale ? values.locale.value : null,
        contactFirstName: values.contactFirstName,
        contactLastName: values.contactLastName,
        contactPhone: values.contactPhone,
        contactEmail: values.contactEmail,
        accountType: values.accountType ? values.accountType.value : null,
        accountHolderType: values.accountHolderType
          ? values.accountHolderType.value
          : null,
        merchantType: values.merchantType ? values.merchantType.value : null,
        captureHigherThanAuthed: values.captureHigherThanAuthed
          ? values.captureHigherThanAuthed.value
          : null,
        allowPaymentFacilitatorFields: values.captureHigherThanAuthed
          ? values.allowPaymentFacilitatorFields.value
          : null,
      })
    )
      .unwrap()
      .then(() => {
        history.goBack();
      });
  };

  const saveAccountHolderDetails = () => {
    const values = getValues();

    dispatch(
      updateAccountHolderDetails({
        merchantId: merchantDetails.id,
        countryCode: values.countryCode ? values.countryCode.value : null,
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        phone: values.phone,
        birthDate: values.birthDate,
        legal_entity_type: values.legalEntityType,
        billingAddressCountryCode: values.billingAddressCountryCode
          ? values.billingAddressCountryCode.value
          : null,
        billingAddressSubDivisionCode: values.billingAddressSubDivisionCode,
        billingAddressCity: values.billingAddressCity,
        billingAddressPostalCode: values.billingAddressPostalCode,
        billingAddressAddressLine1: values.billingAddressAddressLine1,
      })
    )
      .unwrap()
      .then(() => {
        history.goBack();
      });
  };

  const saveRapidConnectDetails = () => {
    const values = getValues();

    if (merchantDetails.rapidConnectMerchant.id) {
      dispatch(
        updateRapidConnectDetails({
          merchantId: merchantDetails.id,
          terminalId: values.terminalId,
          groupId: values.groupId,
          merchantExternalId:
            values.merchantExternalId !== merchantRapidConnect.merchantId
              ? values.merchantExternalId
              : null,
          primaryServiceUrl: values.primaryServiceUrl,
          merchantCategoryCode: values.merchantCategoryCode,
          ecommerceUrl: values.ecommerceUrl,
          secondaryServiceUrl: values.secondaryServiceUrl,
          datawireId:
            values.datawireId !== merchantRapidConnect.datawireId
              ? values.datawireId
              : null,
          status: values.rcStatus.value,
        })
      )
        .unwrap()
        .then(() => {
          history.goBack();
        });
    } else {
      dispatch(
        createRapidConnectDetails({
          merchantId: merchantDetails.id,
          merchantExternalId: values.merchantExternalId,
          terminalId: values.terminalId,
          groupId: values.groupId,
          primaryServiceUrl: values.primaryServiceUrl,
          secondaryServiceUrl: values.secondaryServiceUrl,
          merchantCategoryCode: values.merchantCategoryCode,
          ecommerceUrl: values.ecommerceUrl,
        })
      )
        .unwrap()
        .then(() => {
          history.goBack();
        });
    }
  };

  const saveReportsConfigDetails = () => {
    const values = getValues();

    dispatch(
      updateReportsConfigDetails({
        merchantId: merchantDetails.id,
        reports: values.reports.map((it) => it.value),
        storageTypes: values.storageTypes.map((it) => it.value),
        folderName: values.folderName,
      })
    )
      .unwrap()
      .then(() => {
        history.goBack();
      });
  };

  const updateMerchantStatus = () => {
    const values = getValues();
    dispatch(
      updateMerchant({
        merchantId: merchantDetails.id,
        status: values.status.value,
        reason: values.reasonChangeStatus,
        fromDateTime: moment(values.fromDate).toISOString(),
      })
    )
      .unwrap()
      .then(() => {
        history.goBack();
      });
  };

  const saveMerchantsConfigDetails = () => {
    const values = getValues();
    dispatch(
      updateMerchantConfigLimitDetails({
        merchantId: merchantDetails.id.toString(),
        body: {
          rollingAnnualTransactionVolumeLimit:
            values.rollingAnnualTransactionVolumeLimit,
          rolling24hTransactionVolumeLimitNotice:
            values.rolling24hTransactionVolumeLimitNotice,
          rolling24hTransactionVolumeLimit:
            values.rolling24hTransactionVolumeLimit,
          rolling24hTransactionVolumeLimitBlock:
            values.rolling24hTransactionVolumeLimitBlock,
          rolling7DaysTransactionVolumeLimit:
            values.rolling7DaysTransactionVolumeLimit,
          rolling7DaysTransactionVolumeLimitNotice:
            values.rolling7DaysTransactionVolumeLimitNotice,
          rolling7DaysTransactionVolumeLimitBlock:
            values.rolling7DaysTransactionVolumeLimitBlock,
          rolling30DaysTransactionVolumeLimit:
            values.rolling30DaysTransactionVolumeLimit,
          rolling30DaysTransactionVolumeLimitNotice:
            values.rolling30DaysTransactionVolumeLimitNotice,
          rolling30DaysTransactionVolumeLimitBlock:
            values.rolling30DaysTransactionVolumeLimitBlock,
          rollingAnnualTransactionVolumeLimitNotice:
            values.rollingAnnualTransactionVolumeLimitNotice,
          rollingAnnualTransactionVolumeLimitBlock:
            values.rollingAnnualTransactionVolumeLimitBlock,
          rollingAnnualRefundVolumePercentLimit:
            values.rollingAnnualRefundVolumePercentLimit,
          rollingAnnualRefundVolumeLimit: values.rollingAnnualRefundVolumeLimit,
          rollingAnnualRefundPercentLimitNotice:
            values.rollingAnnualRefundPercentLimitNotice,
          rollingAnnualRefundLimitNotice: values.rollingAnnualRefundLimitNotice,
          rollingAnnualRefundPercentLimitBlock:
            values.rollingAnnualRefundPercentLimitBlock,
          rollingAnnualRefundLimitBlock: values.rollingAnnualRefundLimitBlock,
          rollingAnnualChargebackVolumePercentLimit:
            values.rollingAnnualChargebackVolumePercentLimit,
          rollingAnnualChargebackVolumeLimit:
            values.rollingAnnualChargebackVolumeLimit,
          rollingAnnualChargebackPercentLimitNotice:
            values.rollingAnnualChargebackPercentLimitNotice,
          rollingAnnualChargebackLimitNotice:
            values.rollingAnnualChargebackLimitNotice,
          rollingAnnualChargebackPercentLimitBlock:
            values.rollingAnnualChargebackPercentLimitBlock,
          rollingAnnualChargebackLimitBlock:
            values.rollingAnnualChargebackLimitBlock,
        },
      })
    );

    dispatch(
      updateMerchantConfigDetails({
        merchantId: merchantDetails.id,
        merchantConfiguration: {
          clientVertical: values.clientVertical,
          creditLimit: values.creditLimit,
          approvalDate: values.approvalDate,
          expiryDate: values.expiryDate,
          internalRiskRating: values.internalRiskRating
            ? values.internalRiskRating.value
            : null,
          merchantAdvisedLowestTicket: values.merchantAdvisedLowestTicket,
          merchantAdvisedLowestTicketCount:
            values.merchantAdvisedLowestTicketCount,
          merchantAdvisedAverageTicket: values.merchantAdvisedAverageTicket,
          merchantAdvisedHighestTicket: values.merchantAdvisedHighestTicket,
          merchantAdvisedDailyTransactionNumber:
            values.merchantAdvisedDailyTransactionNumber,
          merchantAdvisedDailyTransactionVolume:
            values.merchantAdvisedDailyTransactionVolume,
          merchantAdvisedRefundRatio: values.merchantAdvisedRefundRatio,
          merchantAdvisedChargebackRatio: values.merchantAdvisedChargebackRatio,
          merchantAdvisedChargebackDays: values.merchantAdvisedChargebackDays,
          merchantAdvisedDeliveryDays: values.merchantAdvisedDeliveryDays,
          expectedMonthlyVolume: values.expectedMonthlyVolume,
          merchantHighMonthlyVolumePercentToFlag:
            values.merchantHighMonthlyVolumePercentToFlag,
          merchantLowMonthlyVolumePercentToFlag:
            values.merchantLowMonthlyVolumePercentToFlag,
          highRiskAccount: values.highRiskAccount
            ? values.highRiskAccount.value
            : null,
          highRiskAccountDescription: values.highRiskAccountDescription,
          merchantDailyTransactionNumberLimitPercent:
            values.merchantDailyTransactionNumberLimitPercent,
          merchantDailyTransactionVolumeLimitPercent:
            values.merchantDailyTransactionVolumeLimitPercent,
          dailyTicketAverage: values.dailyTicketAverage,
          dailyTicketVolumeLimitPercent: values.dailyTicketVolumeLimitPercent,
          advisedDailyRefundsNumber: values.advisedDailyRefundsNumber,
          advisedDailyRefundsVolume: values.advisedDailyRefundsVolume,
          advisedDailyRefundsVolumeRatio: values.advisedDailyRefundsVolumeRatio,
          advisedDailyRefundsNumberLimitPercent:
            values.advisedDailyRefundsNumberLimitPercent,
          advisedDailyChargebacksNumberRatio:
            values.advisedDailyChargebacksNumberRatio,
          advisedDailyChargebacksNumber: values.advisedDailyChargebacksNumber,
          advisedDailyChargebacksCountLimitPercent:
            values.advisedDailyChargebacksCountLimitPercent,
          advisedDailyChargebacksVolume: values.advisedDailyChargebacksVolume,
          expectedMonthlyTransactionsNumber:
            values.expectedMonthlyTransactionsNumber,
          advisedDailyRefundsNumberRatio: values.advisedDailyRefundsNumberRatio,
          advisedRepeatedCardNumberCount: values.advisedRepeatedCardNumberCount,
          advisedRepeatedCardNumberVolume:
            values.advisedRepeatedCardNumberVolume,
          advisedRepeatedBankAccountNumberCount:
            values.advisedRepeatedBankAccountNumberCount,
          advisedRepeatedBankAccountNumberVolume:
            values.advisedRepeatedBankAccountNumberVolume,
          advisedRepeatedIpAddressCount: values.advisedRepeatedIpAddressCount,
          advisedRepeatedIpAddressVolume: values.advisedRepeatedIpAddressVolume,
          advisedRepeatedCardNumberUtilizedCount:
            values.advisedRepeatedCardNumberUtilizedCount,
          advisedRepeatedCardNumberUtilizedVolume:
            values.advisedRepeatedCardNumberUtilizedVolume,
          // advisedBeginningOperatingHour: adviseTimeConvert(
          //   values.advisedBeginningOperatingHour
          // ),
          // advisedEndingOperatingHour: adviseTimeConvert(
          //   values.advisedEndingOperatingHour
          // ),
          advisedTransactionsOutsideOperatingHoursCount:
            values.advisedTransactionsOutsideOperatingHoursCount,
          advisedTransactionsOutsideOperatingHoursVolume:
            values.advisedTransactionsOutsideOperatingHoursVolume,
          advisedRejectedCheckedTransactionVolume:
            values.advisedRejectedCheckedTransactionVolume,
          advisedRejectedCheckedTransactionCount:
            values.advisedRejectedCheckedTransactionCount,
          status: 'PENDING_APPROVAL',
        },
      })
    )
      .unwrap()
      .then(() => {
        history.goBack();
      });
  };

  const isRapidConnectCreated = merchantRapidConnect.id !== null;

  useEffect(() => {
    switch (component) {
      case 'merchant_details':
        {
          setEditProps({
            pageName: t('system.merchantDetails.detailsUpdate'),
            updateMethod: save,
            iconText: 'Details',
            iconColor: getMerchantDetailsIconColor(
              merchantDetails.merchantStatusDetails.currentStatus
            ),
            icon: <StorefrontIcon fontSize="large" sx={{ color: '#fff' }} />,
            componentUpdate: (
              <MerchantDetailsInputComponent
                register={register}
                control={control}
                errors={currentErrors}
                merchantCountry={merchantCountry}
                merchantGateway={merchantDetails.processorName}
              />
            ),
          });
        }
        break;
      case 'account_holder':
        {
          setEditProps({
            pageName: t('system.merchantDetails.detailsUpdateAccountHolder'),
            iconText: 'Account Holder',
            updateMethod: saveAccountHolderDetails,
            iconColor: 'box-bg-biloba-flower-300 box-shadow-biloba-flower',
            icon: (
              <AccountBalanceIcon fontSize="large" sx={{ color: '#fff' }} />
            ),
            componentUpdate: (
              <AccountHolderDetailsInputComponent
                register={register}
                control={control}
                errors={currentErrors}
              />
            ),
          });
        }
        break;
      case 'rapid_connect':
        {
          setEditProps({
            pageName: merchantDetails.rapidConnectMerchant.id
              ? t('system.merchantDetails.detailsUpdateRapidConnect')
              : t('system.merchantDetails.detailsCreateRapidConnect'),
            iconText: 'Rapid Connect Configuration',
            updateMethod: saveRapidConnectDetails,
            iconColor: 'box-bg-roti-400 box-shadow-roti',
            icon: <MemoryIcon fontSize="large" sx={{ color: '#fff' }} />,
            componentUpdate: (
              <MerchantRapidConnectComponent
                register={register}
                control={control}
                errors={currentErrors}
                isCreated={isRapidConnectCreated}
              />
            ),
          });
        }
        break;
      case 'reports_config':
        {
          setEditProps({
            pageName: merchantDetails.merchantConfiguration
              ? t('system.merchantDetails.detailsUpdateReports')
              : t('system.merchantDetails.detailsCreateReports'),
            updateMethod: saveReportsConfigDetails,
            iconText: 'Reports Configuration',
            iconColor: 'box-bg-dodger-blue-300 box-shadow-dodger-blue',
            icon: <InsertChartIcon fontSize="large" sx={{ color: '#fff' }} />,
            componentUpdate: (
              <ReportConfigurationDetailsInputComponent
                register={register}
                control={control}
                errors={currentErrors}
              />
            ),
          });
        }
        break;
      case 'merchant_config': {
        setEditProps({
          pageName: merchantDetails.merchantConfiguration
            ? t('system.merchantDetails.detailsUpdateMerchantsConfig')
            : t('system.merchantDetails.detailsCreateMerchantsConfig'),
          updateMethod: saveMerchantsConfigDetails,
          iconText: 'Merchant Configuration',
          iconColor: 'box-bg-green-smoke-400 box-shadow-green-smoke',
          icon: (
            <SettingsApplicationsIcon fontSize="large" sx={{ color: '#fff' }} />
          ),
          componentUpdate: (
            <MerchantConfigDetailsInputComponent
              register={register}
              control={control}
              errors={currentErrors}
            />
          ),
        });
        break;
      }
      case 'toggle_status': {
        setEditProps({
          pageName: merchantDetails.merchantConfiguration
            ? t('system.merchantDetails.detailsUpdateMerchantsConfig')
            : t('system.merchantDetails.detailsCreateMerchantsConfig'),
          updateMethod: updateMerchantStatus,
          iconText: 'Toggle Status',
          iconColor: 'box-bg-blue-lagoon-300 box-shadow-blue-lagoon',
          icon: <AutorenewIcon fontSize="large" sx={{ color: '#fff' }} />,
          componentUpdate: (
            <MerchantToggleStatusComponent
              register={register}
              control={control}
              errors={currentErrors}
            />
          ),
        });
        break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    component,
    control,
    currentErrors,
    merchantDetails.merchantConfiguration,
    merchantDetails.merchantStatusDetails.currentStatus,
    register,
    merchantCountry,
  ]);

  useEffect(() => {
    if (!isEqual(formState.errors, currentErrors)) {
      setCurrentErrors({ ...formState.errors });
    }
  }, [formState, currentErrors]);

  return (
    <>
      <UpdateDetailsComponent
        pageName={editProps.pageName}
        handleSubmit={handleSubmit(editProps.updateMethod)}
        itemNotFound={itemNotFound}
        isDetailsLoading={isDetailsLoading}
        detailId={merchantDetails.id}
        backUrl="/system/merchants"
        backErrorMessage={t('system.merchantDetails.backToMerchants')}
        iconColor={editProps.iconColor}
        isSubmitting={isSubmitting}
        isDirty={isDirty}
        icon={editProps.icon}
        iconText={editProps.iconText}
        componentUpdate={editProps.componentUpdate}
      />
    </>
  );
};
