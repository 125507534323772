import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';

import { ColumnProps, PaginationProps, TableCard } from 'components/table';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { LayoutList } from 'layouts/list/LayoutList';
import { ProgramItem } from 'entities/system';
import { ItemSort } from 'entities';

import {
  setApplyFilters,
  setPage,
  setRowsPerPage,
  setSort,
} from '../programsSlice';
import { Filters } from './filters/Filters';
import { useColumns } from './useColumns';
import {
  getFilters,
  getItems,
  getItemsLoadingComputed,
  getPagination,
  getTotalNumber,
} from '../selectors';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import { useHistory } from 'react-router-dom';
import { getProfilePermission } from 'domain/account/selectors';
import { UserPermissions } from 'enums/Permissions';
import { FiltersParams } from '../utils';

export const ProgramList = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  const items: ProgramItem[] = useAppSelector(getItems);
  const pagination: PaginationProps = useAppSelector(getPagination);
  const filters: FiltersParams = useAppSelector(getFilters);
  const totalNumber: number = useAppSelector(getTotalNumber);
  const itemsLoadingComputed: boolean = useAppSelector(getItemsLoadingComputed);
  const permissions: any = useAppSelector(getProfilePermission);

  const columns: ColumnProps[] = useColumns();

  const tableRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    tableRef?.current?.scrollTo(tableRef?.current?.scrollLeft, 0);
  }, [itemsLoadingComputed]);

  const setCurrentSort = (sort: ItemSort) => {
    dispatch(setSort(sort));
  };

  const isFilterSelected = [
    filters.programUrl.length ||
      filters.programName.length ||
      filters.processor.length ||
      filters.corporates.length,
  ].some((value) => !!value);

  const isOnRowClick = permissions.includes(UserPermissions.PROGRAMS_CREATE);
  const filterAction = isFilterSelected
    ? () => dispatch(setApplyFilters())
    : undefined;

  return (
    <LayoutList title={t('programs.breadcrumb')}>
      <>
        <TableCard
          tableRef={tableRef}
          loading={itemsLoadingComputed}
          columns={columns}
          totalNumber={totalNumber}
          items={items}
          setSort={setCurrentSort}
          pagination={pagination}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
          filterApply={filterAction}
          filters={<Filters />}
          onHover={false}
          create={
            isOnRowClick ? (
              <div className="px-3 py-2 flex-1">
                <Button
                  onClick={() => history.push('/system/programs/create')}
                  className="ml-2"
                  variant="contained"
                  startIcon={<DisplaySettingsIcon />}
                >
                  <span className="text-xxs sm:text-sm">
                    {t('programs.create')}
                  </span>
                </Button>
              </div>
            ) : null
          }
        />
      </>
    </LayoutList>
  );
};
