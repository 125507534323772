import React, {
  FocusEvent,
  MouseEvent,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import { IconCross } from 'components/icons/IconCross';
import { HorizontalDivider } from '../HorizontalDivider';
import { Button } from '../Button';
import { useTranslation } from 'react-i18next';
import { IconChevronLeft } from '../icons/IconChevronLeft';
import { FieldError } from 'react-hook-form';
import * as PopperJS from '@popperjs/core';
import Popper from '@mui/material/Popper';
import { Fade } from '@mui/material';
import Paper from '@mui/material/Paper';
import { debounce } from 'lodash';
import { ESC_KEY, useOnKeyDown } from '../../hooks/useOnKeyDown';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';

export const InputDropdownComponent = ({
  name,
  value,
  popoverIsOpen,
  placeholder,
  label,
  disabled = false,
  togglePopoverIsOpen,
  icon,
  error,
  inputHeightClassName = 'h-8',
  labelClass = 'font-medium tracking-wide text-xs',
}: {
  name: string;
  inputHeightClassName?: string;
  value?: string;
  label?: any | ReactNode;
  labelClass?: string;
  placeholder?: string;
  disabled?: boolean;
  popoverIsOpen: boolean;
  icon?: ReactNode;
  error?: FieldError;
  togglePopoverIsOpen: (
    event: MouseEvent | TouchEvent | FocusEvent<HTMLInputElement>
  ) => void;
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const { t } = useTranslation();

  return (
    <div
      className={classNames({
        'opacity-50': disabled,
      })}
    >
      <label htmlFor={name} className="relative block">
        <span className={labelClass}>{label}</span>
        {error && (
          <span className="absolute right-0 mt-1 text-xs font-medium text-red-500">
            {error?.message || t(`validations.${error?.type}`)}
          </span>
        )}
      </label>

      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
      <div
        role="button"
        tabIndex={0}
        onClick={togglePopoverIsOpen}
        className="relative cursor-pointer"
      >
        <input
          ref={inputRef}
          id={name}
          name={name}
          value={value}
          placeholder={placeholder || ''}
          disabled={disabled}
          readOnly
          className={classNames(
            'block w-full mt-1 text-xs font-bold cursor-pointer form-input rounded',
            disabled ? 'cursor-default' : 'cursor-pointer',
            {
              'border-red-500 focus:border-red-500 focus:shadow-outline-red':
                !!error,
            },
            inputHeightClassName
          )}
        />
        <button
          type="button"
          disabled={disabled}
          onClick={() => inputRef.current?.focus()}
          className="absolute top-0 right-0 flex items-center justify-center w-12 h-full focus:outline-none"
        >
          {icon || (
            <IconChevronLeft
              className={classNames(
                'ml-2 transform transition text-gray-700 duration-200',
                {
                  '-rotate-90': !popoverIsOpen,
                  'rotate-90 text-blue-600': popoverIsOpen,
                }
              )}
            />
          )}
        </button>
      </div>
    </div>
  );
};

export const DropdownDateTime = ({
  title,
  errorMessage = null,
  value,
  popoverWidth = 240,
  headerSecondaryLabel,
  children,
  trigger: dropdown,
  footer,
  onOpen = () => {},
  onClose = () => {},
  onClearFilter = () => {},
  placementPosition = 'top-end',
}: {
  popoverWidth?: number;
  popoverHeight?: number;
  title: string;
  errorMessage?: any;
  headerSecondaryLabel?: string;
  searchPlaceholder?: string;
  children: (close: () => any) => ReactNode;
  value?: string;
  trigger: ({
    togglePopoverIsOpen,
    popoverIsOpen,
  }: {
    togglePopoverIsOpen: (
      event: MouseEvent | TouchEvent | FocusEvent<HTMLInputElement>
    ) => void;
    popoverIsOpen: boolean;
  }) => ReactNode;
  footer?: ReactNode;
  onOpen?: (searchQuery: string) => any;
  onClose?: () => any;
  onClearFilter?: () => any;
  placementPosition?: PopperJS.Placement;
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<any | null>(null);
  const [popoverIsOpen, setOpen] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setPopoverIsOpenDebounced = useCallback(
    debounce((state: boolean) => setOpen(state), 200),
    []
  );

  const togglePopoverIsOpen = useCallback(
    (event: MouseEvent | TouchEvent | FocusEvent) => {
      event.stopPropagation();
      const target = event.currentTarget;
      setAnchorEl(target);
      setPopoverIsOpenDebounced(!popoverIsOpen);
    },
    [popoverIsOpen, setPopoverIsOpenDebounced]
  );

  useOnKeyDown({ keyCode: [ESC_KEY] }, () => setOpen(false));

  const popperRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(
    {
      ref: popperRef,
    },
    () => setOpen(false)
  );

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === ESC_KEY) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [anchorEl]);

  return (
    <>
      <div>{dropdown({ togglePopoverIsOpen, popoverIsOpen })}</div>
      <Popper
        sx={{ zIndex: 1200, marginTop: '10px' }}
        open={popoverIsOpen}
        anchorEl={anchorEl}
        placement="bottom"
        ref={popperRef}
        transition
      >
        {({ TransitionProps }) => (
          <Fade
            {...TransitionProps}
            timeout={350}
            className="m-2"
            ref={popperRef}
          >
            <Paper>
              <div>
                <div className="flex items-center justify-between p-3">
                  <span>
                    <span className="text-xs font-semibold text-gray-900">
                      {title}
                    </span>{' '}
                    <span className="text-xs text-red-700 mr-4">
                      {errorMessage}
                    </span>
                  </span>
                  <div className="flex items-center">
                    {headerSecondaryLabel && (
                      <span className="mr-3 text-gray-700 text-xxs">
                        {headerSecondaryLabel}
                      </span>
                    )}
                    <button
                      onClick={() => setPopoverIsOpenDebounced(false)}
                      className="transition-shadow duration-300 rounded focus:outline-none focus:shadow-outline"
                    >
                      <IconCross className="w-4 h-4 text-gray-700" />
                    </button>
                  </div>
                </div>
                <HorizontalDivider />
                <div className="p-3">
                  {children(() => setPopoverIsOpenDebounced(false))}
                </div>
                <HorizontalDivider />
                {footer || (
                  <div className="p-3 w-full">
                    <Button
                      variant={value ? 'primary' : 'secondary'}
                      widthClass="w-full"
                      className="font-bold"
                      onClick={onClearFilter}
                    >
                      {t('kyc.filters.clearFilter')}
                    </Button>
                  </div>
                )}
              </div>
            </Paper>
          </Fade>
        )}
      </Popper>
    </>
  );
};
