import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { breadcrumbsSlice } from 'domain/breadcrumbs/breadcrumbsSlice';
import accountSliceReducer from 'domain/account/accountSlice';

import authorizationReducer, {
  storeName,
} from 'domain/transaction-management/authorizations/authorizationsSlice';
import transactionSliceReducer, {
  storeName as transactionStoreName,
} from 'domain/transaction-management/transactions/transactionSlice';

import unmatchedTransactionReducer, {
  storeName as unmatchedTransactionStoreName,
} from 'domain/transaction-management/unmatched-transactions/unmatchedTransactionSlice';

import chargebacksSliceReducer, {
  storeName as chargebacksStoreName,
} from 'domain/dispute-resolution/chargebacks/chargebacksSlice';

import reportReducer, {
  storeName as reportStoreName,
} from 'domain/reports/report-view-component/reportSlice';

import apiReducer, {
  storeName as apiStoreName,
} from 'domain/logs/api/apiSlice';

import serviceMonitorReducer, {
  storeName as serviceMonitorStoreName,
} from 'domain/logs/service-monitoring/serviceMonitorSlice';

import notificationReducer, {
  storeName as notificationStoreName,
} from 'domain/notifications/notification/notificationSlice';

import blockedPansReducer, {
  storeName as blockedPansStoreName,
} from 'domain/fraud-prevention/blockedPans/blockedPansSlice';

import virtualTerminalReducer, {
  storeName as virtualTerminalStoreName,
} from 'domain/transaction-management/virtual-terminal-component/virtualTerminalSlice';

import virtualTerminalPaymentReducer, {
  storeName as virtualTerminalPaymentStoreName,
} from 'domain/virtual-terminal-payment/virtualTerminalPaymentSlice';

import merchantsReducer, {
  storeName as merchantsStoreName,
} from 'domain/system/merchants/merchantsSlice';

import programsReducer, {
  storeName as programsStoreName,
} from 'domain/system/programs/programsSlice';

import rolesReducer, {
  storeName as rolesStoreName,
} from 'domain/user-management/roles/rolesSlice';

import userReducer, {
  storeName as userStoreName,
} from 'domain/user-management/users/userSlice';

import secretKeyReducer, {
  storeName as secretKeyStoreName,
} from 'domain/api-secret-key-management/secret-key-management/secretKeyManagementSlice';

import dashboardReducer, {
  storeName as dashboardStoreName,
} from 'domain/dashboard/dashboradSlice';

import settingsReducer, {
  storeName as settingsStoreName,
} from 'domain/settings/settingsSlice';

import complianceAuditReducer, {
  storeName as complianceAuditStoreName,
} from 'domain/compliance-audit/compliance-audit-component/complianceAuditSlice';

const breadcrumbsConfig = {
  key: 'breadcrumbs',
  storage,
};

const accountConfig = {
  key: 'account',
  storage,
};

export const appReducer = combineReducers({
  [storeName]: authorizationReducer,
  [transactionStoreName]: transactionSliceReducer,
  [unmatchedTransactionStoreName]: unmatchedTransactionReducer,
  [chargebacksStoreName]: chargebacksSliceReducer,
  [reportStoreName]: reportReducer,
  [apiStoreName]: apiReducer,
  [serviceMonitorStoreName]: serviceMonitorReducer,
  [notificationStoreName]: notificationReducer,
  [merchantsStoreName]: merchantsReducer,
  [blockedPansStoreName]: blockedPansReducer,
  [virtualTerminalStoreName]: virtualTerminalReducer,
  [virtualTerminalPaymentStoreName]: virtualTerminalPaymentReducer,
  [programsStoreName]: programsReducer,
  [rolesStoreName]: rolesReducer,
  [userStoreName]: userReducer,
  [secretKeyStoreName]: secretKeyReducer,
  [dashboardStoreName]: dashboardReducer,
  [complianceAuditStoreName]: complianceAuditReducer,
  [settingsStoreName]: settingsReducer,
  breadcrumbs: persistReducer(breadcrumbsConfig, breadcrumbsSlice.reducer),
  account: persistReducer(accountConfig, accountSliceReducer),
});

// export type RootState = ReturnType<typeof appReducer>;
