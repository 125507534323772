import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import numeral from 'numeral';
import { CellBase, CellLight, ColumnProps } from 'components/table/Table';
import {
  MatchType,
  MatchTypeLabel,
  TransactionItem,
} from 'entities/transaction-management';
import { TransactionStatusBadge } from './TransactionStatusBadge';
import { TransactionStatus, TransactionType } from '../transaction';
import { useSelector } from 'react-redux';
import { dateTimeFormat, dateTimeZone } from '../../../account/selectors';
import {
  dateTimeFormatUtil,
  timeWithZoneFormatUtil,
} from 'components/dateTimeFormatUtil';

export const useTransactionsListColumns = () => {
  const { t } = useTranslation();
  const dateFormat = useSelector(dateTimeFormat);
  const dateZone = useSelector(dateTimeZone);

  const timeFormatVal = (value: string) =>
    timeWithZoneFormatUtil(value, dateZone);

  const dateFormatVal = (value: string) =>
    dateTimeFormatUtil(value, dateFormat, dateZone);

  const getMerchantType = (item: TransactionItem) => {
    switch (item.matchType) {
      case MatchType.SUCCESS:
        return <CellLight>{MatchTypeLabel.SUCCESS}</CellLight>;
      case MatchType.PENDING:
        return <CellLight>{MatchTypeLabel.PENDING}</CellLight>;
      case MatchType.DUP:
        return <CellLight>{MatchTypeLabel.DUPLICATE}</CellLight>;
      case MatchType.NEQSTAN:
        return <CellLight>{MatchTypeLabel.NEQSTAN}</CellLight>;
      case MatchType.NEQAMT:
        return <CellLight>{MatchTypeLabel.NEQAMT}</CellLight>;
      case MatchType.NEQNWID:
        return <CellLight>{MatchTypeLabel.NEQNWID}</CellLight>;
      case MatchType.NOAUTH:
        return <CellLight>{MatchTypeLabel.NOAUTH}</CellLight>;
      case MatchType.NOSTL:
        return <CellLight>{MatchTypeLabel.NOSTL}</CellLight>;
      default:
        return <CellLight>{item.matchType}</CellLight>;
    }
  };

  return useMemo(
    (): ColumnProps[] => [
      {
        id: 'transactionId',
        value: (item: TransactionItem) => <CellLight>{item.id}</CellLight>,
        label: t('transactionsManagement.transactions.list.transactionId'),
        width: 280,
      },
      {
        id: 'settlementDate',
        value: (item: TransactionItem) => (
          <CellLight>
            {item.settlementDate && dateFormatVal(item.settlementDate)}
          </CellLight>
        ),
        label: t('transactionsManagement.transactions.list.settlementDate'),
        isSorted: true,
        sortField: 'SETTLEMENT_DATE',
        width: 120,
      },
      {
        id: 'transactionAmount',
        value: (item: TransactionItem) => (
          <CellBase>
            {item.transactionAmount &&
              numeral(item.transactionAmount).format('0.00')}
          </CellBase>
        ),
        label: t('transactionsManagement.transactions.list.transactionAmount'),
        isSorted: true,
        sortField: 'SETTLEMENT_AMOUNT',
        width: 140,
      },
      {
        id: 'settlementCurrencyCode',
        value: (item: TransactionItem) => (
          <CellBase>{item.settlementCurrencyCode}</CellBase>
        ),
        label: t(
          'transactionsManagement.transactions.list.settlementCurrencyCode'
        ),
        isSorted: true,
        sortField: 'SETTLEMENT_CURRENCY_CODE',
        width: 120,
      },
      {
        id: 'transactionStatus',
        value: (item: TransactionItem) => (
          <CellLight>
            {item.transactionStatus && (
              <TransactionStatusBadge status={item.transactionStatus}>
                {Object.values(TransactionStatus).includes(
                  item.transactionStatus
                )
                  ? t(
                      `transactionsManagement.transactions.status.${item.transactionStatus}`
                    )
                  : item?.transactionStatus}
              </TransactionStatusBadge>
            )}
          </CellLight>
        ),
        label: t('transactionsManagement.transactions.list.transactionStatus'),
        isSorted: true,
        sortField: 'SETTLEMENT_STATUS',
        width: 130,
      },
      {
        id: 'transactionType',
        value: (item: TransactionItem) => (
          <CellBase>
            {item?.transactionType &&
            Object.values(TransactionType).includes(item.transactionType)
              ? t(
                  `transactionsManagement.transactions.transactionType.${item.transactionType}`
                )
              : item?.transactionType}
          </CellBase>
        ),
        label: t('transactionsManagement.transactions.list.transactionType'),
        isSorted: true,
        sortField: 'SETTLEMENT_TYPE',
        width: 90,
      },
      {
        id: 'transactionDate',
        value: (item: TransactionItem) => (
          <CellLight>
            {item.transactionDate && dateFormatVal(item.transactionDate)}
          </CellLight>
        ),
        label: t('transactionsManagement.transactions.list.transactionDate'),
        isSorted: true,
        sortField: 'SETTLEMENT_TRANSACTION_DATE',
        width: 120,
      },
      {
        id: 'transactionTime',
        value: (item: TransactionItem) => (
          <CellLight>
            {item.transactionTime && timeFormatVal(item.transactionTime)}
          </CellLight>
        ),
        label: t('transactionsManagement.transactions.list.transactionTime'),
        isSorted: true,
        sortField: 'SETTLEMENT_TRANSACTION_TIME',
        width: 120,
      },
      {
        id: 'matchType',
        value: getMerchantType,
        label: t('transactionsManagement.transactions.list.matchType'),
        isSorted: true,
        sortField: 'MATCH_TYPE',
        width: 130,
      },
      {
        id: 'cardNumber',
        value: (item: TransactionItem) => (
          <CellBase>{item.cardNumber}</CellBase>
        ),
        label: t('transactionsManagement.transactions.list.cardNumber'),
        isSorted: true,
        sortField: 'SETTLEMENT_CARD_NUMBER',
        width: 140,
      },
      {
        id: 'cardBrand',
        value: (item: TransactionItem) => <CellBase>{item.cardBrand}</CellBase>,
        label: t('transactionsManagement.transactions.list.cardType'),
        isSorted: true,
        sortField: 'SETTLEMENT_CARD_BRAND',
        width: 100,
      },
      {
        id: 'authorizationCode',
        value: (item: TransactionItem) => (
          <CellBase>{item.authorizationCode}</CellBase>
        ),
        label: t('transactionsManagement.transactions.list.authorizationCode'),
        isSorted: true,
        sortField: 'SETTLEMENT_AUTHORIZATION_CODE',
        width: 140,
      },
      {
        id: 'merchantName',
        value: (item: TransactionItem) => (
          <CellLight>{item.subMerchantName}</CellLight>
        ),
        label: t('transactionsManagement.transactions.list.subMerchantName'),
        isSorted: true,
        sortField: 'MERCHANT_NAME',
        width: 120,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t]
  );
};
