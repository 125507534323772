import React, { useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  getItemsLoadingComputed,
  getTotalSalesRollingPer30DayLoading,
} from '../selectors';
import { dayRunningInitRange } from './ComplianceAuditIndexComponent';
import { useSelector } from 'react-redux';
import { dateTimeZone } from 'domain/account/selectors';
import Divider from '@mui/joy/Divider';
import moment from 'moment-timezone';
import { ButtonGroup } from '@mui/material';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import { loadTotalSalesRollingPerDayItems } from '../thunks';
import {
  dateMonthFormatUtil,
  dateTimeFormatUtil,
  generationDateFormatUtil,
} from 'components/dateTimeFormatUtil';
import {
  DatePickerControl,
  DatePickerType,
  setDateFilter,
} from 'components/DatePickerControl';
import { Total30DaySalesChartComponent } from './charts-components/Total30DaySalesChartComponent';
import { RangeType } from '../utils';

type TotalDailySalesProps = {
  control: any;
  watch: any;
  setValue: any;
  merchantRef: any;
};

export const Running30DaysRangeComponent: React.FC<TotalDailySalesProps> = ({
  control,
  watch,
  setValue,
  merchantRef,
}) => {
  const dispatch = useAppDispatch();
  const itemsLoading = useAppSelector(getItemsLoadingComputed);
  const dateZone = useSelector(dateTimeZone);
  const totalSalesRollingPer30DayLoading = useAppSelector(
    getTotalSalesRollingPer30DayLoading
  );

  const dateFormatVal = (value: any) =>
    dateTimeFormatUtil(value, generationDateFormatUtil, dateZone);
  const dateMonthFormatVal = (value: any) =>
    dateMonthFormatUtil(value, dateZone);

  const running30RangeRef = useRef(dayRunningInitRange(dateZone, 30));
  const [isMonthRangeSelect, setMonthRangeSelect] = useState(false);

  const { running30Range } = watch();

  const click30DaysInit = () => {
    setMonthRangeSelect(false);
    setValue('running30Range', dayRunningInitRange(dateZone, 30));
  };

  const clickMonthRange = () => {
    setMonthRangeSelect(true);
  };

  const click30DaysRange = () => {
    setMonthRangeSelect(false);
  };

  const onDate30SaleRunningRangeApply = () => {
    dispatch(
      loadTotalSalesRollingPerDayItems({
        merchantId: merchantRef.current?.value,
        date: dateFormatVal(running30Range.to),
        rangeType: RangeType.THIRTY_DAYS,
      })
    );
    running30RangeRef.current = running30Range;
  };

  const handle30DaysChanges = (callback: any, dates: any) => {
    const start = moment(dates).tz(dateZone).subtract(29, 'day').toDate();

    if (dates) {
      setDateFilter(callback, { from: start, to: dates });
    }
  };

  const handleMonthYearChanges = (callback: any, dates: any) => {
    const start = moment(dates).tz(dateZone).startOf('month').toDate();
    const end = moment(dates).tz(dateZone).endOf('month').toDate();
    setDateFilter(callback, { from: start, to: end });
  };

  const handleMonthlyRange = (callback: any, dates: any) => {
    if (isMonthRangeSelect) {
      handleMonthYearChanges(callback, dates);
      return;
    }
    handle30DaysChanges(callback, dates);
  };

  const showMonthRangeValue = (value: any) => {
    return value && value.to && value.from ? dateMonthFormatVal(value.to) : '';
  };

  return (
    <div className="col-span-8 l1250g:col-span-4 4xl:col-span-3 items-center justify-center h-auto bg-white border p-4 rounded-md box-shadow-dark ">
      <Total30DaySalesChartComponent
        isMonthYearPicker={isMonthRangeSelect}
        dateRange={running30RangeRef.current}
      />
      <div className="m-3">
        <Divider />
      </div>
      {merchantRef.current && !itemsLoading && (
        <div className="grid grid-cols-3 gap-4">
          <div className="col-span-3 sm:col-span-2">
            <DatePickerControl
              control={control}
              handleChange={handleMonthlyRange}
              showValueChange={isMonthRangeSelect ? showMonthRangeValue : null}
              datePickerType={
                isMonthRangeSelect
                  ? DatePickerType.DATE_MONTH_RANGE
                  : DatePickerType.STANDARD_DATE_SELECT
              }
              popoverWidth={520}
              name="running30Range"
              maxDate={moment().subtract(1, 'd').toDate()}
              title="Date Range"
              footer={
                <div className="grid justify-center justify-items-center m-4">
                  <ButtonGroup
                    variant="outlined"
                    size="small"
                    aria-label="outlined button group"
                  >
                    <Button
                      style={{ width: '120px' }}
                      onClick={click30DaysInit}
                    >
                      Last 30 days
                    </Button>
                    {!isMonthRangeSelect ? (
                      <Button
                        style={{ width: '140px' }}
                        onClick={clickMonthRange}
                      >
                        Month Range
                      </Button>
                    ) : (
                      <Button
                        style={{ width: '140px' }}
                        onClick={click30DaysRange}
                      >
                        30 Days Range
                      </Button>
                    )}
                  </ButtonGroup>
                </div>
              }
            />
          </div>
          <div className="items-end justify-stretch justify-items-end mt-1 w-full col-span-3 sm:col-span-1">
            <LoadingButton
              size="small"
              variant="contained"
              style={{ width: '100%' }}
              loading={totalSalesRollingPer30DayLoading}
              onClick={onDate30SaleRunningRangeApply}
            >
              View Data
            </LoadingButton>
          </div>
        </div>
      )}
    </div>
  );
};
