import { PageHeader } from 'components/PageHeader';

type LayoutListProps = {
  title: string;
  children: React.ReactNode;
  titleRight?: React.ReactNode;
};

export const LayoutList: React.FC<LayoutListProps> = ({
  title,
  children,
  titleRight,
}) => {
  return (
    <div className="relative flex flex-col h-full bg-gray-200">
      <div className="absolute w-full">
        <PageHeader title={title} right={titleRight} />
      </div>
      <div className="h-full p-3 pt-19">{children}</div>
    </div>
  );
};
