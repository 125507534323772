import ChevronLeft from '@mui/icons-material/ChevronLeft';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { checkResetPasswordToken } from 'domain/account/thunks';
import { getInvalidToken, getIsLoading } from 'domain/account/selectors';
import { Loading } from 'components/Loading';

import { FormResetPassword } from './FormResetPassword';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const SubmittedIcon = () => (
  <svg width="70" height="94" viewBox="0 0 70 94" fill="none">
    <path
      opacity="0.4"
      d="M49.4247 52.7758C53.1 52.7758 56.7339 53.6951 59.934 55.4341C60.5152 55.7474 61.0485 56.1207 61.593 56.48C66.845 46.1989 69.6167 35.3254 69.6167 24.7176C69.6167 24.1215 69.6167 23.5061 69.5975 22.8908C69.559 22.0254 68.9436 21.2754 68.0782 21.0831C56.4629 18.5446 45.9436 12.2561 37.6744 2.90999L36.4436 1.50607C36.0782 1.08305 35.5591 0.852288 35.0013 0.852288C34.4051 0.775407 33.9436 1.0638 33.5782 1.46769L31.6936 3.54464C23.8475 12.1792 13.9628 18.1407 3.11676 20.7946L1.86671 21.1022C1.02055 21.2946 0.424401 22.0446 0.405151 22.9099C-0.287129 49.9484 15.6744 69.3908 22.559 76.5061C24.4231 78.4199 26.5012 80.322 28.4772 81.8943C27.736 79.6588 27.3093 77.3064 27.3093 74.8912C27.3093 62.6964 37.2299 52.7758 49.4247 52.7758Z"
      fill="#157BFA"
    />
    <circle cx="49.1042" cy="75.2708" r="18.2708" fill="#62DA97" />
    <path
      d="M57.0215 69.7896L47.6519 80.143L44.2319 76.4311"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const BackToLogin = () => {
  const { t } = useTranslation();

  return (
    <Link to="/login" className="mt-5 text-sm font-semibold text-blue-500">
      <ChevronLeft className="mr-2" fontSize="small" />
      {t('resetPasswordForm.backToLogin')}
    </Link>
  );
};

export const Container = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const isLoading = useAppSelector(getIsLoading);
  const invalidToken = useAppSelector(getInvalidToken);

  const [submitted, setSubmitted] = useState<boolean>(false);

  const { token } = useParams<{ token: string }>();

  useEffect(() => {
    dispatch(
      checkResetPasswordToken({
        confirmationCode: token,
      })
    );
  }, [dispatch, token]);

  if (isLoading || invalidToken) {
    return (
      <div className="flex items-center justify-center w-full min-h-screen py-12 px-3">
        {isLoading && <Loading className="text-blue-500" />}
        {invalidToken && (
          <div className="text-center">
            <p className="font-semibold text-red-500 mb-5">
              {t('resetPasswordForm.invalidToken')}
            </p>
            <BackToLogin />
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="flex justify-center w-full min-h-screen py-12">
      <div className="flex flex-col items-center lg:justify-center w-full max-w-sm px-3">
        {submitted && (
          <div className="mb-8">
            <SubmittedIcon />
          </div>
        )}
        <h3 className="mb-8 text-2xl font-bold text-gray-900">
          {submitted
            ? t('resetPasswordForm.submittedTitle')
            : t('resetPasswordForm.title')}
        </h3>
        {submitted && (
          <>
            <p className="px-5 mb-8 text-base font-medium text-center text-gray-700">
              {t('resetPasswordForm.submittedDescription')}
            </p>
            <BackToLogin />
          </>
        )}
        {!submitted && (
          <>
            {token ? (
              <GoogleReCaptchaProvider
                reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ''}
              >
                <FormResetPassword
                  token={token}
                  onSubmit={() => {
                    setSubmitted(true);
                  }}
                />
              </GoogleReCaptchaProvider>
            ) : (
              <FormResetPassword
                token={token}
                onSubmit={() => {
                  setSubmitted(true);
                }}
              />
            )}
            <BackToLogin />
          </>
        )}
      </div>
    </div>
  );
};
