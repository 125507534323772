import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  getLoggingDetails,
  getLoggingPage,
  getLoggingPagination,
  getLoggingRowsPage,
  getLoggingTotalNumber,
  isLoggingDetailsLoading,
} from '../selectors';
import { useEffect, useMemo, useRef, useState } from 'react';
import { loadLoggingItems } from '../thunks';
import { Base64 } from 'js-base64';
import {
  resetLoggingItems,
  setLoggingPage,
  setLoggingRowsPerPage,
} from '../userSlice';
import { ColumnProps, PaginationProps, TableCard } from 'components/table';
import { LayoutList } from 'layouts/list/LayoutList';
import { Button } from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useColumns } from './userColumns';
import { LoggingDetails, LoggingParams, LoggingStatus } from '../utils';
import { useForm } from 'react-hook-form';
import { logTypesItems } from './utils';
import { ItemAttributes } from 'components/itemAttributeType';
import { Filters } from './filters/Filters';
import { TimeRange } from 'entities';
import { DateRange } from 'types/dateRange';
import { dateTimeZone } from '../../../account/selectors';
import moment from 'moment-timezone';

const paramLogging = (param: string) => {
  switch (param) {
    case LoggingParams.USER_SIG_IN:
      return [LoggingStatus.SIGN_IN];
    case LoggingParams.USER_SIG_OUT:
      return [
        LoggingStatus.SIGN_OUT,
        LoggingStatus.SIGN_OUT_USER,
        LoggingStatus.SIGN_OUT_SYSTEM,
      ];
    case LoggingParams.USER_INVALID_PASSWORD:
      return [
        LoggingStatus.MAXIMUM_LOGIN_ATTEMPTS,
        LoggingStatus.INVALID_PASSWORD_ENTRIES,
      ];
    case LoggingParams.USER_INVALID_OTP:
      return [LoggingStatus.INVALID_OTP_ENTRIES];
    default:
      return null;
  }
};

export const UserAdditionalInfoComponent = () => {
  const { id, infoParam } = useParams<{ id: string; infoParam: string }>();
  const userId = Base64.decode(id);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const tableRef = useRef<HTMLDivElement>(null);
  const columns: ColumnProps[] = useColumns();
  const pagination: PaginationProps = useAppSelector(getLoggingPagination);
  const items: LoggingDetails[] = useAppSelector(getLoggingDetails);
  const page = useAppSelector(getLoggingPage);
  const rowsPerPage = useAppSelector(getLoggingRowsPage);
  const logsTotalNumber = useAppSelector(getLoggingTotalNumber);
  const isLoggingLoading = useAppSelector(isLoggingDetailsLoading);

  const dateZone = useAppSelector(dateTimeZone);

  const paramValue = useMemo(() => paramLogging(infoParam), [infoParam]);
  const logTypeList = paramValue
    ? logTypesItems.filter((v) => paramValue.includes(v.value))
    : ([] as ItemAttributes[]);
  const time: DateRange = {
    from: null,
    to: null,
  };
  const [appliedList, setAppliedList] = useState<ItemAttributes[]>(logTypeList);
  const [appliedTime, setAppliedTime] = useState<TimeRange | null>(null);

  const { control, setValue, watch } = useForm({
    mode: 'onBlur',
    defaultValues: {
      timeRage: time,
      logTypes: logTypeList,
    },
  });

  const { logTypes, timeRage } = watch();

  useEffect(() => {
    dispatch(
      loadLoggingItems({
        adminId: userId,
        search: {
          logTypes: appliedList.map((v) => v.value),
          timeRage: appliedTime,
        },
      })
    );
  }, [dispatch, id, userId, page, rowsPerPage, appliedList, appliedTime]);

  useEffect(
    () => () => {
      dispatch(resetLoggingItems());
    },
    [dispatch]
  );

  const onApply = () => {
    dispatch(resetLoggingItems());
    setAppliedList(logTypes);
    if (timeRage.from) {
      setAppliedTime({
        to: moment(timeRage?.to ? timeRage.to : timeRage.from)
          .tz(dateZone)
          .endOf('d')
          .format('YYYY-MM-DDTHH:mm:ss'),
        from: moment(timeRage.from)
          .tz(dateZone)
          .startOf('d')
          .format('YYYY-MM-DDTHH:mm:ss'),
      });
    }
  };

  const onClear = () => {
    dispatch(resetLoggingItems());
    setValue('timeRage', time);
    setValue('logTypes', [] as ItemAttributes[]);
    setAppliedList([] as ItemAttributes[]);
    setAppliedTime(null);
  };

  return (
    <LayoutList title={t('userManagement.loggingDetails')}>
      <TableCard
        tableRef={tableRef}
        columns={columns}
        loading={isLoggingLoading}
        items={items}
        onHover={false}
        totalNumber={logsTotalNumber}
        pagination={pagination}
        setPage={setLoggingPage}
        setRowsPerPage={setLoggingRowsPerPage}
        filterApply={onApply}
        filters={
          <Filters
            control={control}
            reset={onClear}
            isDisabledClean={false}
            onApply={onApply}
          />
        }
        backButton={
          <div className="px-3 py-2 flex-1">
            <Button
              onClick={() => history.goBack()}
              startIcon={<KeyboardBackspaceIcon />}
            >
              back
            </Button>
          </div>
        }
      />
    </LayoutList>
  );
};
