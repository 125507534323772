import { createAsyncThunk } from '@reduxjs/toolkit';
import { mappedSelectData } from 'utils/filters';
import { toast } from 'react-toastify';

import { userResource } from './api';
import {
  listRequestPayload,
  mappedUiTabsDetails,
  mappedUiTabsResult,
} from './utils';
import {
  getAppliedFilters,
  getPage,
  getPagination,
  getRowsPerPage,
  getSort,
} from './selectors';
import { PaginationProps } from 'components/table/TableFooter';
import { LoadItems } from 'entities';
import { RootStateType } from 'store/store';

export const loadItems: any = createAsyncThunk(
  'umRolesList/loadItems',
  async (options: LoadItems, thunkApi) => {
    try {
      const { getState } = thunkApi;
      const page = getPage(getState() as RootStateType);
      const sort = getSort(getState() as RootStateType);
      const rowsPerPage = getRowsPerPage(getState() as RootStateType);
      const appliedFilters = getAppliedFilters(getState() as RootStateType);

      const params = {
        page,
        limit: rowsPerPage,
        offset: (page - 1) * rowsPerPage,
        sort: sort,
        ...(options || {}),
        ...listRequestPayload(appliedFilters),
      };

      return await userResource.list(params);
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

export const reloadItems: any = createAsyncThunk(
  'umRolesList/reloadItems',
  async (_: void, thunkApi) => {
    try {
      const { dispatch, getState } = thunkApi;
      const { page, rowsPerPage }: PaginationProps = getPagination(
        getState() as RootStateType
      );

      dispatch(
        loadItems({
          offset: 0,
          limit: page * rowsPerPage,
        })
      );
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

export const fetchDetails: any = createAsyncThunk(
  'umRolesList/fetchDetails',
  async (data: { roleId: string }) => {
    try {
      const response = await userResource.fetchDetails(data);

      const { permissions, tabs } = response.data;
      const result = mappedSelectData({ data: permissions });
      const mappedTabs = mappedUiTabsDetails(tabs);

      return {
        ...response.data,
        permissions: result.data,
        tabs: mappedTabs,
        id: data.roleId,
      };
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

export const createRole: any = createAsyncThunk(
  'umRolesList/createRole',
  async (data: any, thunkApi) => {
    try {
      const { createdTime, permissions, tabs, id, sidebar, ...rest } = data;
      const permissionIds = permissions.map(({ value }: any) => value);
      const mappedTabs = mappedUiTabsResult(tabs);

      const response = await userResource.createRole({
        permissionIds,
        tabs: mappedTabs,
        ...rest,
      });

      thunkApi.dispatch(reloadItems());

      toast.success('Role created', {
        theme: 'colored',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
      });

      return response;
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

export const updateRole: any = createAsyncThunk(
  'umRolesList/updateRole',
  async (data: any, thunkApi) => {
    try {
      const { createdTime, permissions, tabs, sidebar, ...rest } = data;
      const permissionIds = permissions.map(({ value }: any) => value);
      const mappedTabs = mappedUiTabsResult(tabs);

      await userResource.updateRole({
        permissionIds,
        tabs: mappedTabs,
        ...rest,
      });

      thunkApi.dispatch(reloadItems());

      toast.success('Role updated', {
        theme: 'colored',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
      });

      return {
        tabs,
        createdTime,
        permissions,
        ...rest,
      };
    } catch (e) {
      return Promise.reject(e);
    }
  }
);
