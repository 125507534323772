import { PageHeader } from 'components/PageHeader';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import { useForm } from 'react-hook-form';
import { RequiredFieldComponent } from 'components/RequiredFiledComponent';
import { Input, TextArea } from 'components/inputs';
import { ReactNode, useEffect, useState } from 'react';
import { IconVisaRound } from 'components/icons/IconVisaRound';
import { IconMasterCardRound } from 'components/icons/IconMasterCardRound';
import { IconAmexRound } from 'components/icons/IconAmexRound';
import { IconDiscoverRound } from 'components/icons/IconDiscoverRound';
import { IconDinersRound } from 'components/icons/IconDinersRound';
import { IconJCBRound } from 'components/icons/IconJCBRound';
import AddCardIcon from '@mui/icons-material/AddCard';
import { useAppDispatch } from 'hooks/redux';
import { useHistory } from 'react-router-dom';
import { createBlockedPan } from '../../thunks';
import { findCardBrand } from '../../../../virtual-terminal-payment/thunks';
import { CardBrand } from '../../../../../entities';

export const CreateBlockedPan = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const {
    handleSubmit,
    register,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      cardNumber: '',
      reason: '',
    },
  });

  const { reason } = watch();

  const save = (data: any) => {
    dispatch(
      createBlockedPan({
        cardNumber: data.cardNumber.replace(/\s/g, ''),
        reason: data.reason,
      })
    )
      .unwrap()
      .then(() => {
        history.push('/fraud-prevention/blocked-pans');
      })
      .catch(() => history.push('/fraud-prevention/blocked-pans'));
  };

  const [maskedValue, setMaskedValue] = useState('');
  const [value, setValue] = useState('');
  const [cardBrand, setCardBrand] = useState<unknown>(null);

  const maskNumber = (number: string) => {
    const newValue = number.replace(/\D/g, '').slice(0, 19);
    return newValue.replace(/(.{4})/g, '$1 ').trim();
  };

  const discoverCreditBrand = (cadBrand: string) => {
    switch (cadBrand) {
      case CardBrand.VISA:
        return <IconVisaRound />;
      case CardBrand.MASTER_CARD:
        return <IconMasterCardRound />;
      case CardBrand.AMEX:
        return <IconAmexRound />;
      case CardBrand.DISCOVER:
        return <IconDiscoverRound />;
      case CardBrand.DINERS:
        return <IconDinersRound />;
      case CardBrand.JCB:
        return <IconJCBRound />;
      default:
        return null;
    }
  };

  const cancelChanges = () => {
    history.push('/fraud-prevention/blocked-pans');
  };

  useEffect(() => {
    setValue(maskedValue);
  }, [maskedValue]);

  const isSubmitDisabled =
    maskedValue.replace(/\s/g, '').length < 12 ||
    !reason.replace(/\s/g, '').length;

  const clearNumber = (valueCard = '') => {
    return valueCard.replace(/\D+/g, '');
  };

  const getCardBrand = (valueCard: string) => {
    const clearedValue = clearNumber(valueCard);
    if (clearedValue.length < 12) {
      setCardBrand(null);
      return;
    }
    dispatch(findCardBrand({ number: clearedValue }))
      .unwrap()
      .then((e: any) => {
        setCardBrand(discoverCreditBrand(e.data));
      })
      .catch(() => {
        setCardBrand(null);
      });
  };

  return (
    <div className="relative flex flex-col h-full bg-gray-200">
      <div className="flex justify-around items-center">
        <PageHeader title={t('blockedPans.createBlockedPan')} />
      </div>
      <div className="h-full pt-5 overflow-auto">
        <form
          onSubmit={handleSubmit(save)}
          className="w-full"
          autoComplete="off"
        >
          <div className="grid grid-cols-6 l1250g:grid-cols-8 4xl:grid-cols-12 gap-4">
            <div className="flex col-span-6 m850px:col-start-2 m850px:col-span-4 l1250g:col-start-3 l1250g:col-span-4 4xl:col-start-5 4xl:col-span-4 h-auto bg-white border sm:mx-4 mt-4 p-4 rounded-md box-shadow-dark">
              <div className="w-full">
                <div className="-mt-8 ">
                  <div className="flex flex-row items-center justify-start justify-items-start sm:ml-3 p-4 w-full sm:w-40 h-16 rounded-md box-shadow-roman box-bg-roman-300">
                    <div>
                      <AddCardIcon fontSize="large" sx={{ color: '#fff' }} />
                    </div>
                    <div className="text-xs text-white ml-2">
                      {t('blockedPans.createBlockedPan')}
                    </div>
                  </div>
                </div>
                <div className="grid gap-3 grid-cols-1 items-center justify-items-start my-4 sm:mx-4 sm:p-3">
                  <div className="w-full">
                    <Input
                      label={
                        <RequiredFieldComponent
                          labelText={t('blockedPans.cardPan')}
                        />
                      }
                      iconTop="top-1"
                      icon={cardBrand as ReactNode}
                      placeholder={t('blockedPans.cardPlaceholder')}
                      className="h-12"
                      labelFontClassName="font-medium tracking-wide text-xs"
                      labelColorClassName="text-back"
                      error={errors.cardNumber}
                      inputProps={{
                        value: value || '',
                        ...register('cardNumber', {
                          onChange: (e) =>
                            setMaskedValue(maskNumber(e.currentTarget.value)),
                          onBlur: (e: any) => getCardBrand(e.target.value),
                          required: true,
                          maxLength: {
                            value: 23,
                            message: t('blockedPans.cardMaxLength', {
                              maxLength: 19,
                            }),
                          },
                          minLength: {
                            value: 14,
                            message: t('blockedPans.cardMinLength', {
                              minLength: 12,
                            }),
                          },
                        }),
                      }}
                    />
                  </div>
                  <div className="w-full">
                    <TextArea
                      label={<RequiredFieldComponent labelText="Reason" />}
                      className="h-40"
                      error={errors.reason}
                      labelFontClassName="font-medium tracking-wide text-xs"
                      inputProps={register('reason', {
                        required: true,
                        maxLength: {
                          value: 255,
                          message: t('blockedPans.reasonMaxLength', {
                            maxLength: 255,
                          }),
                        },
                      })}
                    />
                  </div>
                  <div className="w-full sm:flex">
                    <div className="sm:w-1/2 sm:mr-1">
                      <LoadingButton
                        loading={isSubmitting}
                        loadingPosition="start"
                        disabled={isSubmitDisabled}
                        type="submit"
                        className="w-full"
                        variant="contained"
                        color="success"
                        startIcon={<BookmarkAddedIcon />}
                      >
                        <span className="text-xs sm:text-sm">Save</span>
                      </LoadingButton>
                    </div>
                    <div className="sm:w-1/2 sm:ml-1 mt-1 sm:mt-0">
                      <Button
                        onClick={cancelChanges}
                        disabled={isSubmitting}
                        className="w-full"
                        variant="contained"
                        color="error"
                        startIcon={<CancelPresentationIcon />}
                      >
                        <span className="text-xs sm:text-sm">Cancel</span>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
