import { createSlice } from '@reduxjs/toolkit';

import { createPayment, findCardBrand } from './thunks';

export interface VirtualTerminalState {
  createPaymentLoading: boolean;
  isModalOpen: boolean;
  isCreatedPayment: boolean;
  errorMessage: string;
  paymentStep: number;
  currentMerchant: any;
  currentNotificationId: any;
  itemNotFound: boolean;
  isCardBrandLoading: boolean;
  cardBrand: string;
  isCardBrandNotFound: boolean;
}

export const storeName = 'virtualTerminalPayment';

const initialState: VirtualTerminalState = {
  createPaymentLoading: false,
  isModalOpen: false,
  isCreatedPayment: false,
  errorMessage: '',
  paymentStep: 1,
  currentMerchant: null,
  currentNotificationId: null,
  itemNotFound: false,
  isCardBrandLoading: false,
  cardBrand: '',
  isCardBrandNotFound: false,
};

const virtualTerminalPaymentSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    upPaymentCreateStep(state) {
      state.paymentStep = state.paymentStep + 1;
    },
    setCurrentMerchant(state, action) {
      state.currentMerchant = action.payload;
    },

    downPaymentCreateStepNumber(state) {
      state.paymentStep = state.paymentStep - 1;
    },

    resetAll(state) {
      state.isModalOpen = initialState.isModalOpen;
      state.paymentStep = initialState.paymentStep;
      state.cardBrand = initialState.cardBrand;
    },

    clearPaymentCreate(state) {
      state.paymentStep = initialState.paymentStep;
      state.currentMerchant = initialState.currentMerchant;
    },
    closeModal(state) {
      state.isModalOpen = initialState.isModalOpen;
      state.paymentStep = initialState.paymentStep;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createPayment.fulfilled, (state) => {
      state.createPaymentLoading = false;
      state.isModalOpen = false;
      state.isCreatedPayment = true;
    });

    builder.addCase(createPayment.pending, (state) => {
      state.createPaymentLoading = true;
      state.isModalOpen = false;
      state.isCreatedPayment = false;
    });

    builder.addCase(createPayment.rejected, (state, action: any) => {
      const { error } = action;
      state.errorMessage = error?.message ? error.message : error?.code;
      state.createPaymentLoading = false;
      state.isModalOpen = true;
      state.isCreatedPayment = false;
    });

    builder.addCase(findCardBrand.fulfilled, (state, action: any) => {
      state.cardBrand = action.payload;
      state.isCardBrandLoading = initialState.isCardBrandLoading;
      state.isCardBrandNotFound = initialState.isCardBrandNotFound;
    });

    builder.addCase(findCardBrand.pending, (state) => {
      state.cardBrand = initialState.cardBrand;
      state.isCardBrandLoading = true;
      state.isCardBrandNotFound = initialState.isCardBrandNotFound;
    });

    builder.addCase(findCardBrand.rejected, (state) => {
      state.cardBrand = initialState.cardBrand;
      state.isCardBrandLoading = initialState.isCardBrandLoading;
      state.isCardBrandNotFound = true;
    });
  },
});

export const {
  upPaymentCreateStep,
  downPaymentCreateStepNumber,
  clearPaymentCreate,
  setCurrentMerchant,
  closeModal,
  resetAll,
} = virtualTerminalPaymentSlice.actions;

export default virtualTerminalPaymentSlice.reducer;
