import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { breadcrumbsSlice } from 'domain/breadcrumbs/breadcrumbsSlice';
import { useAppDispatch } from 'hooks/redux';
import { getAppliedFilters, getPage, getRowsPerPage } from '../selectors';
import { fetchServiceMonitor } from '../thunks';
import { ServiceMonitorList } from './ServiceMonitorList';
import { resetAllFilters } from '../serviceMonitorSlice';
import { useSelector } from 'react-redux';

export const Container = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const page = useSelector(getPage);
  const rowsPerPage = useSelector(getRowsPerPage);
  const appliedFilters = useSelector(getAppliedFilters);

  const { pathname } = useLocation();

  useEffect(() => {
    dispatch(
      breadcrumbsSlice.actions.setBreadcrumbs([
        {
          label: t('serviceMonitor.breadcrumb'),
          route: pathname,
        },
      ])
    );
  }, [dispatch, pathname, t]);

  useEffect(() => {
    dispatch(fetchServiceMonitor());
  }, [dispatch, page, appliedFilters, rowsPerPage]);

  useEffect(
    () => () => {
      dispatch(resetAllFilters());
    },
    [dispatch]
  );

  return <ServiceMonitorList />;
};
