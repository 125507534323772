import { useTranslation } from 'react-i18next';
import { PageHeader } from 'components/PageHeader';
import QueryStatsOutlinedIcon from '@mui/icons-material/QueryStatsOutlined';

import {
  ArcElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import { useEffect, useMemo, useRef } from 'react';
import { useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import { merchantList } from 'api/merchants';
import { FilterSelect } from 'components/filters/FilterSelect';
import classNames from 'classnames';
import { dateInitRange } from '../utils';
import moment from 'moment-timezone';

import { CircularProgress } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { loadCardInfoItems } from '../thunks';
import { ItemAttributes } from 'components/itemAttributeType';
import {
  getMerchantDetailsIconColor,
  MerchantStatuses,
} from 'domain/system/merchants/utils';
import { getItemsLoadingComputed, getTotalSalesItems } from '../selectors';
import LoadingButton from '@mui/lab/LoadingButton';
import { MerchantToggleStatusBadge } from 'domain/system/merchants/list/MerchantToggleStatusBadge';
import { useHistory } from 'react-router-dom';
import { Base64 } from 'js-base64';
import { useSelector } from 'react-redux';
import { dateTimeZone } from 'domain/account/selectors';
import { TotalDailySalesComponent } from './TotalDailySalesComponent';
import { TotalMonthlySalesComponent } from './TotalMonthlySalesComponent';
import { Running7DaysRangeComponent } from './Running7DaysRangeComponent';
import { Running30DaysRangeComponent } from './Running30DaysRangeComponent';
import { Running12MonthsRangeComponent } from './Running12MonthsRangeComponent';

type StatisticFormSchema = {
  merchantId: ItemAttributes | null;
  totalDailySalesRange: any;
  totalMonthlySalesRange: any;
  running7Range: any;
  running30Range: any;
  running12Range: any;
};

export const monthlyInitRange = (approvalDate: any, zone: any) => {
  return {
    from: approvalDate
      ? moment(approvalDate, 'yyyy-MM-DD').tz(zone).startOf('month').toDate()
      : moment().tz(zone).subtract(11, 'months').startOf('month').toDate(),
    to: moment().tz(zone).startOf('day').toDate(),
  };
};

export const dayRunningInitRange = (zone: any, period: any) => {
  return {
    from: moment().tz(zone).subtract(period, 'day').startOf('day').toDate(),
    to: moment().tz(zone).subtract(1, 'day').startOf('day').toDate(),
  };
};

export const yearRunningInitRange = (zone: any) => {
  return {
    from: moment().tz(zone).endOf('month').subtract(11, 'month').toDate(),
    to: moment().tz(zone).endOf('month').toDate(),
  };
};

export const ComplianceAuditIndexComponent = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history: any = useHistory();
  const totalSalesItems = useAppSelector(getTotalSalesItems);
  const itemsLoading = useAppSelector(getItemsLoadingComputed);

  const dateZone = useSelector(dateTimeZone);

  const initMonthRange = useMemo(
    () => monthlyInitRange(totalSalesItems.approvalDate, dateZone),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [totalSalesItems.approvalDate]
  );

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ArcElement
  );

  const { setValue, control, watch } = useForm<StatisticFormSchema>({
    mode: 'onBlur',
    defaultValues: {
      merchantId: null,
      totalDailySalesRange: dayRunningInitRange(dateZone, 30),
      totalMonthlySalesRange: initMonthRange,
      running7Range: dayRunningInitRange(dateZone, 7),
      running30Range: dayRunningInitRange(dateZone, 30),
      running12Range: yearRunningInitRange(dateZone),
    },
  });

  const { merchantId } = watch();

  const merchantRef = useRef<ItemAttributes | null>(null);

  useEffect(() => {
    if (history.location.state?.merchant) {
      setValue('merchantId', history.location.state?.merchant);
      dispatch(
        loadCardInfoItems({
          merchantId: history.location.state?.merchant.value,
        })
      );
      merchantRef.current = history.location.state?.merchant;
    }
  }, [setValue, dispatch, history.location.state?.merchant]);

  const isMerchantNotSelected =
    merchantId === null || merchantRef.current?.value === merchantId?.value;

  const applyMerchant = () => {
    dispatch(loadCardInfoItems({ merchantId: merchantId?.value }));
    merchantRef.current = merchantId;
    setValue('totalDailySalesRange', dateInitRange);
  };

  const onViewMerchantDetails = () => {
    history.push(
      `/system/merchants/details/${Base64.encodeURI(merchantId?.value)}`,
      {
        isSetToAudit: true,
      }
    );
  };

  return (
    <div className="relative flex flex-col h-full bg-gray-200">
      <div className="flex justify-around items-center">
        <PageHeader title={t('compliance-audit.complianceAudit')} />
      </div>
      <div className="h-full p-3 pt-5 overflow-auto custom-scrollbar">
        <div className="grid grid-cols-8 xxl:grid-cols-12 gap-4">
          {/*back bar*/}
          <div className="flex grid-flow-col items-center col-span-8 xxl:grid-cols-3 xxl:col-span-12 8xl:col-start-2 8xl:col-span-10 gap-4 h-auto bg-white border my-4 p-4 rounded-md box-shadow-dark">
            <div className="flex flex-wrap justify-between w-full">
              <div className="-mt-8 w-full sm:w-auto">
                {!itemsLoading ? (
                  <div
                    className={classNames(
                      'flex flex-row items-center justify-start justify-items-start p-4 w-full sm:w-40 h-16 rounded-md sm:ml-3',
                      getMerchantDetailsIconColor(totalSalesItems.status)
                    )}
                  >
                    <div>
                      <QueryStatsOutlinedIcon
                        fontSize="large"
                        sx={{ color: '#fff' }}
                      />
                    </div>
                    <div className="text-xs text-white ml-1">
                      Compliance Audit
                    </div>
                  </div>
                ) : (
                  <div
                    className={classNames(
                      'flex flex-row items-center justify-start justify-items-start p-4 w-full sm:w-40 h-16 rounded-md sm:ml-3 box-bg-white-300 box-shadow-grey'
                    )}
                  >
                    <div>
                      <CircularProgress />
                    </div>
                    <div className="text-xs text-blue-500 ml-1">
                      Compliance Audit
                    </div>
                  </div>
                )}
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-6 4xl:grid-cols-12 6xl:grid-cols-12 items-center sm:mx-4 my-4 w-full">
                <div className="sm:col-span-3 l2g:col-span-3 xxl:col-span-2">
                  <FilterSelect
                    placeholder="Select Merchant Name"
                    name="merchantId"
                    isMulti={false}
                    isClearable={false}
                    loadOptions={merchantList}
                    control={control}
                  />
                </div>
                <div className="col-span-3 m850px:col-span-3 xxl:col-span-2 items-end justify-end justify-items-end mt-1 m850px:ml-2">
                  <div className="flex flex-row w-full mt-4 sm:mt-0 sm:ml-2 m850px:ml-0">
                    <div className="flex-auto">
                      <LoadingButton
                        className="w-full h-8"
                        variant="contained"
                        size="small"
                        disabled={isMerchantNotSelected}
                        onClick={applyMerchant}
                        loading={itemsLoading}
                      >
                        Select Merchant
                      </LoadingButton>
                    </div>
                    <div className="ml-1">
                      {totalSalesItems.status && !itemsLoading ? (
                        <MerchantToggleStatusBadge
                          heightClass="h-8"
                          status={totalSalesItems.status}
                        >
                          {totalSalesItems.status} Merchant
                        </MerchantToggleStatusBadge>
                      ) : (
                        <MerchantToggleStatusBadge
                          heightClass="h-8"
                          status={MerchantStatuses.UNKNOWN}
                        >
                          {MerchantStatuses.UNKNOWN} Status
                        </MerchantToggleStatusBadge>
                      )}
                    </div>
                  </div>
                </div>

                <div className="sm:col-end-7 m850px:col-end-13 sm:col-span-3 m850px:col-span-2 flex gap-4 sm:items-end sm:justify-end sm:justify-items-end sm:-mr-2 mt-4 sm:mt-2 m850px:-mr-0 m850px:mt-2 m850px:ml-2">
                  <Button
                    size="small"
                    className="w-full"
                    disabled={merchantRef.current === null}
                    onClick={onViewMerchantDetails}
                  >
                    View Merchant Details
                  </Button>
                </div>
              </div>
            </div>
          </div>

          <div className="col-span-8 xxl:col-span-12 8xl:col-start-2 8xl:col-span-10 grid grid-cols-2 l1250g:grid-cols-8 4xl:grid-cols-9 gap-4">
            <div className="col-span-8 4xl:col-span-9 grid grid-cols-2 gap-4">
              <TotalDailySalesComponent
                control={control}
                watch={watch}
                setValue={setValue}
                merchantRef={merchantRef}
              />
              <TotalMonthlySalesComponent
                control={control}
                watch={watch}
                setValue={setValue}
                merchantRef={merchantRef}
              />
            </div>
            <Running7DaysRangeComponent
              control={control}
              watch={watch}
              setValue={setValue}
              merchantRef={merchantRef}
            />
            <Running30DaysRangeComponent
              control={control}
              watch={watch}
              setValue={setValue}
              merchantRef={merchantRef}
            />
            <Running12MonthsRangeComponent
              control={control}
              watch={watch}
              setValue={setValue}
              merchantRef={merchantRef}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
