import { getInputProps, InputProps } from './utils';
import Label from './Label';
import { PatternFormat } from 'react-number-format';

export const MaskedNumberInput = ({
  label,
  labelFontClassName,
  labelColorClassName,
  icon,
  name,
  error,
  type = 'text',
  placeholder = ' ',
  className,
  format = '',
  ...props
}: InputProps) => {
  const inputProps = getInputProps({
    type,
    error,
    className,
    placeholder,
    name,
  });

  return (
    <Label
      name={name}
      label={label}
      labelFontClassName={labelFontClassName}
      labelColorClassName={labelColorClassName}
      error={error}
    >
      {/* eslint-disable-next-line react/jsx-no-comment-textnodes */}
      <div className="relative">
        {/*todo check after update react v19*/}
        {/*<NumberFormat {...props}  isNumericString mask={''} />*/}
        <PatternFormat
          {...inputProps}
          value={props.value}
          format={format}
          valueIsNumericString
          mask=""
        />
        {icon && (
          <span className="absolute top-0 right-0 flex items-center justify-center w-12 h-full">
            {icon}
          </span>
        )}
      </div>
    </Label>
  );
};
