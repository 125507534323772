import { createSelector } from '@reduxjs/toolkit';

import { storeName } from './chargebacksSlice';
import { RootStateType } from 'store/store';

export const getRoot = (state: RootStateType) => state[storeName];

export const getPage = createSelector([getRoot], ({ page }) => page);

export const getSort = createSelector([getRoot], ({ sort }) => sort);

export const getRowsPerPage = createSelector(
  [getRoot],
  ({ rowsPerPage }) => rowsPerPage
);
export const getItems = createSelector([getRoot], ({ items }) => items);
export const getDetails = createSelector([getRoot], ({ details }) => details);
export const getNotFound = createSelector(
  [getRoot],
  ({ chargebackNotFound }) => chargebackNotFound
);

export const getLoadingDetails = createSelector(
  [getRoot],
  ({ isDetailsLoading }) => isDetailsLoading
);

export const getTotalNumber = createSelector(
  [getRoot],
  ({ totalNumber }) => totalNumber
);

export const getPagination = createSelector(
  [getRoot],
  ({
    items,
    page,
    endReached,
    rowsPerPage,
    paginationLoading,
    itemsLoading,
  }) => ({
    total: items.length,
    page,
    endReached,
    rowsPerPage,
    paginationLoading,
    itemsLoading,
  })
);

export const getItemsLoadingComputed = createSelector(
  [getRoot],
  ({ itemsLoading }) => itemsLoading
);
