import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { breadcrumbsSlice } from 'domain/breadcrumbs/breadcrumbsSlice';

import { useAppDispatch } from 'hooks/redux';

import { loadItems } from '../thunks';
import { getPage, getRowsPerPage, getSort } from '../selectors';
import { ChargebacksList } from './ChargebacksList';
import { useLocation } from 'react-router-dom';
import { resetAllItems } from '../chargebacksSlice';

export const Container = () => {
  const { t } = useTranslation();

  const page = useSelector(getPage);
  const sort = useSelector(getSort);
  const rowsPerPage = useSelector(getRowsPerPage);

  const dispatch = useAppDispatch();

  const storeDispatch = useDispatch();
  const { pathname } = useLocation();

  useEffect(() => {
    storeDispatch(
      breadcrumbsSlice.actions.setBreadcrumbs([
        {
          label: t('disputeResolution.breadcrumb'),
          route: pathname,
        },
        {
          label: t('disputeResolution.chargebacks.breadcrumb'),
          route: pathname,
        },
      ])
    );
  }, [storeDispatch, pathname, t]);

  useEffect(() => {
    dispatch(loadItems());
  }, [dispatch, page, rowsPerPage, sort]);

  useEffect(
    () => () => {
      dispatch(resetAllItems());
    },
    [dispatch]
  );

  return <ChargebacksList />;
};
