import { Line } from 'react-chartjs-2';
import React, { useMemo } from 'react';

import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';

import { RectLoader } from 'components/RectLoader';

import { TotalSalesResponse } from '../../utils';
import { getItemsLoadingComputed, getTotalSalesItems } from '../../selectors';
import { useAppSelector } from 'hooks/redux';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { dateTimeZone } from 'domain/account/selectors';
import { dateMonthFormatUtil2 } from 'components/dateTimeFormatUtil';

interface TotalDailySalesChartProps {
  dateRange: any;
}

export const TotalMonthlySalesChartComponent: React.FC<
  TotalDailySalesChartProps
> = ({ dateRange }) => {
  const totalSalesItems = useAppSelector(getTotalSalesItems);
  const itemsLoading = useAppSelector(getItemsLoadingComputed);

  const dateZone = useSelector(dateTimeZone);
  const dateMonthFormatVal = (value: any, format: any = null) =>
    dateMonthFormatUtil2(value, dateZone, format);

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  const dateViewFormat = [
    'Total Sales per Month',
    `Last ${
      moment(dateRange?.to)?.diff(dateRange.from, 'months') + 1
    } months ${dateMonthFormatVal(dateRange.from)} - ${dateMonthFormatVal(dateRange.to)}`,
  ];

  const transactionDaysPeriod = useMemo(() => {
    const dateArray = [] as TotalSalesResponse[];
    const currentDate = dateRange.to;
    let stopDate = dateRange.from;
    while (currentDate >= stopDate) {
      const monthFormat = dateMonthFormatVal(stopDate, 'YYYY-MM');
      const find = totalSalesItems.totalSalesPerMonth.find(
        (it) => it.date === monthFormat
      );

      dateArray.push({
        date: dateMonthFormatVal(stopDate, 'MMM-YYYY'),
        calculatedVolume:
          find && find.calculatedVolume ? find.calculatedVolume : 0,
        expectedVolume: find && find.expectedVolume ? find.expectedVolume : 0,
        expectedVolumeNotice:
          find && find.expectedVolumeNotice ? find.expectedVolumeNotice : 0,
        expectedVolumeBlock:
          find && find.expectedVolumeBlock ? find.expectedVolumeBlock : 0,
      });
      stopDate = moment(stopDate).tz(dateZone).add(1, 'month');
    }

    return dateArray;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange.from, dateRange.to, totalSalesItems.totalSalesPerMonth]);

  const totalTransactionOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom' as const,
      },
      title: {
        display: true,
        color: '#000000',
        text: dateViewFormat,
        font: {
          size: 14,
          weight: 'normal' as const,
        },
      },
    },
    elements: {
      point: {
        radius: 2,
        hoverRadius: 4,
        hoverBackgroundColor: '1976D2',
        hitRadius: 8,
      },
    },
    scales: {
      y: {
        offset: false,
        beginAtZero: true,
        grace: '15%',
        min: 0,
        ticks: {
          stepSize: 20,
        },
      },
    },
  };

  const totalTransactionData = {
    labels: transactionDaysPeriod.map((it) => it.date),
    datasets: [
      {
        label: 'Expected Daily Volume',
        data: transactionDaysPeriod.map((it) => it.expectedVolume),
        fill: false,
        backgroundColor: '#bb7202',
        borderColor: '#bb7202',
        tension: 0.1,
      },
      {
        label: 'Expected Daily Volume Notice',
        data: transactionDaysPeriod.map((it) => it.expectedVolumeNotice),
        fill: false,
        backgroundColor: 'rgb(211,185,2)',
        borderColor: 'rgb(211,185,2)',
        tension: 0.1,
      },
      {
        label: 'Expected Daily Volume Block',
        data: transactionDaysPeriod.map((it) => it.expectedVolumeBlock),
        fill: false,
        backgroundColor: '#545353',
        borderColor: '#545353',
        tension: 0.1,
      },
      {
        label: 'Calculated Volume',
        data: transactionDaysPeriod.map((it) => it.calculatedVolume),
        fill: false,
        backgroundColor: 'rgb(42,74,229)',
        borderColor: 'rgb(42,74,229)',
        tension: 0.1,
      },
    ],
    options: {
      animations: {
        radius: {
          duration: 400,
          easing: 'linear',
          loop: (context) => context.active,
        },
      },
      interaction: {
        mode: 'point',
      },
      plugins: {
        tooltip: {
          enabled: false,
        },
      },
    },
  };

  return (
    <div>
      {itemsLoading ? (
        <RectLoader width={150} height={28} />
      ) : (
        <Line
          options={totalTransactionOptions}
          data={totalTransactionData}
          height="450px"
          width="100%"
        />
      )}
    </div>
  );
};
