import React, { useState } from 'react';
import { ItemSort } from '../../entities';
import { ColumnProps, SortingDirection } from './Table';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';

interface ColumnComponentProps {
  item: ColumnProps;
  setSort?: (sort: ItemSort) => void;
}

export const ColumnComponent: React.FC<ColumnComponentProps> = ({
  item,
  setSort,
}) => {
  const [currentSort, setCurrentSort] = useState<ItemSort | null>(null);

  const sortItems = (column: ColumnProps) => {
    if (
      currentSort === null ||
      currentSort.field !== column.sortField ||
      (currentSort?.field === column.sortField &&
        currentSort.type === SortingDirection.DESC)
    ) {
      const field = { field: column.sortField!, type: SortingDirection.ASC };
      setCurrentSort(field);
      setSort!(field);
    } else if (
      currentSort?.field === column.sortField &&
      currentSort.type === SortingDirection.ASC
    ) {
      const field = { field: column.sortField!, type: SortingDirection.DESC };
      setCurrentSort(field);
      setSort!(field);
    }
  };

  return (
    <th
      className="sticky top-0 z-10 py-2 pl-4 border-t-0"
      style={{ background: '#F0F4F8' }}
    >
      {item.headerContent ? (
        item.headerContent()
      ) : (
        <div style={{ minWidth: item.minWidth, width: item.width }}>
          <div className="flex items-center  mb-1">
            {item.isSorted ? (
              <>
                <button
                  onClick={() => sortItems(item)}
                  className="font-normal justify-items-center text-black cursor-pointer text-sm focus:outline-none"
                >
                  {item.label || ' '}
                  <UnfoldMoreIcon style={{ fontSize: '12px' }} />
                </button>
              </>
            ) : (
              <span className="font-normal text-black text-sm focus:outline-none">
                {item.label || ' '}
              </span>
            )}
          </div>
        </div>
      )}
    </th>
  );
};
