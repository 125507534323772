import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { breadcrumbsSlice } from 'domain/breadcrumbs/breadcrumbsSlice';
import { useAppDispatch, useAppSelector } from 'hooks/redux';

import { SettlementsList } from './SettlementsList';
import {
  getAppliedFilters,
  getPage,
  getRowsPerPage,
  getSort,
} from '../selectors';
import { loadItems } from '../thunks';
import { useLocation } from 'react-router-dom';

export const Container = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const page = useAppSelector(getPage);
  const appliedFilters = useAppSelector(getAppliedFilters);
  const rowsPerPage = useAppSelector(getRowsPerPage);
  const sort = useAppSelector(getSort);

  const { pathname } = useLocation();

  useEffect(() => {
    dispatch(
      breadcrumbsSlice.actions.setBreadcrumbs([
        {
          label: t('transactionsManagement.breadcrumb'),
          route: pathname,
        },
        {
          label: t('transactionsManagement.transactions.breadcrumb'),
          route: pathname,
        },
      ])
    );
  }, [dispatch, pathname, t]);

  useEffect(() => {
    dispatch(loadItems());
  }, [dispatch, page, appliedFilters, rowsPerPage, sort]);

  return <SettlementsList />;
};
