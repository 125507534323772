import { Children, cloneElement, ReactElement, useState } from 'react';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import FilterListIcon from '@mui/icons-material/FilterList';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { useTranslation } from 'react-i18next';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';

export const FilterDrawer = ({ children, apply }) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="px-3 py-2">
      <Button onClick={toggleDrawer} startIcon={<FilterListIcon />}>
        <span className="normal-case text-lg">{t('filters.button')}</span>
      </Button>
      <SwipeableDrawer
        anchor="right"
        open={isOpen}
        onKeyUp={(event) => {
          if (apply && event.key == 'Enter') {
            toggleDrawer();
            apply();
          }
        }}
        onClose={toggleDrawer}
        onOpen={toggleDrawer}
      >
        <div className="p-6 sm:w-[490px] h-full overflow-y-auto custom-scrollbar flex flex-col">
          <div className="flex items-center justify-between pb-1">
            <p className="text-lg font-semibold">{t('filters.title')}</p>
            <IconButton aria-label="close" onClick={toggleDrawer} size="small">
              <CloseIcon />
            </IconButton>
          </div>
          <Divider />
          <div className="mt-4 flex-1">
            {Children.map(children, (child: ReactElement) =>
              cloneElement(child, { toggleDrawer } as {
                toggleDrawer: () => void;
              })
            )}
          </div>
        </div>
      </SwipeableDrawer>
    </div>
  );
};
