import { createAsyncThunk } from '@reduxjs/toolkit';

import { virtualTerminalResource } from './api';
import { LoadItems } from 'entities';
import { listRequestPayload } from './utils';
import { dateTimeZone } from '../../account/selectors';
import { RootStateType } from 'store/store';
import {
  getAppliedFilters,
  getPage,
  getRowsPerPage,
  getSort,
} from './selectors';

export const loadItems: any = createAsyncThunk(
  'virtualTerminal/loadItems',
  async (options: LoadItems, thunkApi) => {
    try {
      const { getState } = thunkApi;

      const page = getPage(getState() as RootStateType);
      const sort = getSort(getState() as RootStateType);
      const rowsPerPage = getRowsPerPage(getState() as RootStateType);
      const appliedFilters = getAppliedFilters(getState() as RootStateType);

      const dateZone = dateTimeZone(getState() as RootStateType);

      const params = {
        page,
        limit: rowsPerPage,
        offset: (page - 1) * rowsPerPage,
        sort: sort,
        ...(options || {}),
        ...listRequestPayload(appliedFilters, dateZone),
      };

      return await virtualTerminalResource.paymentList(params);
    } catch (e) {
      return Promise.reject(e);
    }
  }
);
