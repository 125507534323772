import { createSelector } from '@reduxjs/toolkit';

import { storeName } from './dashboradSlice';
import { RootStateType } from 'store/store';

export const getRoot = (state: RootStateType) => state[storeName];

export const getCardInfo = createSelector(
  [getRoot],
  ({ cardInfoItem }) => cardInfoItem
);

export const getUnmatchedTransactions = createSelector(
  [getRoot],
  ({ unmatchedTransactions }) => unmatchedTransactions
);

export const getItemsLoadingComputed = createSelector(
  [getRoot],
  ({ itemsLoading }) => itemsLoading
);

export const getTransactionStatusLoadingComputed = createSelector(
  [getRoot],
  ({ transactionStatusLoading }) => transactionStatusLoading
);

export const getTransactionTypeLoadingComputed = createSelector(
  [getRoot],
  ({ transactionTypeLoading }) => transactionTypeLoading
);
