import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import numeral from 'numeral';
import { CellBase, CellLight, ColumnProps } from 'components/table/Table';
import {
  AuthorizationItem,
  AuthorizationStatus,
} from 'entities/transaction-management';
import { CardType } from '../../transactions/transaction';
import { AuthorizationStatusBadge } from './AuthorizationStatusBadge';
import { useSelector } from 'react-redux';
import { dateTimeFormat, dateTimeZone } from 'domain/account/selectors';
import {
  dateFormatUtil,
  dateTimeFormatUtil,
  timeFormatUtil,
} from 'components/dateTimeFormatUtil';
import moment from 'moment';

export const useAuthorizationsListColumns = () => {
  const { t } = useTranslation();
  const dateFormat = useSelector(dateTimeFormat);
  const dateZone = useSelector(dateTimeZone);

  const zone = useMemo(
    () => (dateZone ? dateZone : moment.tz.guess()),
    [dateZone]
  );

  const format = useMemo(
    () => (dateFormat ? dateFormat : dateFormatUtil),
    [dateFormat]
  );

  const timeFormat = (value: string) =>
    dateTimeFormatUtil(value, format + timeFormatUtil, zone);

  return useMemo(
    (): ColumnProps[] => [
      {
        id: 'transmissionId',
        value: (item: AuthorizationItem) => <CellLight>{item.id}</CellLight>,
        label: t('transactionsManagement.authorizations.list.transactionId'),
        width: 280,
      },
      {
        id: 'transmissionDate',
        value: (item: AuthorizationItem) => (
          <CellLight>
            {item.transmissionDate && timeFormat(item.transmissionDate)}
          </CellLight>
        ),
        label: t('transactionsManagement.authorizations.list.transmissionDate'),
        isSorted: true,
        sortField: 'AUTHORIZATION_TRANSMISSION_TIME',
        width: 220,
      },
      {
        id: 'transactionStatus',
        value: (item: AuthorizationItem) => (
          <CellLight>
            {item.status && (
              <AuthorizationStatusBadge
                widthClass={'w-20'}
                status={item.status}
              >
                {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  //@ts-ignore
                  Object.values(AuthorizationStatus).includes(item.status)
                    ? t(
                        `transactionsManagement.authorizations.status.${item.status}`
                      )
                    : item.status
                }
              </AuthorizationStatusBadge>
            )}
          </CellLight>
        ),
        label: t('transactionsManagement.transactions.list.transactionStatus'),
        isSorted: true,
        sortField: 'AUTHORIZATION_STATUS',
        width: 140,
      },
      {
        id: 'transactionType',
        value: (item: AuthorizationItem) => (
          <CellBase>
            {item.transactionType &&
              t(
                `transactionsManagement.authorizations.transactionType.${item.transactionType}`
              )}
          </CellBase>
        ),
        label: t('transactionsManagement.transactions.list.transactionType'),
        isSorted: true,
        sortField: 'AUTHORIZATION_TYPE',
        width: 100,
      },
      {
        id: 'transactionAmount',
        value: (item: AuthorizationItem) => (
          <CellBase>{numeral(item.transactionAmount).format('0.00')}</CellBase>
        ),
        label: t(
          'transactionsManagement.authorizations.list.transactionAmount'
        ),
        isSorted: true,
        sortField: 'AUTHORIZATION_AMOUNT',
        width: 100,
      },
      {
        id: 'transactionCurrency',
        value: (item: AuthorizationItem) => (
          <CellBase>{item.transactionCurrency}</CellBase>
        ),
        label: t(
          'transactionsManagement.authorizations.list.transactionCurrency'
        ),
        isSorted: true,
        sortField: 'AUTHORIZATION_CURRENCY',
        width: 100,
      },
      {
        id: 'maskedPan',
        value: (item: AuthorizationItem) => (
          <CellBase>{item.cardNumber}</CellBase>
        ),
        label: t('transactionsManagement.authorizations.list.maskedPan'),
        isSorted: true,
        sortField: 'AUTHORIZATION_MASKED_PAN',
        width: 125,
      },
      {
        id: 'cardType',
        value: (item: AuthorizationItem) => (
          <CellBase>
            {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-ignore
              item.cardType && Object.values(CardType).includes(item.cardType)
                ? t(
                    `transactionsManagement.transactions.cardType.${item.cardType}`
                  )
                : item.cardType
            }
          </CellBase>
        ),
        label: t('transactionsManagement.authorizations.list.cardType'),
        isSorted: true,
        sortField: 'AUTHORIZATION_CARD_BRAND',
        width: 100,
      },
      {
        id: 'responseCode',
        value: (item: AuthorizationItem) => (
          <CellBase>{item.responseCode}</CellBase>
        ),
        label: t('transactionsManagement.authorizations.list.responseCode'),
        isSorted: true,
        sortField: 'AUTHORIZATION_RESPONSE_CODE',
        width: 120,
      },
      {
        id: 'authId',
        value: (item: AuthorizationItem) => <CellBase>{item.authId}</CellBase>,
        label: t('transactionsManagement.authorizations.list.authId'),
        isSorted: true,
        sortField: 'AUTHORIZATION_AUTH_ID',
        width: 100,
      },
      {
        id: 'merchantName',
        value: (item: AuthorizationItem) => (
          <CellLight>{item.merchantName}</CellLight>
        ),
        label: t('transactionsManagement.transactions.list.subMerchantName'),
        isSorted: true,
        sortField: 'MERCHANT_NAME',
        width: 120,
      },
      {
        id: 'stan',
        value: (item: AuthorizationItem) => <CellBase>{item.stan}</CellBase>,
        label: t('transactionsManagement.authorizations.list.stan'),
        isSorted: true,
        sortField: 'AUTHORIZATION_STAN',
        width: 100,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t]
  );
};
