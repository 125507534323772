import React, { ReactNode } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';

import { IconCalendar } from 'components/icons/IconCalendar';
import {
  DropdownDateTime,
  InputDropdownComponent,
} from './dropdown-component/DropdownDateTime';
import { Controller } from 'react-hook-form';
import { dateRangeFormatUtil, dateTimeFormatUtil } from './dateTimeFormatUtil';
import { useSelector } from 'react-redux';
import { dateTimeFormat, dateTimeZone } from '../domain/account/selectors';
import moment from 'moment-timezone';
import { monthYearFormat } from '../domain/reports/utils';
import * as PopperJS from '@popperjs/core';

export enum DatePickerType {
  STANDARD_DATE_SELECT = 'STANDARD_DATE_SELECT',
  STANDARD_DATE_SELECT_ONE_MONTH = 'STANDARD_DATE_SELECT_ONE_MONTH',
  STANDARD_DATE_SELECT_TWO_MONTH = 'STANDARD_DATE_SELECT_TWO_MONTH',
  STANDARD_DATE_RANGE = 'STANDARD_DATE_RANGE',
  DATE_MONTH_RANGE = 'DATE_MONTH_RANGE',
  STANDARD_DATE_SELECT_ONE_MONTH_NO_RANGE = 'STANDARD_DATE_SELECT_ONE_MONTH_NO_RANGE',
  MONTH_START_END_RANGE = 'MONTH_START_END_RANGE',
  MONTH_START_END_RANGE_ONE_MONTH = 'MONTH_START_END_RANGE_ONE_MONTH',
  MONTH_RANGE_SELECT = 'MONTH_RANGE_SELECT',
}

export const setDateFilter = (callback: any, dateRange: any) => {
  const { to, from } = dateRange;
  callback({
    to: to,
    from: from,
  });
};

interface DatePickerDropDownProps {
  title: string;
  control: any;
  datePickerType?: DatePickerType;
  handleChange?: any;
  showValueChange?: any;
  name: any;
  errorMessage?: any;
  onClearFilter?: () => void;
  className?: string;
  popoverWidth?: number;
  maxDate?: Date;
  minDate?: Date;
  label?: any;
  footer?: ReactNode;
  placeholder?: string;
  closeOnSelect?: boolean;
  inputHeightClassName?: string;
  labelClass?: string;
  isLoading?: boolean;
  placementPosition?: PopperJS.Placement;
}

export const DatePickerControl: React.FC<DatePickerDropDownProps> = ({
  title,
  control,
  name,
  datePickerType = DatePickerType.STANDARD_DATE_SELECT,
  showValueChange = null,
  handleChange = null,
  errorMessage = null,
  onClearFilter,
  className,
  popoverWidth = 520,
  maxDate,
  minDate,
  label,
  footer,
  placeholder,
  inputHeightClassName,
  labelClass,
  placementPosition,
}) => {
  const dateFormat = useSelector(dateTimeFormat);
  const dateZone = useSelector(dateTimeZone);
  const dateFormatVal = (value: any) =>
    dateTimeFormatUtil(value, dateFormat, dateZone);

  const showValue = (value: any) => {
    if (showValueChange) {
      return showValueChange(value);
    }
    if (value && value.to && value.from) {
      return (
        dateFormatVal(moment(value.from)) +
        ' : ' +
        dateFormatVal(moment(value.to))
      );
    }

    return value?.from ? dateFormatVal(moment(value.from)) : '';
  };

  const handleChangeRange = (onChange: any, data: any, close: any) => {
    handleChange(onChange, data);

    if (!data?.length) {
      close();
    }
    if (data?.length && data.filter((date: any) => !date).length === 0) {
      close();
    }
  };

  const handleKeyboardEvent = (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  const selectDatePickerType = (onChange: any, value: any, close: any) => {
    const startDate = value && value.from ? value.from : null;
    const endDate = value && value.to ? value.to : null;
    switch (datePickerType) {
      case DatePickerType.STANDARD_DATE_SELECT:
        return (
          <DatePicker
            className={className}
            maxDate={maxDate}
            minDate={minDate}
            onChange={(data) => handleChange(onChange, data)}
            dateFormat="yyyy/MM/dd"
            monthsShown={2}
            inline
            disabledKeyboardNavigation
            startDate={value && value.from ? value.from : null}
            endDate={value && value.to ? value.to : null}
          />
        );
      case DatePickerType.STANDARD_DATE_SELECT_ONE_MONTH:
        return (
          <DatePicker
            className={className}
            maxDate={maxDate}
            minDate={minDate}
            onChange={(data) => handleChangeRange(onChange, data, close)}
            shouldCloseOnSelect={!(!startDate || Boolean(startDate && endDate))}
            onKeyDown={(e: any) => {
              if (e.key === 'Enter') {
                e.preventDefault();
              }
            }}
            dateFormat="yyyy/MM/dd"
            inline
            selectsRange
            disabledKeyboardNavigation
            startDate={value && value.from ? value.from : null}
            endDate={value && value.to ? value.to : null}
          />
        );
      case DatePickerType.STANDARD_DATE_SELECT_TWO_MONTH:
        return (
          <DatePicker
            className={className}
            maxDate={maxDate}
            minDate={minDate}
            onChange={(data) => handleChangeRange(onChange, data, close)}
            shouldCloseOnSelect={!(!startDate || Boolean(startDate && endDate))}
            onKeyDown={handleKeyboardEvent}
            dateFormat="yyyy/MM/dd"
            monthsShown={2}
            inline
            selectsRange
            disabledKeyboardNavigation
            startDate={value && value.from ? value.from : null}
            endDate={value && value.to ? value.to : null}
          />
        );
      case DatePickerType.STANDARD_DATE_RANGE:
        return (
          <DatePicker
            className={className}
            maxDate={maxDate}
            minDate={minDate}
            onChange={(data) => handleChange(onChange, data)}
            dateFormat="yyyy/MM/dd"
            monthsShown={2}
            inline
            selectsRange
            disabledKeyboardNavigation
            startDate={value && value.from ? value.from : null}
            endDate={value && value.to ? value.to : null}
          />
        );
      case DatePickerType.DATE_MONTH_RANGE:
        return (
          <div className="flex justify-center">
            <DatePicker
              className={className}
              selected={new Date(moment(value.to).format(monthYearFormat))}
              maxDate={maxDate}
              minDate={minDate}
              onChange={(data) => handleChange(onChange, data)}
              dateFormat="MMMM/yyyy"
              showMonthYearPicker
              yearItemNumber={2}
              inline
              disabledKeyboardNavigation
            />
          </div>
        );
      case DatePickerType.STANDARD_DATE_SELECT_ONE_MONTH_NO_RANGE:
        return (
          <div className="flex justify-center">
            <DatePicker
              className={className}
              selected={
                value?.from
                  ? new Date(
                      moment(value.from)
                        .tz(dateZone)
                        .format(dateRangeFormatUtil)
                    )
                  : undefined
              }
              maxDate={maxDate}
              minDate={minDate}
              onChange={(data) => handleChangeRange(onChange, data, close)}
              onKeyDown={(e: any) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                }
              }}
              dateFormat="yyyy/MM/dd"
              inline
              disabledKeyboardNavigation
            />
          </div>
        );
      case DatePickerType.MONTH_START_END_RANGE:
        return (
          <div className="flex justify-center">
            <DatePicker
              className={className}
              maxDate={maxDate}
              minDate={minDate}
              onChange={(data) => handleChange(onChange, data)}
              dateFormat="MMMM/yyyy"
              showMonthYearPicker
              yearItemNumber={2}
              inline
              disabledKeyboardNavigation
              startDate={value && value.from ? value.from : null}
              endDate={value && value.to ? value.to : null}
            />
          </div>
        );
      case DatePickerType.MONTH_START_END_RANGE_ONE_MONTH:
        return (
          <div className="flex justify-center">
            <DatePicker
              className={className}
              maxDate={maxDate}
              minDate={minDate}
              onChange={(data) => handleChangeRange(onChange, data, close)}
              shouldCloseOnSelect={
                !(!startDate || Boolean(startDate && endDate))
              }
              onKeyDown={(e: any) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                }
              }}
              dateFormat="MMMM/yyyy"
              showMonthYearPicker
              selectsRange
              inline
              disabledKeyboardNavigation
              startDate={value && value.from ? value.from : null}
              endDate={value && value.to ? value.to : null}
            />
          </div>
        );
      case DatePickerType.MONTH_RANGE_SELECT:
        return (
          <div className="flex justify-center">
            <DatePicker
              className={className}
              maxDate={maxDate}
              minDate={minDate}
              onChange={(data) => handleChange(onChange, data)}
              dateFormat="MMMM/yyyy"
              showMonthYearPicker
              selectsRange
              yearItemNumber={2}
              inline
              disabledKeyboardNavigation
              startDate={value && value.from ? value.from : null}
              endDate={value && value.to ? value.to : null}
            />
          </div>
        );
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange } }) => (
        <DropdownDateTime
          title={title}
          errorMessage={errorMessage}
          footer={footer}
          popoverWidth={popoverWidth}
          onClearFilter={onClearFilter}
          placementPosition={placementPosition}
          value={showValue(value)}
          trigger={(dropdownProps) => (
            <InputDropdownComponent
              name={'filterKey'}
              label={label}
              labelClass={labelClass}
              placeholder={placeholder}
              value={showValue(value)}
              inputHeightClassName={inputHeightClassName}
              icon={
                <div className="px-2 -mr-3 bg-white">
                  <IconCalendar
                    className={
                      dropdownProps.popoverIsOpen
                        ? 'text-blue-500'
                        : 'text-gray-700'
                    }
                  />
                </div>
              }
              {...dropdownProps}
            />
          )}
        >
          {(close) => (
            <div className={className}>
              {selectDatePickerType(onChange, value, close)}
            </div>
          )}
        </DropdownDateTime>
      )}
    />
  );
};
