import { createSelector } from '@reduxjs/toolkit';

import { storeName } from './virtualTerminalPaymentSlice';
import { RootStateType } from 'store/store';

export const getRoot = (state: RootStateType) => state[storeName];

export const getCreatePaymentLoadingComputed = createSelector(
  [getRoot],
  ({ createPaymentLoading }) => createPaymentLoading
);
export const getPaymentStep = createSelector(
  [getRoot],
  ({ paymentStep }) => paymentStep
);
export const getCurrentMerchant = createSelector(
  [getRoot],
  ({ currentMerchant }) => currentMerchant
);

export const isCardBrandLoadingSelect = createSelector(
  [getRoot],
  ({ isCardBrandLoading }) => isCardBrandLoading
);

export const getModalOpen = createSelector(
  [getRoot],
  ({ isModalOpen }) => isModalOpen
);

export const getErrorMessage = createSelector(
  [getRoot],
  ({ errorMessage }) => errorMessage
);
