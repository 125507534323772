import { useMemo, useState } from 'react';

import { RequiredFieldComponent } from 'components/RequiredFiledComponent';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { getMerchantCreateInfoItemStatus } from '../selectors';
import { MerchantInfoItem } from 'entities/system';
import { MerchantCreateSteps } from './MerchantCreateSteps';
import { setMerchantInfoItemCreate } from '../merchantsSlice';
import { Input } from 'components/inputs';
import { corporateList } from 'api/corporates';
import { countryCodeList, currencyCodeList } from 'api/merchants';
import {
  countryStateList,
  gatewaysList,
  isStateDisabled,
  stateList,
} from '../utils';
import { emailPattern } from 'validation/patterns';
import { programList } from 'api/programs';
import { SelectNoController } from 'components/inputs/SelectNoController';

export const MerchantInfoCreateComponent = () => {
  const dispatch = useAppDispatch();

  const merchantInfoItem = useAppSelector(getMerchantCreateInfoItemStatus);
  const [merchantInfo, setMerchantItemInfo] =
    useState<MerchantInfoItem>(merchantInfoItem);

  const merchantInfoValue = (value: any, name: string) => {
    setMerchantItemInfo({
      ...merchantInfo,
      [name]: value,
    });
  };

  const setGatewayValue = (value: any) => {
    setMerchantItemInfo({
      ...merchantInfo,
      gateway: value,
      corporate: null,
      program: null,
    });
  };

  const setCorporateValue = (value: any) => {
    setMerchantItemInfo({
      ...merchantInfo,
      corporate: value,
      program: null,
    });
  };

  const corporatesList = useMemo(() => {
    return merchantInfo.gateway
      ? corporateList({ gateway: merchantInfo.gateway.value })
      : null;
  }, [merchantInfo.gateway]);

  const corporateProgramList = useMemo(() => {
    return merchantInfo.gateway && merchantInfo.corporate
      ? programList({
          corporateId: merchantInfo.corporate?.value,
          gateway: merchantInfo.gateway.value,
        })
      : null;
  }, [merchantInfo.gateway, merchantInfo.corporate]);

  const setMerchantInfoItem = () => {
    dispatch(setMerchantInfoItemCreate(merchantInfo));
  };

  const isValidEmail =
    merchantInfo.contactEmail !== ''
      ? emailPattern.test(merchantInfo.contactEmail)
      : false;

  const hasStateUS =
    merchantInfo.countryCode &&
    merchantInfo.countryCode.value === 'US' &&
    merchantInfo.stateUS === null;

  const hasState =
    merchantInfo.countryCode &&
    countryStateList.includes(merchantInfo.countryCode?.value) &&
    merchantInfo.state.trim().length === 0;

  const isDisabled =
    !merchantInfo.merchantName.trim().length ||
    !merchantInfo.countryCode ||
    !merchantInfo.corporate ||
    !merchantInfo.address1 ||
    !merchantInfo.city ||
    !merchantInfo.postal ||
    !merchantInfo.contactFirstName ||
    !merchantInfo.contactLastName ||
    !merchantInfo.contactPhone ||
    !merchantInfo.gateway ||
    !isValidEmail ||
    hasStateUS ||
    hasState ||
    !merchantInfo.currency ||
    !merchantInfo.program;

  return (
    <>
      <div className="grid gap-3 sm:grid-cols-2 l2g:grid-cols-3 3xl:grid-cols-4 items-center justify-items-start my-4 sm:m-4 sm:p-3">
        <div className="w-full">
          <Input
            label={<RequiredFieldComponent labelText="Merchant Name" />}
            placeholder="Merchant Name"
            defaultValue={merchantInfoItem.merchantName}
            onChange={(val) =>
              merchantInfoValue(val.currentTarget.value.trim(), 'merchantName')
            }
            labelFontClassName="text-sm font-normal"
          />
        </div>
        <div className="w-full">
          <Input
            label={
              <RequiredFieldComponent isRequired={false} labelText="URL" />
            }
            placeholder="URL"
            defaultValue={merchantInfoItem.url ? merchantInfoItem.url : ''}
            onChange={(val) =>
              merchantInfoValue(val.currentTarget.value.trim(), 'url')
            }
            labelFontClassName="text-sm font-normal"
          />
        </div>
        <div className="w-full">
          <SelectNoController
            label={
              <RequiredFieldComponent
                labelText="Currency"
                className="font-medium tracking-wide text-xs text-black"
              />
            }
            placeholder="Currency"
            name="currency"
            height="2.2rem"
            onChange={(val) => merchantInfoValue(val, 'currency')}
            loadOptions={currencyCodeList}
            closeMenuOnSelect={true}
            value={merchantInfo.currency?.value ? merchantInfo.currency : null}
            required
          />
        </div>
        <div className="w-full">
          <SelectNoController
            label={
              <RequiredFieldComponent
                labelText="Country"
                className="font-medium tracking-wide text-xs text-black"
              />
            }
            height="2.2rem"
            placeholder="Select Country"
            name="countryCode"
            onChange={(val) => merchantInfoValue(val, 'countryCode')}
            loadOptions={countryCodeList}
            closeMenuOnSelect={true}
            value={
              merchantInfo.countryCode?.value ? merchantInfo.countryCode : null
            }
            required
          />
        </div>
        <div className="w-full">
          {merchantInfo.countryCode &&
          merchantInfo.countryCode.value === 'US' ? (
            <SelectNoController
              label={
                <RequiredFieldComponent
                  labelText="State"
                  className="font-medium tracking-wide text-xs text-black"
                />
              }
              height="2.2rem"
              placeholder="State"
              name="state"
              isClearable={false}
              closeMenuOnSelect={true}
              onChange={(val) => merchantInfoValue(val, 'stateUS')}
              options={stateList}
              value={merchantInfo.stateUS?.value ? merchantInfo.stateUS : null}
            />
          ) : (
            <Input
              label={
                <RequiredFieldComponent
                  isRequired={!isStateDisabled(merchantInfo.countryCode?.value)}
                  labelText="State"
                />
              }
              disabled={isStateDisabled(merchantInfo.countryCode?.value)}
              placeholder="State"
              defaultValue={merchantInfo.state}
              onChange={(val) =>
                merchantInfoValue(val.currentTarget.value.trim(), 'state')
              }
              labelFontClassName="text-sm font-normal"
            />
          )}
        </div>
        <div className="w-full">
          <Input
            label={
              <RequiredFieldComponent isRequired={true} labelText="Address 1" />
            }
            placeholder="Address 1"
            defaultValue={merchantInfoItem.address1}
            onChange={(val) =>
              merchantInfoValue(val.currentTarget.value.trim(), 'address1')
            }
            labelFontClassName="text-sm font-normal"
          />
        </div>
        <div className="w-full">
          <Input
            label={
              <RequiredFieldComponent
                isRequired={false}
                labelText="Address 2"
              />
            }
            placeholder="Address 2"
            defaultValue={merchantInfoItem.address2}
            onChange={(val) =>
              merchantInfoValue(val.currentTarget.value.trim(), 'address 2')
            }
            labelFontClassName="text-sm font-normal"
          />
        </div>
        <div className="w-full">
          <Input
            label={
              <RequiredFieldComponent isRequired={true} labelText="City" />
            }
            placeholder="City"
            defaultValue={merchantInfoItem.city}
            onChange={(val) =>
              merchantInfoValue(val.currentTarget.value.trim(), 'city')
            }
            labelFontClassName="text-sm font-normal"
          />
        </div>
        <div className="w-full">
          <Input
            label={
              <RequiredFieldComponent isRequired={true} labelText="Postal" />
            }
            placeholder="Postal"
            defaultValue={merchantInfoItem.postal}
            onChange={(val) =>
              merchantInfoValue(val.currentTarget.value.trim(), 'postal')
            }
            labelFontClassName="text-sm font-normal"
          />
        </div>
        <div className="w-full">
          <Input
            label={
              <RequiredFieldComponent
                isRequired={true}
                labelText="Contact First Name"
              />
            }
            placeholder="Contact First Name"
            defaultValue={merchantInfoItem.contactFirstName}
            onChange={(val) =>
              merchantInfoValue(
                val.currentTarget.value.trim(),
                'contactFirstName'
              )
            }
            labelFontClassName="text-sm font-normal"
          />
        </div>
        <div className="w-full">
          <Input
            label={
              <RequiredFieldComponent
                isRequired={true}
                labelText="Contact Last Name"
              />
            }
            placeholder="Contact Last Name"
            defaultValue={merchantInfoItem.contactLastName}
            onChange={(val) =>
              merchantInfoValue(
                val.currentTarget.value.trim(),
                'contactLastName'
              )
            }
            labelFontClassName="text-sm font-normal"
          />
        </div>
        <div className="w-full">
          <Input
            label={
              <RequiredFieldComponent
                isRequired={true}
                labelText="Contact Phone"
              />
            }
            placeholder="Contact Phone"
            defaultValue={merchantInfoItem.contactPhone}
            onChange={(val) =>
              merchantInfoValue(val.currentTarget.value.trim(), 'contactPhone')
            }
            labelFontClassName="text-sm font-normal"
          />
        </div>
        <div className="w-full">
          <Input
            label={
              <RequiredFieldComponent
                isRequired={true}
                labelText="Contact Email"
              />
            }
            placeholder="Contact Email"
            defaultValue={merchantInfoItem.contactEmail}
            onChange={(val) =>
              merchantInfoValue(val.currentTarget.value.trim(), 'contactEmail')
            }
            labelFontClassName="text-sm font-normal"
            hasError={
              merchantInfoItem.contactEmail !== '' &&
              !emailPattern.test(merchantInfoItem.contactEmail)
            }
          />
        </div>
        <div className="w-full">
          <SelectNoController
            placeholder="Gateway"
            name="gateway"
            label={
              <RequiredFieldComponent
                labelText="Gateway"
                className="font-medium tracking-wide text-xs text-black"
              />
            }
            height="2.2rem"
            onChange={(val) => {
              setGatewayValue(val);
            }}
            closeMenuOnSelect={true}
            options={gatewaysList}
            value={merchantInfo.gateway?.value ? merchantInfo.gateway : null}
            required
          />
        </div>
        <div className="w-full">
          <SelectNoController
            label={
              <RequiredFieldComponent
                labelText="Corporate"
                className="font-medium tracking-wide text-xs text-black"
              />
            }
            placeholder="Corporate"
            name="corporate"
            height="2.2rem"
            isDisabled={!merchantInfo.gateway}
            onChange={setCorporateValue}
            closeMenuOnSelect
            loadOptionsParam={corporatesList}
            value={
              merchantInfo.corporate?.value ? merchantInfo.corporate : null
            }
            required
          />
        </div>
        <div className="w-full">
          <SelectNoController
            label={
              <RequiredFieldComponent
                labelText="Program"
                className="font-medium tracking-wide text-xs text-black"
              />
            }
            height="2.2rem"
            placeholder="Program"
            name="program"
            isDisabled={!merchantInfo.gateway || !merchantInfo.corporate}
            onChange={(val) => merchantInfoValue(val, 'program')}
            loadOptionsParam={corporateProgramList}
            closeMenuOnSelect={true}
            value={merchantInfo.program?.value ? merchantInfo.program : null}
            required
          />
        </div>
      </div>
      <MerchantCreateSteps
        isNextStepDisabled={isDisabled}
        onClick={setMerchantInfoItem}
      />
    </>
  );
};
