import { createAsyncThunk } from '@reduxjs/toolkit';

import { logsResource } from './api';

import {
  getAppliedFilters,
  getPage,
  getRowsPerPage,
  getSort,
} from './selectors';
import { LoadItems } from 'entities';
import { listRequestPayload } from './utils';
import { dateTimeZone } from '../../account/selectors';
import { RootStateType } from 'store/store';

export const loadItems: any = createAsyncThunk(
  'drApi/loadItems',
  async (options: LoadItems, thunkApi) => {
    try {
      const { getState } = thunkApi;
      const page = getPage(getState() as RootStateType);
      const sort = getSort(getState() as RootStateType);
      const rowsPerPage = getRowsPerPage(getState() as RootStateType);
      const appliedFilters = getAppliedFilters(getState() as RootStateType);

      const dateZone = dateTimeZone(getState() as RootStateType);

      const params = {
        page: page,
        limit: rowsPerPage,
        offset: (page - 1) * rowsPerPage,
        sort: sort,
        ...(options || {}),
        ...listRequestPayload(appliedFilters, dateZone),
      };

      return await logsResource.list(params);
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

export const fetchDetails: any = createAsyncThunk(
  'drApi/fetchDetails',
  async (data: { id: string }) => {
    try {
      const response = await logsResource.fetchDetails(data);
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
);
