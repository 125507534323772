import { createAsyncThunk } from '@reduxjs/toolkit';

import { listRequestPayload } from './utils';
import { unmatchedTransactionsResource } from './api';

import {
  getAppliedFilters,
  getPage,
  getRowsPerPage,
  getSort,
} from './selectors';
import { LoadItems } from 'entities';
import { dateTimeZone } from 'domain/account/selectors';
import { dateTimeFormatUtil } from 'components/dateTimeFormatUtil';
import { RootStateType } from 'store/store';

export const loadItems: any = createAsyncThunk(
  'tmUnmatchedTransaction/loadItems',
  async (options: LoadItems, thunkApi) => {
    try {
      const { getState } = thunkApi;

      const appliedFilters = getAppliedFilters(getState() as RootStateType);
      const page = getPage(getState() as RootStateType);
      const sort = getSort(getState() as RootStateType);
      const rowsPerPage = getRowsPerPage(getState() as RootStateType);

      const dateZone = dateTimeZone(getState() as RootStateType);
      const dateFormatVal = (value: any) =>
        dateTimeFormatUtil(value, 'YYYY-MM-DD', dateZone);

      const params = {
        page,
        limit: rowsPerPage,
        offset: (page - 1) * rowsPerPage,
        sort: sort,
        ...listRequestPayload(appliedFilters, dateFormatVal),
      };

      return await unmatchedTransactionsResource.list(params);
    } catch (e) {
      return Promise.reject(e);
    }
  }
);
