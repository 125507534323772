import { useTranslation } from 'react-i18next';

import { ColumnProps, PaginationProps, TableCard } from 'components/table';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { ChargebackItem } from 'entities/dispute-resolution';
import { LayoutList } from 'layouts/list/LayoutList';
import { ItemSort } from 'entities';

import {
  getItems,
  getItemsLoadingComputed,
  getPagination,
  getTotalNumber,
} from '../selectors';
import { useCharbacksColumns } from './useCharbacksColumns';
import { setPage, setRowsPerPage, setSort } from '../chargebacksSlice';
import { User } from 'domain/user-management/users/list/context';
import { addToUserId } from 'domain/user-management/users/userSlice';
import { Base64 } from 'js-base64';
import { useHistory } from 'react-router-dom';
import { getProfilePermission } from 'domain/account/selectors';
import { UserPermissions } from 'enums/Permissions';

export const ChargebacksList = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const items: ChargebackItem[] = useAppSelector(getItems);
  const pagination: PaginationProps = useAppSelector(getPagination);
  const totalNumber: number = useAppSelector(getTotalNumber);
  const itemsLoadingComputed: boolean = useAppSelector(getItemsLoadingComputed);
  const permissions: any = useAppSelector(getProfilePermission);

  const columns: ColumnProps[] = useCharbacksColumns();

  const handleRowClick = (user: User) => {
    dispatch(addToUserId(user.id));
    history.push(`chargebacks/details/${Base64.encodeURI(user.id)}`);
  };

  const setCurrentSort = (sort: ItemSort) => {
    dispatch(setSort(sort));
  };

  const isOnRowClick = permissions.includes(UserPermissions.CHARGEBACKS_GET);

  return (
    <LayoutList title={t('disputeResolution.chargebacks.breadcrumb')}>
      <TableCard
        loading={itemsLoadingComputed}
        columns={columns}
        items={items}
        totalNumber={totalNumber}
        setSort={setCurrentSort}
        onRowClick={isOnRowClick ? handleRowClick : undefined}
        onHover={isOnRowClick}
        pagination={pagination}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
      />
    </LayoutList>
  );
};
