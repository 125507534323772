import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Button from '@mui/material/Button';
import { Base64 } from 'js-base64';
import { useRef } from 'react';

import { ColumnProps, PaginationProps, TableCard } from 'components/table';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { LayoutList } from 'layouts/list/LayoutList';
import { ItemSort } from 'entities';

import {
  getFilters,
  getItems,
  getItemsLoadingComputed,
  getPagination,
  getTotalNumber,
} from '../selectors';
import {
  addToUserId,
  setApplyFilters,
  setPage,
  setRowsPerPage,
  setSort,
} from '../userSlice';
import { Filters } from './filters/Filters';
import { useColumns } from './userColumns';
import { User } from './context';
import { getProfilePermission } from 'domain/account/selectors';
import { UserPermissions } from 'enums/Permissions';

export const UsersList = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const items: User[] = useAppSelector(getItems);
  const totalNumber: number = useAppSelector(getTotalNumber);
  const itemsLoadingComputed: boolean = useAppSelector(getItemsLoadingComputed);
  const pagination: PaginationProps = useAppSelector(getPagination);
  const permissions: any = useAppSelector(getProfilePermission);
  const filters: any = useAppSelector(getFilters);
  const isOnRowClick = permissions.includes(UserPermissions.ADMINS_GET);
  const hasCreateUserPermission = permissions.includes(
    UserPermissions.ADMINS_ADD
  );

  const tableRef = useRef<HTMLDivElement>(null);

  const columns: ColumnProps[] = useColumns();

  const handleRowClick = (user: User) => {
    dispatch(addToUserId(user.id));
    history.push(
      `/user-management/user-list/user-details/${Base64.encodeURI(user.id)}`
    );
  };

  const setCurrentSort = (sort: ItemSort) => {
    dispatch(setSort(sort));
  };

  const isFilterSelected =
    filters.rolesId.length ||
    filters.status.length ||
    filters.userEmail ||
    filters.userName;

  return (
    <LayoutList title={t('userManagement.list.userList')}>
      <TableCard
        tableRef={tableRef}
        loading={itemsLoadingComputed}
        columns={columns}
        items={items}
        totalNumber={totalNumber}
        filterApply={
          isFilterSelected ? () => dispatch(setApplyFilters({})) : undefined
        }
        create={
          hasCreateUserPermission ? (
            <div className="px-3 py-2 flex-1">
              <Button
                onClick={() =>
                  history.push('/user-management/user-list/create')
                }
                className="ml-2"
                variant="contained"
                startIcon={<PersonAddAltOutlinedIcon />}
              >
                <span className="text-xxs sm:text-sm">
                  {t('userManagement.createUser')}
                </span>
              </Button>
            </div>
          ) : null
        }
        setSort={setCurrentSort}
        onRowClick={isOnRowClick ? handleRowClick : undefined}
        onHover={isOnRowClick}
        pagination={pagination}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
        filters={<Filters />}
      />
    </LayoutList>
  );
};
