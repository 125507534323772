import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { breadcrumbsSlice } from 'domain/breadcrumbs/breadcrumbsSlice';
import { useAppDispatch } from 'hooks/redux';

import { UnmatchedTransactionsList } from './UnmatchedTransactionsList';
import {
  getAppliedFilters,
  getPage,
  getRowsPerPage,
  getSort,
} from '../selectors';
import { loadItems } from '../thunks';
import { useSelector } from 'react-redux';

export const Container = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const page = useSelector(getPage);
  const sort = useSelector(getSort);
  const appliedFilters = useSelector(getAppliedFilters);
  const rowsPerPage = useSelector(getRowsPerPage);

  const { pathname } = useLocation();

  useEffect(() => {
    dispatch(
      breadcrumbsSlice.actions.setBreadcrumbs([
        {
          label: t('transactionsManagement.breadcrumb'),
          route: pathname,
        },
        {
          label: t('transactionsManagement.unmatchedTransactions.breadcrumb'),
          route: pathname,
        },
      ])
    );
  }, [dispatch, pathname, t]);

  useEffect(() => {
    dispatch(loadItems());
  }, [dispatch, page, appliedFilters, rowsPerPage, sort]);

  return <UnmatchedTransactionsList />;
};
