import { createSelector } from '@reduxjs/toolkit';

import { storeName } from './merchantsSlice';
import { RootStateType } from 'store/store';

export const getRoot = (state: RootStateType) => state[storeName];

export const getPage = createSelector([getRoot], ({ page }) => page);
export const getSort = createSelector([getRoot], ({ sort }) => sort);
export const getRowsPerPage = createSelector(
  [getRoot],
  ({ rowsPerPage }) => rowsPerPage
);

export const getDetails = createSelector([getRoot], ({ details }) => details);
export const getIsDetailsLoading = createSelector(
  [getRoot],
  ({ isDetailsLoading }) => isDetailsLoading
);

export const getItems = createSelector([getRoot], ({ items }) => items);
export const getItemNotFound = createSelector(
  [getRoot],
  ({ itemNotFound }) => itemNotFound
);

export const getIsCountryCodesLoading = createSelector(
  [getRoot],
  ({ isCountryCodesLoading }) => isCountryCodesLoading
);
export const getOpenStatusModal = createSelector(
  [getRoot],
  ({ openStatusModal }) => openStatusModal
);
export const getMerchantId = createSelector(
  [getRoot],
  ({ merchantId }) => merchantId
);
export const getFutureMerchantStatus = createSelector(
  [getRoot],
  ({ futureMerchantStatus }) => futureMerchantStatus
);
export const getCountryCodes = createSelector(
  [getRoot],
  ({ countryCodes }) => countryCodes
);
export const getIsMerchantCreated = createSelector(
  [getRoot],
  ({ isMerchantCreated }) => isMerchantCreated
);

export const getPagination = createSelector(
  [getRoot],
  ({
    items,
    page,
    endReached,
    rowsPerPage,
    paginationLoading,
    itemsLoading,
  }) => ({
    total: items.length,
    page,
    endReached,
    rowsPerPage,
    paginationLoading,
    itemsLoading,
  })
);

export const getMerchantCreateInfoItemStatus = createSelector(
  [getRoot],
  ({ merchantItemCreate }) => merchantItemCreate.merchantInfoItem
);

export const getMerchantRapidConnectItemStatus = createSelector(
  [getRoot],
  ({ merchantItemCreate }) => merchantItemCreate.merchantRapidConnectItem
);

export const getMerchantNMIItemStatus = createSelector(
  [getRoot],
  ({ merchantItemCreate }) => merchantItemCreate.merchantNMIItem
);

export const getMerchantAccountHolderItemStatus = createSelector(
  [getRoot],
  ({ merchantItemCreate }) => merchantItemCreate.merchantAccountHolderItem
);

export const getMerchantCreateStepStatus = createSelector(
  [getRoot],
  ({ merchantCreateStepNumber }) => merchantCreateStepNumber
);

export const getItemsLoadingComputed = createSelector(
  [getRoot],
  ({ itemsLoading }) => itemsLoading
);

export const getTotalNumber = createSelector(
  [getRoot],
  ({ totalNumber }) => totalNumber
);

export const getIsMerchantCreationLoading = createSelector(
  [getRoot],
  ({ isMerchantCreationLoading }) => isMerchantCreationLoading
);

export const getFilters = createSelector([getRoot], ({ filters }) => filters);
export const getAppliedFilters = createSelector(
  [getRoot],
  ({ appliedFilters }) => appliedFilters
);
