import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import Button from '@mui/material/Button';

import { ColumnProps, PaginationProps, TableCard } from 'components/table';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { Role, RoleDetails } from 'entities/user-management';
import { LayoutList } from 'layouts/list/LayoutList';
import { ItemSort } from 'entities';

import {
  getItems,
  getItemsLoadingComputed,
  getPagination,
  getTotalNumber,
} from '../selectors';
import { setPage, setRowsPerPage, setSort } from '../rolesSlice';
import { useColumns } from './RoleColumns';
import { getProfilePermission } from '../../../account/selectors';
import { UserPermissions } from '../../../../enums/Permissions';

export const RoleList = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const items: Role[] = useAppSelector(getItems);
  const totalNumber: number = useAppSelector(getTotalNumber);
  const pagination: PaginationProps = useAppSelector(getPagination);
  const itemsLoadingComputed: boolean = useAppSelector(getItemsLoadingComputed);

  const tableRef = useRef<HTMLDivElement>(null);
  const columns: ColumnProps[] = useColumns();
  const permissions: any = useAppSelector(getProfilePermission);
  const isOnRowClick = permissions.includes(UserPermissions.ROLE_UPDATE);
  const hasAddRole = permissions.includes(UserPermissions.ROLE_ADD);

  useEffect(() => {
    tableRef?.current?.scrollTo(tableRef?.current?.scrollLeft, 0);
  }, [itemsLoadingComputed]);

  const handleRowClick = (item: RoleDetails) => {
    history.push(`/user-management/role-list/update/${item.id}`);
  };

  const setCurrentSort = (sort: ItemSort) => {
    dispatch(setSort(sort));
  };

  return (
    <LayoutList
      title={t('userManagement.roleList.list')}
      titleRight={
        <Button
          onClick={() => history.push('/user-management/role-list/create')}
          className="ml-2"
          variant="contained"
          startIcon={<AddCircleOutlineOutlinedIcon />}
        >
          <span className="text-xxs sm:text-sm">
            {t('userManagement.roleList.create')}
          </span>
        </Button>
      }
    >
      <TableCard
        tableRef={tableRef}
        loading={itemsLoadingComputed}
        columns={columns}
        totalNumber={totalNumber}
        items={items}
        setSort={setCurrentSort}
        onRowClick={isOnRowClick ? handleRowClick : undefined}
        onHover={isOnRowClick}
        pagination={pagination}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
        create={
          hasAddRole ? (
            <div className="px-3 py-2 flex-1">
              <Button
                onClick={() =>
                  history.push('/user-management/role-list/create')
                }
                className="ml-2"
                variant="contained"
                startIcon={<AddCircleOutlineOutlinedIcon />}
              >
                <span className="text-xxs sm:text-sm">
                  {t('userManagement.roleList.create')}
                </span>
              </Button>
            </div>
          ) : null
        }
      />
    </LayoutList>
  );
};
