import { createSelector } from '@reduxjs/toolkit';

import { storeName } from './authorizationsSlice';
import { RootStateType } from 'store/store';

export const getRoot = (state: RootStateType) => state[storeName];

export const getItems = createSelector([getRoot], ({ items }) => items);
export const getPage = createSelector([getRoot], ({ page }) => page);
export const getRowsPerPage = createSelector(
  [getRoot],
  ({ rowsPerPage }) => rowsPerPage
);
export const getSort = createSelector([getRoot], ({ sort }) => sort);
export const getFilters = createSelector([getRoot], ({ filters }) => filters);
export const getAppliedFilters = createSelector(
  [getRoot],
  ({ appliedFilters }) => appliedFilters
);

export const getTotalNumber = createSelector(
  [getRoot],
  ({ totalNumber }) => totalNumber
);

export const getPagination = createSelector(
  [getRoot],
  ({
    items,
    page,
    endReached,
    rowsPerPage,
    paginationLoading,
    itemsLoading,
  }) => ({
    total: items.length,
    page,
    endReached,
    rowsPerPage,
    paginationLoading,
    itemsLoading,
  })
);

export const getItemsLoadingComputed = createSelector(
  [getRoot],
  ({ itemsLoading }) => itemsLoading
);

export const getTransactionDetails = createSelector(
  [getRoot],
  ({ transactionDetails, isTransactionDetailsLoading }) => ({
    transactionDetails,
    isTransactionDetailsLoading,
  })
);

export const getItemsNotFound = createSelector(
  [getRoot],
  ({ itemNotFound }) => itemNotFound
);

export const getTransactionDetailsLoading = createSelector(
  [getRoot],
  ({ isTransactionDetailsLoading }) => isTransactionDetailsLoading
);

export const getTransactionItemDetails = createSelector(
  [getRoot],
  ({ transactionDetails }) => transactionDetails
);

export const getIsOperationLoading = createSelector(
  [getRoot],
  ({ isOperationLoading }) => isOperationLoading
);
