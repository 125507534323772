import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { getDetails, isUserDetailLoading, userNotFound } from '../selectors';
import { useEffect } from 'react';
import {
  fetchDetails,
  passwordResetRequest,
  resendInvitation,
  updateUser,
} from '../thunks';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import LoginIcon from '@mui/icons-material/Login';
import LensBlurIcon from '@mui/icons-material/LensBlur';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';
import LockClockIcon from '@mui/icons-material/LockClock';
import SendIcon from '@mui/icons-material/Send';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import EditIcon from '@mui/icons-material/Edit';
import PinIcon from '@mui/icons-material/Pin';
import LogoutIcon from '@mui/icons-material/Logout';
import StorefrontIcon from '@mui/icons-material/Storefront';
import { UserStatusBadge } from '../list/UserStatusBadge';
import classNames from 'classnames';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import AccordionDetails from '@mui/material/AccordionDetails';
import { getUserDetailsIconColor } from './utils';
import {
  ACTIVE_STATUS,
  ADMIN_ROLE,
  LOCKED_STATUS,
  LoggingParams,
  MERCHANT_ROLE,
  PENDING_STATUS,
  UNLOCK_USER_REASON,
} from '../utils';
import { RectLoader } from 'components/RectLoader';
import { Base64 } from 'js-base64';
import { PageHeader } from 'components/PageHeader';
import {
  dateTimeFormatUtil,
  timeFormatUtil,
} from 'components/dateTimeFormatUtil';
import { useSelector } from 'react-redux';
import {
  dateTimeFormat,
  dateTimeZone,
  getProfilePermission,
} from 'domain/account/selectors';
import capitalize from 'lodash/capitalize';
import { UserPermissions } from '../../../../enums/Permissions';

const UserValue = ({
  fieldName,
  userValue,
}: {
  fieldName: string;
  userValue?: any;
}) => {
  return (
    <>
      {userValue && (
        <div className="flex flex-col mt-2">
          <div className="text-xs font-normal text-gray-700">{fieldName}</div>
          <div className="text-base font-normal">{userValue}</div>
        </div>
      )}
    </>
  );
};

export const UserGetDetails = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const userDetailsPreview = useAppSelector(getDetails);
  const isDetailLoading = useAppSelector(isUserDetailLoading);
  const notFound = useAppSelector(userNotFound);
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const userId = Base64.decode(id);
  const permissions: any = useAppSelector(getProfilePermission);
  const hasUpdatePermission = permissions.includes(
    UserPermissions.ADMINS_UPDATE
  );
  const hasInvitationResendPermission = permissions.includes(
    UserPermissions.ADMINS_INVITATION_RESEND
  );
  const hasPasswordResetRequestPermission = permissions.includes(
    UserPermissions.ACCOUNT_PASSWORD_RESET_REQUEST
  );

  const dateFormat = useSelector(dateTimeFormat);
  const dateZone = useSelector(dateTimeZone);
  const timeFormat = (value: string) =>
    dateTimeFormatUtil(value, dateFormat + timeFormatUtil, dateZone);

  useEffect(() => {
    dispatch(fetchDetails({ adminId: userId }));
  }, [dispatch, id, userId]);

  const resendUserInvitation = () => {
    dispatch(
      resendInvitation({
        adminId: userId,
      })
    );
  };

  const unlockUser = () => {
    dispatch(
      updateUser({
        id: userDetailsPreview.id,
        firstName: userDetailsPreview.firstName,
        lastName: userDetailsPreview.lastName,
        email: userDetailsPreview.email,
        roleId: userDetailsPreview.role.id,
        reason: UNLOCK_USER_REASON,
        status: ACTIVE_STATUS,
        merchants: userDetailsPreview.merchants?.map((item) => item.id),
      })
    );
  };

  const userPasswordResetRequest = () => {
    dispatch(
      passwordResetRequest({
        id: userId,
      })
    );
  };

  return (
    <div className="relative flex flex-col h-full bg-gray-200">
      <div className="flex justify-around items-center">
        <PageHeader title={t('userManagement.detailsUser')} />
      </div>
      <div className="h-full p-3 pt-5 overflow-auto custom-scrollbar">
        <div className="grid grid-cols-8 xxl:grid-cols-12 gap-4">
          {/*back bar*/}
          <div className="flex flex-wrap flex-row justify-between items-center col-span-8 xxl:col-span-12 6xl:col-start-2 6xl:col-span-10 h-auto bg-white border sm:mx-4 p-2 rounded-md box-shadow-dark">
            <div>
              <Button
                onClick={() => history.goBack()}
                startIcon={<KeyboardBackspaceIcon />}
              >
                Back
              </Button>
            </div>
            {!isDetailLoading && !notFound && (
              <div className="flex gap-4 items-end justify-end justify-items-center">
                {userDetailsPreview.status === PENDING_STATUS &&
                  hasInvitationResendPermission && (
                    <div>
                      <Button
                        onClick={() => resendUserInvitation()}
                        variant="outlined"
                        color="primary"
                        startIcon={<SendIcon />}
                      >
                        <span className="text-xs sm:text-sm">
                          {t('userManagement.userDetails.resendEmail')}
                        </span>
                      </Button>
                    </div>
                  )}
                {userDetailsPreview.status === LOCKED_STATUS &&
                  hasUpdatePermission && (
                    <div>
                      <Button
                        onClick={() => unlockUser()}
                        variant="outlined"
                        color="primary"
                        startIcon={<LockOpenIcon />}
                      >
                        <span className="text-xs sm:text-sm">
                          {t('userManagement.userDetails.unlockUser')}
                        </span>
                      </Button>
                    </div>
                  )}
                {userDetailsPreview.status === ACTIVE_STATUS &&
                  hasPasswordResetRequestPermission && (
                    <div>
                      <Button
                        onClick={() => userPasswordResetRequest()}
                        variant="outlined"
                        color="primary"
                        startIcon={<LockClockIcon />}
                      >
                        <span className="text-xs sm:text-sm">
                          {t('userManagement.userDetails.passwordResetRequest')}
                        </span>
                      </Button>
                    </div>
                  )}
                {hasUpdatePermission ? (
                  <div>
                    <Button
                      onClick={() =>
                        history.push('/user-management/user-list/update')
                      }
                      variant="contained"
                      color="warning"
                      startIcon={<EditIcon />}
                    >
                      <span className="text-xs sm:text-sm">EDIT</span>
                    </Button>
                  </div>
                ) : null}
              </div>
            )}
          </div>
          {notFound && (
            <div className="flex col-span-8 l2g:col-span-8 xxl:col-span-12 6xl:col-start-2 6xl:col-span-10 h-auto bg-white border my-4 sm:mx-4 p-4 rounded-md box-shadow-dark">
              <div className="w-full">
                <div className="-mt-8">
                  <div className="flex flex-row items-center justify-start justify-items-start p-4 w-full sm:w-40 h-16 rounded-md sm:ml-3 box-shadow-nero box-bg-nero-300">
                    <div>
                      <PersonOffIcon fontSize="large" sx={{ color: '#fff' }} />
                    </div>
                    <div className="text-xs text-white">No Details</div>
                  </div>
                </div>
                <div className="flex flex-col items-center justify-center justify-items-start my-4 sm:mx-4 sm:p-3">
                  <div>No such user</div>
                </div>
              </div>
            </div>
          )}
          {isDetailLoading && (
            <div className="flex col-span-8 xxl:col-span-12 6xl:col-start-2 6xl:col-span-10 h-auto bg-white border my-4 sm:mx-4 p-4 rounded-md box-shadow-dark">
              <div className="grid items-center justify-center justify-items-center m-4 p-3 w-full">
                <div className="w-full">
                  <RectLoader width={150} height={18} />
                </div>
              </div>
            </div>
          )}

          {!isDetailLoading && !notFound && (
            <>
              <div className="flex col-span-8 xxl:col-span-12 6xl:col-start-2 6xl:col-span-10 h-auto bg-white border my-4 sm:mx-4 p-4 rounded-md box-shadow-dark">
                <div className="w-full">
                  <div className="-mt-8">
                    <div
                      className={classNames(
                        'flex flex-row items-center justify-start justify-items-start p-4 w-full sm:w-40 h-16 rounded-md sm:ml-3',
                        getUserDetailsIconColor(userDetailsPreview.status)
                      )}
                    >
                      <div>
                        <AccountBoxOutlinedIcon
                          fontSize="large"
                          sx={{ color: '#fff' }}
                        />
                      </div>
                      <div className="text-xs text-white ml-2">Details</div>
                    </div>
                  </div>
                  <div className="grid sm:grid-cols-2 l2g:grid-cols-3 3xl:grid-cols-4 4xl:grid-cols-5 items-center justify-items-start my-4 sm:mx-4 sm:p-3">
                    <UserValue
                      fieldName="First Name"
                      userValue={userDetailsPreview.firstName}
                    />
                    <UserValue
                      fieldName="Last Name"
                      userValue={userDetailsPreview.lastName}
                    />
                    <UserValue
                      fieldName="Email"
                      userValue={userDetailsPreview.email}
                    />
                    <UserValue
                      fieldName="Created At"
                      userValue={timeFormat(userDetailsPreview.createdTime)}
                    />
                    <UserValue
                      fieldName="Updated At"
                      userValue={timeFormat(userDetailsPreview.updatedTime)}
                    />
                    <UserValue
                      fieldName="Role"
                      userValue={userDetailsPreview.role.name}
                    />
                    <UserValue
                      fieldName="User Status"
                      userValue={
                        <UserStatusBadge status={userDetailsPreview.status} />
                      }
                    />
                    <UserValue
                      fieldName="Last Activity Time"
                      userValue={timeFormat(
                        userDetailsPreview.lastActivityTime
                      )}
                    />
                    <UserValue
                      fieldName="Disabled At"
                      userValue={timeFormat(userDetailsPreview.disabledTime)}
                    />
                    <UserValue
                      fieldName="Last Change Reason"
                      userValue={userDetailsPreview.reason}
                    />
                  </div>
                </div>
              </div>
              {userDetailsPreview.role.name === ADMIN_ROLE ? (
                <div className="flex col-span-8 xxl:col-span-12 6xl:col-start-2 6xl:col-span-10 h-auto bg-white border my-4 sm:mx-4 p-4 rounded-md box-shadow-dark">
                  <div className="w-full">
                    <div className="-mt-8">
                      <div className="flex flex-row items-center justify-start justify-items-start p-4 w-full sm:w-40 h-16 rounded-md sm:ml-3 box-bg-pine-glade-300 box-shadow-green-smoke">
                        <div>
                          <ClearAllIcon
                            fontSize="large"
                            sx={{ color: '#fff' }}
                          />
                        </div>
                        <div className="text-xs text-white ml-2">
                          Notifications Subscription
                        </div>
                      </div>
                    </div>
                    {userDetailsPreview.notificationType &&
                    userDetailsPreview.notificationType?.length !== 0 ? (
                      <div className="flex flex-row gap-4 items-center justify-start justify-items-start my-4 sm:mx-4 sm:p-3">
                        {userDetailsPreview.notificationType.map((item) => (
                          <UserValue
                            key={item}
                            fieldName="Notification Name"
                            userValue={capitalize(item.replaceAll(/(_)/g, ' '))}
                          />
                        ))}
                      </div>
                    ) : (
                      <div className="grid items-center justify-center justify-items-center my-4 sm:mx-4 sm:p-3">
                        No Notifications Subscription
                      </div>
                    )}
                  </div>
                </div>
              ) : null}
              {userDetailsPreview.role.name === MERCHANT_ROLE &&
                userDetailsPreview.merchants &&
                userDetailsPreview.merchants?.length !== 0 && (
                  <div className="flex col-span-8 xxl:col-span-12 6xl:col-start-2 6xl:col-span-10 h-auto bg-white border my-4 sm:mx-4 p-4 rounded-md box-shadow-dark">
                    <div className="w-full">
                      <div className="-mt-8">
                        <div className="flex flex-row items-center justify-start justify-items-start p-4 w-full sm:w-40 h-16 rounded-md sm:ml-3 box-bg-dodger-blue-300 box-shadow-dodger-blue">
                          <div>
                            <StorefrontIcon
                              fontSize="large"
                              sx={{ color: '#fff' }}
                            />
                          </div>
                          <div className="text-xs text-white ml-2">
                            Merchants
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-row gap-4 items-center justify-start justify-items-start my-4 sm:mx-4 sm:p-3">
                        {userDetailsPreview.merchants.map((item) => (
                          <UserValue
                            key={item.id}
                            fieldName="Merchant Name"
                            userValue={item.name}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              {/*Logging*/}
              <div className="grid col-span-8 xxl:col-span-12 6xl:col-start-2 6xl:col-span-10">
                <div className="grid grid-cols-8 justify-stretch justify-items-stretch w-full">
                  {/*login*/}
                  <div className="flex col-span-8 m2d:col-span-4 xxl:col-span-2 h-auto bg-white border my-4 sm:mx-4 p-4 rounded-md box-shadow-dark">
                    <div className="w-full">
                      <div className="-mt-8">
                        <div
                          aria-hidden="true"
                          key="sigIn"
                          onClick={() =>
                            history.push(
                              `/user-management/user-list/user-details/${id}/${LoggingParams.USER_SIG_IN}`
                            )
                          }
                          className="flex flex-row items-center justify-center justify-items-center w-90 h-16 rounded-md box-bg-valencia-300 box-shadow-valencia cursor-pointer"
                        >
                          <div>
                            <LoginIcon
                              fontSize="large"
                              sx={{ color: '#fff' }}
                            />
                          </div>
                          <div className="text-xs text-white ml-2">Login</div>
                        </div>
                      </div>
                      <div className="flex flex-col items-center justify-center justify-items-center my-4 sm:mx-4 sm:p-3">
                        <div className="grid ustify-start justify-items-start">
                          {userDetailsPreview.signInAt.map((item, i = 0) => (
                            <div key={i} className="mt-1">
                              <LensBlurIcon className="mr-2" color="primary" />
                              {timeFormat(item)}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*logout*/}
                  <div className="flex col-span-8 m2d:col-span-4 xxl:col-span-2 h-auto bg-white border my-4 sm:mx-4 p-4 rounded-md box-shadow-dark">
                    <div className="w-full">
                      <div className="-mt-8">
                        <div
                          aria-hidden="true"
                          key="sigOut"
                          onClick={() =>
                            history.push(
                              `/user-management/user-list/user-details/${id}/${LoggingParams.USER_SIG_OUT}`
                            )
                          }
                          className="flex flex-row items-center justify-center justify-items-center w-90 h-16 rounded-md box-bg-neon-blue-300 box-shadow-neon-blue cursor-pointer"
                        >
                          <div>
                            <LogoutIcon
                              fontSize="large"
                              sx={{ color: '#fff' }}
                            />
                          </div>
                          <div className="text-xs text-white ml-2">Logout</div>
                        </div>
                      </div>
                      <div className="flex flex-col items-center justify-center justify-items-center my-4 sm:mx-4 sm:p-3">
                        <div className="grid ustify-start justify-items-start">
                          {userDetailsPreview.logoutAt.map((item, i = 0) => (
                            <div key={i} className="mt-1">
                              <LensBlurIcon className="mr-2" color="primary" />
                              {timeFormat(item)}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*invalidPasswordAttempts*/}
                  <div className="flex col-span-8 m2d:col-span-4 xxl:col-span-2 h-auto bg-white border my-4 sm:mx-4 p-4 rounded-md box-shadow-dark">
                    <div className="w-full">
                      <div className="-mt-8">
                        <div
                          aria-hidden="true"
                          key="invalidPass"
                          onClick={() =>
                            history.push(
                              `/user-management/user-list/user-details/${id}/${LoggingParams.USER_INVALID_PASSWORD}`
                            )
                          }
                          className="flex flex-row items-center justify-center justify-items-center w-90 h-16 rounded-md box-bg-roti-300 box-shadow-roti cursor-pointer"
                        >
                          <div>
                            <LockIcon fontSize="large" sx={{ color: '#fff' }} />
                          </div>
                          <div className="flex flex-col items-center justify-center justify-items-center ml-2">
                            <div className="text-xs text-white">Invalid</div>
                            <div className="text-xs text-white -mt-1">
                              Password
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col items-center justify-center justify-items-center my-4 sm:mx-4 sm:p-3">
                        <div className="grid ustify-start justify-items-start">
                          {userDetailsPreview.invalidPasswordAttempts.map(
                            (item, i = 0) => (
                              <div key={i} className="mt-1">
                                <LensBlurIcon
                                  className="mr-2"
                                  color="primary"
                                />
                                {timeFormat(item)}
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*invalidOTP*/}
                  <div className="flex col-span-8 m2d:col-span-4 xxl:col-span-2 h-auto bg-white border my-4 sm:mx-4 p-4 cursor-pointer rounded-md box-shadow-dark">
                    <div className="w-full">
                      <div className="-mt-8">
                        <div
                          aria-hidden="true"
                          key="invalidOTP"
                          onClick={() =>
                            history.push(
                              `/user-management/user-list/user-details/${id}/${LoggingParams.USER_INVALID_OTP}`
                            )
                          }
                          className="flex flex-row items-center justify-center justify-items-center w-90 h-16 rounded-md box-bg-green-smoke-300 box-shadow-green-smoke"
                        >
                          <div>
                            <PinIcon fontSize="large" sx={{ color: '#fff' }} />
                          </div>
                          <div className="flex flex-col items-center justify-center justify-items-center ml-2">
                            <div className="text-xs text-white">Invalid</div>
                            <div className="text-xs text-white -mt-1">OTP</div>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col items-center justify-center justify-items-center my-4 sm:mx-4 sm:p-3">
                        <div className="grid ustify-start justify-items-start">
                          {userDetailsPreview.invalidOTPEntries.map(
                            (item, i = 0) => (
                              <div key={i} className="mt-1">
                                <LensBlurIcon
                                  className="mr-2"
                                  color="primary"
                                />
                                {timeFormat(item)}
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*History Details*/}
              <div className="flex col-span-8 l2g:col-span-8 xxl:col-span-12 6xl:col-start-2 6xl:col-span-10 h-auto bg-white border my-4 sm:mx-4 p-4 rounded-md box-shadow-dark">
                <div className="w-full">
                  <div className="-mt-8">
                    <div className="flex flex-row items-center justify-start justify-items-start sm:ml-3 p-4 w-full sm:w-40 h-16 rounded-md box-shadow-biloba-flower box-bg-biloba-flower-300">
                      <div>
                        <AccountBoxOutlinedIcon
                          fontSize="large"
                          sx={{ color: '#fff' }}
                        />
                      </div>
                      <div className="text-xs text-white ml-2">History</div>
                    </div>
                  </div>
                  <div className="flex flex-col items-center justify-center justify-items-start my-4 sm:mx-4">
                    {userDetailsPreview.history &&
                      userDetailsPreview.history
                        .slice(0, 5)
                        .map((item, i = 0) => (
                          <Accordion key={i} className="w-full">
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls={item.updatedTime}
                              id={item.updatedTime}
                            >
                              <Typography>
                                {timeFormat(item.updatedTime)}
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography component="div">
                                <div className="grid sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-5 items-center justify-items-start sm:m-4 sm:p-3">
                                  <UserValue
                                    fieldName="First Name"
                                    userValue={item.firstName}
                                  />
                                  <UserValue
                                    fieldName="Last Name"
                                    userValue={item.lastName}
                                  />
                                  <UserValue
                                    fieldName="Email"
                                    userValue={item.email}
                                  />

                                  <UserValue
                                    fieldName="Updated By"
                                    userValue={item.updatedBy}
                                  />
                                  <UserValue
                                    fieldName="Updated At"
                                    userValue={timeFormat(item.updatedTime)}
                                  />
                                  <UserValue
                                    fieldName="Role"
                                    userValue={item.role}
                                  />
                                  <UserValue
                                    fieldName="User Status"
                                    userValue={
                                      <UserStatusBadge status={item.status} />
                                    }
                                  />
                                  <UserValue
                                    fieldName="Reason"
                                    userValue={item.reason}
                                  />
                                </div>
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                        ))}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
