import { MerchantCreateSteps } from './MerchantCreateSteps';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  getMerchantAccountHolderItemStatus,
  getMerchantCreateInfoItemStatus,
  getMerchantNMIItemStatus,
  getMerchantRapidConnectItemStatus,
} from '../selectors';
import { createMerchant } from '../thunks';
import { useHistory } from 'react-router-dom';
import { Base64 } from 'js-base64';
import { countryStateList, GatewaysValues } from '../utils';
import { IconPayment } from 'components/icons/IconPayment';
import { useTranslation } from 'react-i18next';
import StorefrontIcon from '@mui/icons-material/Storefront';
import { IconBankAccount } from 'components/icons/IconBankAccount';
import { useSelector } from 'react-redux';
import { dateTimeFormat, dateTimeZone } from 'domain/account/selectors';
import { dateTimeFormatUtil } from 'components/dateTimeFormatUtil';
import { clearMerchantCreate } from '../merchantsSlice';
import { ItemViewRow } from '../../../../components/grid/ItemViewRow';

export const MerchantCreationSummaryComponent = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const dateFormat = useSelector(dateTimeFormat);
  const dateZone = useSelector(dateTimeZone);
  const timeFormat = (value: string | null) =>
    dateTimeFormatUtil(value, dateFormat, dateZone);
  const timeDateFormat = (value: string | null) =>
    dateTimeFormatUtil(value, 'yyyy-MM-DD', dateZone);
  const merchantInfoItem = useAppSelector(getMerchantCreateInfoItemStatus);
  const merchantRapidConnectItem = useAppSelector(
    getMerchantRapidConnectItemStatus
  );
  const merchantAccountItem = useAppSelector(
    getMerchantAccountHolderItemStatus
  );
  const merchantNMIItem = useAppSelector(getMerchantNMIItemStatus);

  const isRapidConnect =
    merchantInfoItem.gateway?.value === GatewaysValues.RAPID_CONNECT;

  const createNewMerchant = () => {
    const rapidConnectRequest = {
      terminalId: merchantRapidConnectItem.rapidConnectTerminalId,
      groupId: merchantRapidConnectItem.rapidConnectGroupId,
      merchantExternalId: merchantRapidConnectItem.merchantExternalId,
      primaryServiceUrl: merchantRapidConnectItem.rapidConnectPrimaryServiceUrl,
      secondaryServiceUrl:
        merchantRapidConnectItem.rapidConnectSecondaryServiceUrl,
      merchantCategoryCode:
        merchantRapidConnectItem.rapidConnectMerchantCategoryCode,
      ecommerceUrl: merchantRapidConnectItem.rapidConnectEcommerceUrl,
    };

    const internalNMIMerchantCreate = {
      gateway: GatewaysValues.NMI,
      currency: merchantInfoItem.currency?.value,
      countryCode: merchantInfoItem.countryCode?.value,
      state: merchantInfoItem.stateUS
        ? merchantInfoItem.stateUS?.value
        : merchantInfoItem.state,
      name: merchantInfoItem.merchantName,
      username: merchantNMIItem.username,
      feePlan: merchantNMIItem.feePlan,
      address1: merchantInfoItem.address1,
      address2: merchantInfoItem.address2,
      city: merchantInfoItem.city,
      postal: merchantInfoItem.postal,
      contactFirstName: merchantInfoItem.contactFirstName,
      contactLastName: merchantInfoItem.contactLastName,
      contactPhone: merchantInfoItem.contactPhone,
      contactEmail: merchantInfoItem.contactEmail,
      url: merchantInfoItem.url,
      accountNumber: merchantNMIItem.accountNumber,
      routingNumber: merchantNMIItem.routingNumber,
      locale: merchantNMIItem.locale?.value,
      timezoneId: merchantNMIItem.timezoneId?.value,
      accountType: merchantNMIItem.accountType?.value,
      accountHolderType: merchantNMIItem.accountHolderType?.value,
      merchantType: merchantNMIItem.merchantType?.value,
      captureHigherThanAuthed: merchantNMIItem.captureHigherThanAuthed?.value,
      allowPaymentFacilitatorFields:
        merchantNMIItem.allowPaymentFacilitatorFields?.value,
    };

    const internalFISERVMerchantCreate = {
      gateway: GatewaysValues.FISERV,
      currency: merchantInfoItem.currency?.value,
      countryCode: merchantInfoItem.countryCode?.value,
      state: merchantInfoItem.stateUS
        ? merchantInfoItem.stateUS?.value
        : merchantInfoItem.state,
      name: merchantInfoItem.merchantName,
      url: merchantInfoItem.url,
      address1: merchantInfoItem.address1,
      address2: merchantInfoItem.address2,
      city: merchantInfoItem.city,
      postal: merchantInfoItem.postal,
      contactFirstName: merchantInfoItem.contactFirstName,
      contactLastName: merchantInfoItem.contactLastName,
      contactPhone: merchantInfoItem.contactPhone,
      contactEmail: merchantInfoItem.contactEmail,
    };
    const internalMerchantCreate = isRapidConnect
      ? internalFISERVMerchantCreate
      : internalNMIMerchantCreate;

    const rapidConnectMerchantDetails = isRapidConnect
      ? rapidConnectRequest
      : null;

    const merchantAccountHolderDetails = {
      countryCode: merchantAccountItem.accountHolderCountryCode?.value,
      firstName: merchantAccountItem.accountHolderFirstName,
      lastName: merchantAccountItem.accountHolderLastName,
      email: merchantAccountItem.accountHolderEmail,
      phone: merchantAccountItem.accountHolderPhone,
      birthDate: timeDateFormat(merchantAccountItem.accountHolderBirthdate),
      billingAddressCountryCode:
        merchantAccountItem.accountHolderBillingAddressCountryCode?.value,
      billingAddressSubDivisionCode:
        merchantAccountItem.accountHolderBillingAddressSubDivisionCode,
      billingAddressCity: merchantAccountItem.accountHolderBillingAddressCity,
      billingAddressPostalCode:
        merchantAccountItem.accountHolderBillingAddressPostalCode,
      billingAddressAddressLine1:
        merchantAccountItem.accountHolderBillingAddressAddressLine1,
      legal_entity_type: merchantAccountItem.legalEntityType,
    };

    dispatch(
      createMerchant({
        programId: merchantInfoItem.program?.value,
        corporateId: merchantInfoItem.corporate?.value,
        merchant: internalMerchantCreate,
        rapidConnectMerchant: rapidConnectMerchantDetails,
        accountHolder: merchantAccountHolderDetails,
      })
    )
      .unwrap()
      .then((response: any) => {
        history.push(`/system/merchants/details/${Base64.encodeURI(response)}`);
        dispatch(clearMerchantCreate());
      })
      .catch(() => {});
  };

  return (
    <>
      <div className="grid grid-cols-8 xxl:grid-cols-12 gap-4">
        <div className="flex col-span-8 xxl:col-span-12 h-auto bg-white border sm:mx-4 my-4 rounded-md">
          <div className="w-full">
            <div className="flex flex-wrap justify-between">
              <div className="-mt-8 w-full sm:w-auto">
                <div className="flex flex-row items-center justify-start justify-items-start p-4 w-full sm:w-40 h-16 rounded-md sm:ml-3 box-bg-nepal box-shadow-dark">
                  <div>
                    <StorefrontIcon fontSize="large" sx={{ color: '#fff' }} />
                  </div>
                  <div className="text-xs text-white ml-1">
                    {t(
                      'system.merchantCreate.merchantSummaryDetails.merchantInfoDetails.info'
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="grid sm:grid-cols-2 l2g:grid-cols-3 3xl:grid-cols-4 4xl:grid-cols-5 items-center justify-center justify-items-start sm:m-2">
              <ItemViewRow
                labelName={t(
                  'system.merchantCreate.merchantSummaryDetails.merchantInfoDetails.merchantName'
                )}
                isNotSet
                isRequired
                value={merchantInfoItem.merchantName}
              />
              <ItemViewRow
                labelName={t(
                  'system.merchantCreate.merchantSummaryDetails.merchantInfoDetails.url'
                )}
                isNotSet
                value={merchantInfoItem.url}
              />
              <ItemViewRow
                labelName={t(
                  'system.merchantCreate.merchantSummaryDetails.merchantInfoDetails.currency'
                )}
                isRequired
                isNotSet
                value={merchantInfoItem.currency?.label as string}
              />
              <ItemViewRow
                labelName={t(
                  'system.merchantCreate.merchantSummaryDetails.merchantInfoDetails.country'
                )}
                isRequired
                isNotSet
                value={merchantInfoItem.countryCode?.label as string}
              />
              {merchantInfoItem.countryCode &&
                (merchantInfoItem.countryCode.value === 'US' ||
                  countryStateList.includes(
                    merchantInfoItem.countryCode.value
                  )) && (
                  <ItemViewRow
                    labelName={t(
                      'system.merchantCreate.merchantSummaryDetails.merchantInfoDetails.state'
                    )}
                    isNotSet
                    value={
                      merchantInfoItem.stateUS
                        ? merchantInfoItem.stateUS?.label
                        : merchantInfoItem.state
                    }
                  />
                )}
              <ItemViewRow
                labelName={t(
                  'system.merchantCreate.merchantSummaryDetails.merchantInfoDetails.address1'
                )}
                isRequired
                isNotSet
                value={merchantInfoItem.address1}
              />
              <ItemViewRow
                labelName={t(
                  'system.merchantCreate.merchantSummaryDetails.merchantInfoDetails.address2'
                )}
                isNotSet
                value={merchantInfoItem.address2}
              />
              <ItemViewRow
                labelName={t(
                  'system.merchantCreate.merchantSummaryDetails.merchantInfoDetails.city'
                )}
                isRequired
                isNotSet
                value={merchantInfoItem.city}
              />
              <ItemViewRow
                labelName={t(
                  'system.merchantCreate.merchantSummaryDetails.merchantInfoDetails.postal'
                )}
                isRequired
                isNotSet
                value={merchantInfoItem.postal}
              />
              <ItemViewRow
                labelName={t(
                  'system.merchantCreate.merchantSummaryDetails.merchantInfoDetails.contactFirstName'
                )}
                isRequired
                isNotSet
                value={merchantInfoItem.contactFirstName}
              />
              <ItemViewRow
                labelName={t(
                  'system.merchantCreate.merchantSummaryDetails.merchantInfoDetails.contactLastName'
                )}
                isRequired
                isNotSet
                value={merchantInfoItem.contactLastName}
              />
              <ItemViewRow
                labelName={t(
                  'system.merchantCreate.merchantSummaryDetails.merchantInfoDetails.contactPhone'
                )}
                isRequired
                isNotSet
                value={merchantInfoItem.contactPhone}
              />
              <ItemViewRow
                labelName={t(
                  'system.merchantCreate.merchantSummaryDetails.merchantInfoDetails.contactEmail'
                )}
                isRequired
                isNotSet
                value={merchantInfoItem.contactEmail}
              />
              <ItemViewRow
                labelName={t(
                  'system.merchantCreate.merchantSummaryDetails.merchantInfoDetails.corporate'
                )}
                isRequired
                isNotSet
                value={merchantInfoItem.corporate?.label as string}
              />
              <ItemViewRow
                labelName={t(
                  'system.merchantCreate.merchantSummaryDetails.merchantInfoDetails.program'
                )}
                isRequired
                isNotSet
                value={merchantInfoItem.program?.label as string}
              />
            </div>
          </div>
        </div>
        <div className="flex col-span-8 xxl:col-span-12 h-auto bg-white border sm:mx-4 my-4 rounded-md">
          <div className="w-full">
            <div className="flex flex-wrap justify-between">
              <div className="-mt-8 w-full sm:w-auto">
                <div className="flex flex-row items-center justify-start justify-items-start p-4 w-full sm:w-40 h-16 rounded-md sm:ml-3 box-bg-air-force-blue box-shadow-air-force-blue">
                  <div>
                    <IconPayment />
                  </div>
                  <div className="text-xs text-white ml-1">
                    {merchantInfoItem && isRapidConnect
                      ? GatewaysValues.FISERV
                      : GatewaysValues.NMI}{' '}
                    info
                  </div>
                </div>
              </div>
            </div>
            <div className="grid sm:grid-cols-2 l2g:grid-cols-3 3xl:grid-cols-4 4xl:grid-cols-5 items-center justify-center justify-items-start sm:m-4 ">
              {merchantInfoItem && isRapidConnect ? (
                <>
                  <ItemViewRow
                    labelName={t(
                      'system.merchantCreate.merchantSummaryDetails.merchantFISERV.merchantExternalId'
                    )}
                    isRequired
                    isNotSet
                    value={merchantRapidConnectItem.merchantExternalId}
                  />
                  <ItemViewRow
                    labelName={t(
                      'system.merchantCreate.merchantSummaryDetails.merchantFISERV.rapidConnectTerminalId'
                    )}
                    isRequired
                    isNotSet
                    value={merchantRapidConnectItem.rapidConnectTerminalId}
                  />
                  <ItemViewRow
                    labelName={t(
                      'system.merchantCreate.merchantSummaryDetails.merchantFISERV.rapidConnectGroupId'
                    )}
                    isRequired
                    isNotSet
                    value={merchantRapidConnectItem.rapidConnectGroupId}
                  />
                  <ItemViewRow
                    labelName={t(
                      'system.merchantCreate.merchantSummaryDetails.merchantFISERV.rapidConnectPrimaryServiceUrl'
                    )}
                    isRequired
                    isNotSet
                    value={
                      merchantRapidConnectItem.rapidConnectPrimaryServiceUrl
                    }
                  />
                  <ItemViewRow
                    labelName={t(
                      'system.merchantCreate.merchantSummaryDetails.merchantFISERV.rapidConnectSecondaryServiceUrl'
                    )}
                    isNotSet
                    value={
                      merchantRapidConnectItem.rapidConnectSecondaryServiceUrl
                    }
                  />
                  <ItemViewRow
                    labelName={t(
                      'system.merchantCreate.merchantSummaryDetails.merchantFISERV.rapidConnectEcommerceUrl'
                    )}
                    isRequired
                    isNotSet
                    value={merchantRapidConnectItem.rapidConnectEcommerceUrl}
                  />
                  <ItemViewRow
                    labelName={t(
                      'system.merchantCreate.merchantSummaryDetails.merchantFISERV.rapidConnectMerchantCategoryCode'
                    )}
                    isRequired
                    isNotSet
                    value={
                      merchantRapidConnectItem.rapidConnectMerchantCategoryCode
                    }
                  />
                </>
              ) : (
                <>
                  <ItemViewRow
                    labelName={t(
                      'system.merchantCreate.merchantSummaryDetails.merchantNMI.accountNumber'
                    )}
                    isRequired
                    isNotSet
                    value={merchantNMIItem.accountNumber}
                  />
                  <ItemViewRow
                    labelName={t(
                      'system.merchantCreate.merchantSummaryDetails.merchantNMI.routingNumber'
                    )}
                    isRequired
                    isNotSet
                    value={merchantNMIItem.routingNumber}
                  />
                  <ItemViewRow
                    labelName={t(
                      'system.merchantCreate.merchantSummaryDetails.merchantNMI.username'
                    )}
                    isNotSet
                    value={merchantNMIItem.username}
                  />
                  <ItemViewRow
                    labelName={t(
                      'system.merchantCreate.merchantSummaryDetails.merchantNMI.feePlan'
                    )}
                    isRequired
                    isNotSet
                    value={merchantNMIItem.feePlan}
                  />
                  <ItemViewRow
                    labelName={t(
                      'system.merchantCreate.merchantSummaryDetails.merchantNMI.locale'
                    )}
                    isNotSet
                    value={merchantNMIItem.locale?.label as string}
                  />
                  <ItemViewRow
                    labelName={t(
                      'system.merchantCreate.merchantSummaryDetails.merchantNMI.timezoneId'
                    )}
                    isRequired
                    isNotSet
                    value={merchantNMIItem.timezoneId?.label as string}
                  />
                  <ItemViewRow
                    labelName={t(
                      'system.merchantCreate.merchantSummaryDetails.merchantNMI.accountType'
                    )}
                    isNotSet
                    value={merchantNMIItem.accountType?.label as string}
                  />
                  <ItemViewRow
                    labelName={t(
                      'system.merchantCreate.merchantSummaryDetails.merchantNMI.accountHolderType'
                    )}
                    isNotSet
                    value={merchantNMIItem.accountHolderType?.label as string}
                  />
                  <ItemViewRow
                    labelName={t(
                      'system.merchantCreate.merchantSummaryDetails.merchantNMI.merchantType'
                    )}
                    isNotSet
                    value={merchantNMIItem.merchantType?.label as string}
                  />
                  <ItemViewRow
                    labelName={t(
                      'system.merchantCreate.merchantSummaryDetails.merchantNMI.captureHigherThanAuthed'
                    )}
                    isNotSet
                    value={
                      merchantNMIItem.captureHigherThanAuthed?.label as string
                    }
                  />
                  <ItemViewRow
                    labelName={t(
                      'system.merchantCreate.merchantSummaryDetails.merchantNMI.allowPaymentFacilitatorFields'
                    )}
                    isNotSet
                    value={
                      merchantNMIItem.captureHigherThanAuthed?.label as string
                    }
                  />
                </>
              )}
            </div>
          </div>
        </div>
        <div className="flex col-span-8 xxl:col-span-12 h-auto bg-white border sm:mx-4 my-4 rounded-md">
          <div className="w-full">
            <div className="flex flex-wrap justify-between">
              <div className="-mt-8 w-full sm:w-auto">
                <div className="flex flex-row items-center justify-start justify-items-start p-4 w-full sm:w-40 h-16 rounded-md sm:ml-3 box-bg-hippie-blue-300 box-shadow-neon-blue">
                  <div>
                    <IconBankAccount />
                  </div>
                  <div className="text-xs text-white ml-1">
                    {t(
                      'system.merchantCreate.merchantSummaryDetails.merchantAccountHolderInfo.info'
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="grid sm:grid-cols-2 l2g:grid-cols-3 3xl:grid-cols-4 4xl:grid-cols-5 items-center justify-center justify-items-start sm:m-4">
              <ItemViewRow
                labelName={t(
                  'system.merchantCreate.merchantSummaryDetails.merchantAccountHolderInfo.accountHolderFirstName'
                )}
                isRequired
                isNotSet
                value={merchantAccountItem.accountHolderFirstName}
              />
              <ItemViewRow
                labelName={t(
                  'system.merchantCreate.merchantSummaryDetails.merchantAccountHolderInfo.accountHolderLastName'
                )}
                isRequired
                isNotSet
                value={merchantAccountItem.accountHolderLastName}
              />
              <ItemViewRow
                labelName={t(
                  'system.merchantCreate.merchantSummaryDetails.merchantAccountHolderInfo.accountHolderPhone'
                )}
                isRequired
                isNotSet
                value={merchantAccountItem.accountHolderPhone}
              />
              <ItemViewRow
                labelName={t(
                  'system.merchantCreate.merchantSummaryDetails.merchantAccountHolderInfo.accountHolderEmail'
                )}
                isRequired
                isNotSet
                value={merchantAccountItem.accountHolderEmail}
              />
              <ItemViewRow
                labelName={t(
                  'system.merchantCreate.merchantSummaryDetails.merchantAccountHolderInfo.accountHolderBirthdate'
                )}
                isRequired
                isNotSet
                value={timeFormat(merchantAccountItem.accountHolderBirthdate)}
              />
              <ItemViewRow
                labelName={t(
                  'system.merchantCreate.merchantSummaryDetails.merchantAccountHolderInfo.accountHolderCountryCode'
                )}
                isRequired
                isNotSet
                value={
                  merchantAccountItem.accountHolderCountryCode?.label as string
                }
              />
              <ItemViewRow
                labelName={t(
                  'system.merchantCreate.merchantSummaryDetails.merchantAccountHolderInfo.accountHolderBillingAddressCountryCode'
                )}
                isRequired
                isNotSet
                value={
                  merchantAccountItem.accountHolderBillingAddressCountryCode
                    ?.label as string
                }
              />
              <ItemViewRow
                labelName={t(
                  'system.merchantCreate.merchantSummaryDetails.merchantAccountHolderInfo.accountHolderBillingAddressCity'
                )}
                isRequired
                isNotSet
                value={merchantAccountItem.accountHolderBillingAddressCity}
              />
              <ItemViewRow
                labelName={t(
                  'system.merchantCreate.merchantSummaryDetails.merchantAccountHolderInfo.accountHolderBillingAddressAddressLine1'
                )}
                isRequired
                isNotSet
                value={
                  merchantAccountItem.accountHolderBillingAddressAddressLine1
                }
              />
              <ItemViewRow
                labelName={t(
                  'system.merchantCreate.merchantSummaryDetails.merchantAccountHolderInfo.accountHolderBillingAddressPostalCode'
                )}
                isRequired
                isNotSet
                value={
                  merchantAccountItem.accountHolderBillingAddressPostalCode
                }
              />
              <ItemViewRow
                labelName={t(
                  'system.merchantCreate.merchantSummaryDetails.merchantAccountHolderInfo.accountHolderBillingAddressSubDivisionCode'
                )}
                isRequired
                isNotSet
                value={
                  merchantAccountItem.accountHolderBillingAddressSubDivisionCode
                }
              />
              <ItemViewRow
                labelName={t(
                  'system.merchantCreate.merchantSummaryDetails.merchantAccountHolderInfo.legalEntityType'
                )}
                isRequired
                isNotSet
                value={merchantAccountItem.legalEntityType}
              />
            </div>
          </div>
        </div>
      </div>
      <MerchantCreateSteps
        isNextStepDisabled={false}
        onClick={createNewMerchant}
      />
    </>
  );
};
