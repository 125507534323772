import { FiltersParams, MerchantItem } from 'entities/system';
import { ItemAttributes } from 'components/itemAttributeType';

export const normalizeChargeback = (chargeback: any): MerchantItem => ({
  ...chargeback,
});

export const defaultFilters: FiltersParams = {
  status: [] as ItemAttributes[],
  merchantName: '',
  programsIds: [] as ItemAttributes[],
  processor: [] as ItemAttributes[],
};

export const accountHoldersDetails = {
  countryCode: '',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  legal_entity_type: '',
  birthDate: '',
  billingAddressCountryCode: '',
  billingAddressSubDivisionCode: '',
  billingAddressCity: '',
  billingAddressPostalCode: '',
  billingAddressAddressLine1: '',
};

export const rapidConnectMerchantDetails = {
  id: '',
  merchantId: '',
  terminalId: '',
  groupId: '',
  datawireId: '',
  primaryServiceUrl: '',
  secondaryServiceUrl: '',
  ecommerceUrl: '',
  merchantCategoryCode: '',
  status: 'UNREGISTERED',
};

export const reportsConfigDetails = {
  reports: [] as string[],
  storageTypes: [] as string[],
  folderName: '',
  updatedAt: '',
  createdAt: '',
};

export const merchantStatusDetails = {
  changeStatus: '',
  currentStatus: '',
  fromDateTime: '',
  reason: '',
};

export const merchantLimitsDetails = {
  //+++Annual value+++
  rollingAnnualTransactionVolumeLimit: null,
  rollingAnnualTransactionVolumeLimitNotice: null,
  rollingAnnualTransactionVolumeLimitBlock: null,
  //--------
  //---daily---
  rolling24hTransactionVolumeLimit: null,
  rolling24hTransactionVolumeLimitNotice: null,
  rolling24hTransactionVolumeLimitBlock: null,
  //----7 days---
  rolling7DaysTransactionVolumeLimit: null,
  rolling7DaysTransactionVolumeLimitNotice: null,
  rolling7DaysTransactionVolumeLimitBlock: null,
  //----30 days---
  rolling30DaysTransactionVolumeLimit: null,
  rolling30DaysTransactionVolumeLimitNotice: null,
  rolling30DaysTransactionVolumeLimitBlock: null,
  //+++refund+++
  rollingAnnualRefundVolumePercentLimit: null,
  rollingAnnualRefundVolumeLimit: null,
  rollingAnnualRefundPercentLimitNotice: null,
  rollingAnnualRefundLimitNotice: null,
  rollingAnnualRefundPercentLimitBlock: null,
  rollingAnnualRefundLimitBlock: null,
  //===============
  //+++Chargeback+++
  rollingAnnualChargebackVolumePercentLimit: null,
  rollingAnnualChargebackVolumeLimit: null,
  rollingAnnualChargebackPercentLimitNotice: null,
  rollingAnnualChargebackLimitNotice: null,
  rollingAnnualChargebackPercentLimitBlock: null,
  rollingAnnualChargebackLimitBlock: null,
  //===============
  rolling24hRefundVolumeLimitNotice: null,
  rolling24hRefundVolumeLimitBlock: null,
  rolling7DaysRefundVolumeLimitNotice: null,
  rolling7DaysRefundVolumeLimitBlock: null,
  rolling30DaysRefundVolumeLimitNotice: null,
  rolling30DaysRefundVolumeLimitBlock: null,
};

export const merchantsConfigDetails = {
  clientVertical: '',
  creditLimit: null,
  approvalDate: null,
  expiryDate: null,
  internalRiskRating: null,
  merchantAdvisedLowestTicket: null,
  merchantAdvisedAverageTicket: null,
  merchantAdvisedHighestTicket: null,
  merchantAdvisedDailyTransactionNumber: null,
  merchantAdvisedDailyTransactionVolume: null,
  merchantAdvisedRefundRatio: null,
  merchantAdvisedChargebackRatio: null,
  merchantAdvisedChargebackDays: null,
  merchantAdvisedDeliveryDays: null,
  expectedMonthlyVolume: null,
  merchantHighMonthlyVolumePercentToFlag: null,
  merchantLowMonthlyVolumePercentToFlag: null,
  status: '',
  expectedMonthlyTransactionNumber: null,
  highRiskAccount: false,
  highRiskAccountDescription: '',
  createdAt: '',
  merchantDailyTransactionNumberLimitPercent: null,
  merchantDailyTransactionVolumeLimitPercent: null,
  dailyTicketAverage: null,
  dailyTicketVolumeLimitPercent: null,
  advisedDailyRefundsNumber: null,
  advisedDailyRefundsVolume: null,
  advisedDailyRefundsVolumeRatio: null,
  advisedDailyRefundsNumberLimitPercent: null,
  advisedDailyChargebacksNumberRatio: null,
  advisedDailyChargebacksNumber: null,
  advisedDailyChargebacksCountLimitPercent: null,
  advisedDailyChargebacksVolume: null,
  updatedAt: '',
  expectedMonthlyTransactionsNumber: null,
  advisedDailyRefundsNumberRatio: null,
  merchantAdvisedLowestTicketCount: null,
  advisedRepeatedCardNumberCount: null,
  advisedRepeatedCardNumberVolume: null,
  advisedRepeatedBankAccountNumberCount: null,
  advisedRepeatedBankAccountNumberVolume: null,
  advisedRepeatedIpAddressCount: null,
  advisedRepeatedIpAddressVolume: null,
  advisedRepeatedCardNumberUtilizedCount: null,
  advisedRepeatedCardNumberUtilizedVolume: null,
  advisedBeginningOperatingHour: null,
  advisedEndingOperatingHour: null,
  advisedTransactionsOutsideOperatingHoursCount: null,
  advisedTransactionsOutsideOperatingHoursVolume: null,
  advisedRejectedCheckedTransactionVolume: null,
  advisedRejectedCheckedTransactionCount: null,
};

export const details = {
  id: '',
  name: '',
  logoUrl: '',
  updatedTime: '',
  corporateId: '',
  programId: '',
  programName: '',
  externalMerchantId: '',
  processorName: '',
  address1: '',
  address2: '',
  city: '',
  state: null,
  postal: '',
  url: null,
  timezoneId: '',
  accountNumber: null,
  routingNumber: null,
  username: '',
  feePlan: '',
  externalIdentifier: '',
  locale: '',
  currency: '',
  contactFirstName: '',
  contactLastName: '',
  contactPhone: '',
  contactEmail: '',
  accountType: null,
  accountHolderType: null,
  merchantType: null,
  captureHigherThanAuthed: null,
  allowPaymentFacilitatorFields: null,
  createdTime: '',
  countryCode: '',
  accountHolder: accountHoldersDetails,
  rapidConnectMerchant: rapidConnectMerchantDetails,
  reportsConfiguration: reportsConfigDetails,
  merchantConfiguration: merchantsConfigDetails,
  merchantStatusDetails: merchantStatusDetails,
  merchantLimits: merchantLimitsDetails,
};

export const listRequestPayload = (options: FiltersParams) => ({
  name: options.merchantName || undefined,
  programsIds: options.programsIds?.map((value) => value.value),
  processorIds: options.processor?.map((value) => value.value),
  status: options.status?.map((value) => value.value),
});

export enum MerchantStatuses {
  ACTIVE = 'ACTIVE',
  APPROVED = 'APPROVED',
  SUSPENDED = 'SUSPENDED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  BLOCKED = 'BLOCKED',
  UNKNOWN = 'UNKNOWN',
}

export enum GatewaysValues {
  FISERV = 'FISERV',
  NMI = 'NMI',
  RAPID_CONNECT = 'RAPID_CONNECT',
}

export const merchantStatusItems: ItemAttributes[] = [
  { label: 'ACTIVE', value: MerchantStatuses.ACTIVE },
  { label: 'APPROVED', value: MerchantStatuses.APPROVED },
  { label: 'PENDING', value: MerchantStatuses.PENDING },
  { label: 'SUSPENDED', value: MerchantStatuses.SUSPENDED },
  { label: 'REJECTED', value: MerchantStatuses.REJECTED },
  { label: 'BLOCKED', value: MerchantStatuses.BLOCKED },
];

export const getMerchantDetailsIconColor = (status: string | null) => {
  switch (status) {
    case MerchantStatuses.ACTIVE:
      return 'box-bg-green-300 box-shadow-green';
    case MerchantStatuses.SUSPENDED:
      return 'box-bg-red-300 box-shadow-red';
    case MerchantStatuses.APPROVED:
      return 'box-bg-gossip-300 box-shadow-gossip';
    case MerchantStatuses.PENDING:
      return 'box-bg-orange-300 box-shadow-orange';
    case MerchantStatuses.REJECTED:
      return 'box-bg-bright-red-300 box-shadow-bright-red';
    case MerchantStatuses.BLOCKED:
      return 'box-bg-night-rider-300 box-shadow-night-rider';
    case null:
      return 'box-bg-primary-400 box-shadow-primary';
    default:
      return 'box-bg-grey-300 box-shadow-grey';
  }
};

export const localeList = [
  { label: 'English (United States)', value: 'en_US' },
  { label: 'French (Canada)', value: 'fr_CA' },
  { label: 'Spanish (Spain)', value: 'es_ES' },
  { label: 'Spanish (Panama)', value: 'es_PA' },
  { label: 'Spanish (Costa Rica)', value: 'es_CR' },
];

export const stateList = [
  { label: 'Alabama', value: 'AL' },
  { label: 'Alaska', value: 'AK' },
  { label: 'Arizona', value: 'AZ' },
  { label: 'Arkansas', value: 'AR' },
  { label: 'California', value: 'CA' },
  { label: 'Colorado', value: 'CO' },
  { label: 'Connecticut', value: 'CT' },
  { label: 'Delaware', value: 'DE' },
  { label: 'District of Columbia', value: 'DC' },
  { label: 'Florida', value: 'FL' },
  { label: 'Georgia', value: 'GA' },
  { label: 'Hawaii', value: 'HI' },
  { label: 'Idaho', value: 'ID' },
  { label: 'Illinois', value: 'IL' },
  { label: 'Indiana', value: 'IN' },
  { label: 'Iowa', value: 'IA' },
  { label: 'Kansas', value: 'KS' },
  { label: 'Kentucky', value: 'KY' },
  { label: 'Louisiana', value: 'LA' },
  { label: 'Maine', value: 'ME' },
  { label: 'Maryland', value: 'MD' },
  { label: 'Massachusetts', value: 'MA' },
  { label: 'Michigan', value: 'MI' },
  { label: 'Minnesota', value: 'MN' },
  { label: 'Mississippi', value: 'MS' },
  { label: 'Missouri', value: 'MO' },
  { label: 'Montana', value: 'MT' },
  { label: 'Nebraska', value: 'NE' },
  { label: 'Nevada', value: 'NV' },
  { label: 'New Hampshire', value: 'NH' },
  { label: 'New Jersey', value: 'NJ' },
  { label: 'New Mexico', value: 'NM' },
  { label: 'New York', value: 'NY' },
  { label: 'North Carolina', value: 'NC' },
  { label: 'North Dakota', value: 'ND' },
  { label: 'Ohio', value: 'OH' },
  { label: 'Oklahoma', value: 'OK' },
  { label: 'Oregon', value: 'OR' },
  { label: 'Pennsylvania', value: 'PA' },
  { label: 'Rhode Island', value: 'RI' },
  { label: 'South Carolina', value: 'SC' },
  { label: 'South Dakota', value: 'SD' },
  { label: 'Tennessee', value: 'TN' },
  { label: 'Texas', value: 'TX' },
  { label: 'Utah', value: 'UT' },
  { label: 'Vermont', value: 'VT' },
  { label: 'Virginia', value: 'VA' },
  { label: 'Washington', value: 'WA' },
  { label: 'West Virginia', value: 'WV' },
  { label: 'Wisconsin', value: 'WI' },
  { label: 'Wyoming', value: 'WY' },
];

export const timeZoneList = [
  { label: '(GMT) Universal Time, Coordinated', value: '0' },
  { label: '(GMT-05:00) Eastern Time (US & Canada)', value: '1' },
  { label: '(GMT-05:00) Indiana (East)', value: '2' },
  { label: '(GMT-06:00) Central Time (US & Canada)', value: '3' },
  { label: '(GMT-07:00) Mountain Time (US & Canada)', value: '4' },
  { label: '(GMT-07:00) Arizona', value: '5' },
  { label: '(GMT-08:00) Pacific Time (US & Canada)', value: '6' },
  { label: '(GMT-09:00) Alaska', value: '7' },
  { label: '(GMT-10:00) Hawaii', value: '8' },
  { label: '(GMT-12:00) International Date Line West', value: '9' },
  { label: '(GMT-11:00) Midway Island, Samoa', value: '10' },
  { label: '(GMT-08:00) Tijuana, Baja California', value: '11' },
  { label: '(GMT-07:00) Chihuahua, La Paz, Mazatlan', value: '12' },
  { label: '(GMT-06:00) Saskatchewan', value: '13' },
  { label: '(GMT-06:00) Central America', value: '14' },
  { label: '(GMT-06:00) Guadalajara, Mexico City, Monterrey', value: '15' },
  { label: '(GMT-05:00) Bogota, Lima, Quito, Rio Branco', value: '16' },
  { label: '(GMT-04:30) Caracas', value: '17' },
  { label: '(GMT-04:00) Atlantic Time (Canada)', value: '18' },
  { label: '(GMT-04:00) Manaus', value: '19' },
  { label: '(GMT-04:00) Santiago', value: '20' },
  { label: '(GMT-04:00) La Paz', value: '21' },
  { label: '(GMT-03:30) Newfoundland', value: '22' },
  { label: '(GMT-03:00) Buenos Aires', value: '23' },
  { label: '(GMT-03:00) Brasilia', value: '24' },
  { label: '(GMT-03:00) Greenland', value: '25' },
  { label: '(GMT-03:00) Montevideo', value: '26' },
  { label: '(GMT-03:00) Georgetown', value: '27' },
  { label: '(GMT-02:00) Mid-Atlantic', value: '28' },
  { label: '(GMT-01:00) Azores', value: '29' },
  { label: '(GMT-01:00) Cape Verde Is.', value: '30' },
  { label: '(GMT) Monrovia, Reykjavik', value: '31' },
  { label: '(GMT) Casablanca', value: '32' },
  {
    label: '(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague',
    value: '33',
  },
  { label: '(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb', value: '34' },
  { label: '(GMT+01:00) Brussels, Copenhagen, Madrid, Paris', value: '35' },
  { label: '(GMT+01:00) West Central Africa', value: '36' },
  {
    label: '(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
    value: '37',
  },
  { label: '(GMT+02:00) Minsk', value: '38' },
  { label: '(GMT+02:00) Cairo', value: '39' },
  {
    label: '(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
    value: '40',
  },
  { label: '(GMT+02:00) Athens, Bucharest, Istanbul', value: '41' },
  { label: '(GMT+02:00) Jerusalem', value: '42' },
  { label: '(GMT+02:00) Amman', value: '43' },
  { label: '(GMT+02:00) Beirut', value: '44' },
  { label: '(GMT+02:00) Windhoek', value: '45' },
  { label: '(GMT+02:00) Harare, Pretoria', value: '46' },
  { label: '(GMT+03:00) Kuwait, Riyadh', value: '47' },
  { label: '(GMT+03:00) Baghdad', value: '48' },
  { label: '(GMT+03:00) Nairobi', value: '49' },
  { label: '(GMT+03:00) Tbilisi', value: '50' },
  { label: '(GMT+03:00) Moscow, St. Petersburg, Volgograd', value: '51' },
  { label: '(GMT+03:30) Tehran', value: '52' },
  { label: '(GMT+04:00) Abu Dhabi, Muscat', value: '53' },
  { label: '(GMT+04:00) Baku', value: '54' },
  { label: '(GMT+04:00) Yerevan', value: '55' },
  { label: '(GMT+05:00) Ekaterinburg', value: '56' },
  { label: '(GMT+05:00) Islamabad, Karachi', value: '57' },
  { label: '(GMT+05:00) Tashkent', value: '58' },
  { label: '(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi', value: '59' },
  { label: '(GMT+05:30) Sri Jayawardenepura', value: '60' },
  { label: '(GMT+06:00) Astana, Dhaka', value: '61' },
  { label: '(GMT+06:00) Almaty, Novosibirsk', value: '62' },
  { label: '(GMT+06:30) Yangon (Rangoon)', value: '63' },
  { label: '(GMT+07:00) Krasnoyarsk', value: '64' },
  { label: '(GMT+07:00) Bangkok, Hanoi, Jakarta', value: '65' },
  { label: '(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi', value: '66' },
  { label: '(GMT+08:00) Irkutsk, Ulaan Bataar', value: '67' },
  { label: '(GMT+08:00) Kuala Lumpur, Singapore', value: '68' },
  { label: '(GMT+08:00) Taipei', value: '69' },
  { label: '(GMT+08:00) Perth', value: '70' },
  { label: '(GMT+09:00) Seoul', value: '71' },
  { label: '(GMT+09:00) Osaka, Sapporo, Tokyo', value: '72' },
  { label: '(GMT+09:00) Yakutsk', value: '73' },
  { label: '(GMT+09:30) Darwin', value: '74' },
  { label: '(GMT+09:30) Adelaide', value: '75' },
  { label: '(GMT+10:00) Canberra, Melbourne, Sydney', value: '76' },
  { label: '(GMT+10:00) Brisbane', value: '77' },
  { label: '(GMT+10:00) Hobart', value: '78' },
  { label: '(GMT+10:00) Vladivostok', value: '79' },
  { label: '(GMT+10:00) Guam, Port Moresby', value: '80' },
  { label: '(GMT+11:00) Magadan, Solomon Is., New Caledonia', value: '81' },
  { label: '(GMT+12:00) Fiji, Kamchatka, Marshall Is.', value: '82' },
  { label: '(GMT+12:00) Auckland, Wellington', value: '83' },
  { label: '(GMT+13:00) Nukualofa', value: '84' },
  {
    label: '(GMT) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London',
    value: '85',
  },
];

export const accountTypeList = [
  { label: 'Checking', value: 'checking' },
  { label: 'Savings', value: 'savings' },
];

export const accountHolderTypeList = [
  { label: 'Business', value: 'business' },
  { label: 'Personal', value: 'personal' },
];

export const merchantTypeList = [
  { label: 'Gateway', value: 'gateway' },
  { label: 'Split', value: 'split' },
];

export const reportsTypeList = [
  {
    label: 'Merchant Transaction Report',
    value: 'MERCHANT_TRANSACTION_REPORT',
  },
  { label: 'Internal Portfolio Report', value: 'INTERNAL_PORTFOLIO_REPORT' },
  {
    label: 'Monthly Excessive Activity Reporting',
    value: 'MONTHLY_EXCESSIVE_ACTIVITY_REPORTING',
  },
  { label: 'General Account Data', value: 'GENERAL_ACCOUNT_DATA_REPORT' },
  {
    label: 'Daily Exception Reports - Excessive Activity',
    value: 'DAILY_EXCEPTION_REPORT_EXCESSIVE_ACTIVITY',
  },
  {
    label: 'Daily Exception Reports - Refund Exceptions',
    value: 'DAILY_EXCEPTION_REPORT_REFUND_EXCEPTIONS',
  },
  {
    label: 'Daily Exception Reports - Excessive Activity Live',
    value: 'DAILY_EXCEPTION_REPORT_EXCESSIVE_ACTIVITY_LIVE',
  },
  {
    label: 'Portfolio Transaction Summary',
    value: 'PORTFOLIO_TRANSACTION_SUMMARY',
  },
  { label: 'Daily Transaction Summary', value: 'DAILY_TRANSACTION_SUMMARY' },
  { label: 'Daily Transactions Report', value: 'DAILY_TRANSACTIONS_REPORT' },
  {
    label: 'Daily Excessive Activity Reporting',
    value: 'DAILY_EXCESSIVE_ACTIVITY_REPORTING',
  },
  {
    label: 'Daily Report Batch Exceptions',
    value: 'DAILY_REPORT_BATCH_EXCEPTIONS',
  },
  {
    label: 'Daily Report Ticket Exceptions',
    value: 'DAILY_REPORT_TICKET_EXCEPTIONS',
  },
  {
    label: 'Daily Duplicate Transaction',
    value: 'DAILY_DUPLICATE_TRANSACTION',
  },
];

export const storageTypeList = [
  { label: 'S3', value: 'S3' },
  { label: 'SFTP', value: 'SFTP' },
];

export const internalRiskRatingList = [
  { label: 'High', value: 'High' },
  { label: 'Medium', value: 'Medium' },
  { label: 'Low', value: 'Low' },
  { label: 'Watchlist', value: 'Watchlist' },
];

export const conditionResultList = [
  { label: 'True', value: 'true' },
  { label: 'False', value: 'false' },
];

export const merchantStatusList = [
  { label: MerchantStatuses.ACTIVE, value: MerchantStatuses.ACTIVE },
  { label: MerchantStatuses.SUSPENDED, value: MerchantStatuses.SUSPENDED },
  { label: MerchantStatuses.BLOCKED, value: MerchantStatuses.BLOCKED },
];

export const gatewaysList = [
  { label: GatewaysValues.NMI, value: GatewaysValues.NMI },
  { label: GatewaysValues.FISERV, value: GatewaysValues.RAPID_CONNECT },
];

export const rapidConnectStatusList = [
  { label: 'UNREGISTERED', value: 'UNREGISTERED' },
  { label: 'PENDING REGISTRATION', value: 'PENDING_REGISTRATION' },
  { label: 'REGISTERED', value: 'REGISTERED' },
  { label: 'ACTIVATED', value: 'ACTIVATED' },
  { label: 'FAILED TO ACTIVATE', value: 'FAILED_TO_ACTIVATE' },
  { label: 'FAILED TO REGISTER', value: 'FAILED_TO_REGISTER' },
];

export const countryStateList = [
  'AU',
  'AT',
  'BR',
  'DE',
  'IN',
  'MY',
  'MX',
  'FM',
  'MM',
  'NZ',
  'NG',
  'PW',
  'SS',
];

export const selectClassLabel = 'font-medium text-gray-700 text-xxs';

export const isStateDisabled = (country: string) =>
  !countryStateList.includes(country);
