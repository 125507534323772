import { useMemo } from 'react';
import { CellLight, ColumnProps } from 'components/table/Table';
import { useSelector } from 'react-redux';
import { dateTimeFormat, dateTimeZone } from 'domain/account/selectors';
import {
  dateTimeFormatUtil,
  timeFormatUtil,
} from 'components/dateTimeFormatUtil';
import { LogItem } from 'entities/logs';

export const useColumns = () => {
  const dateFormat = useSelector(dateTimeFormat);
  const dateZone = useSelector(dateTimeZone);
  const timeFormat = (value: string) =>
    dateTimeFormatUtil(value, dateFormat + timeFormatUtil, dateZone);
  return useMemo(
    (): ColumnProps[] => [
      {
        id: 'reference',
        value: (item: LogItem) => <CellLight>{item.reference}</CellLight>,
        label: 'Reference',
        isSorted: true,
        sortField: 'API_LOGS_REFERENCE',
        width: 320,
      },
      {
        id: 'merchant',
        value: (item: LogItem) => <CellLight>{item.merchantName}</CellLight>,
        label: 'Merchant Name',
        width: 170,
      },
      {
        id: 'eventId',
        value: (item: LogItem) => <CellLight>{item.eventId}</CellLight>,
        label: 'Event Id',
        width: 320,
      },
      {
        id: 'eventType',
        value: (item: LogItem) => (
          <CellLight>{item.eventType?.replaceAll(/(_)/g, ' ')}</CellLight>
        ),
        label: 'Event Type',
        width: 220,
      },
      {
        id: 'apiGroup',
        value: (item: LogItem) => <CellLight>{item.apiGroup}</CellLight>,
        label: 'Api Group',
        width: 170,
      },
      {
        id: 'type',
        value: (item: LogItem) => (
          <CellLight>{item.type?.replaceAll(/(_)/g, '-')}</CellLight>
        ),
        label: 'Type',
        isSorted: true,
        sortField: 'API_LOGS_TYPE',
        width: 150,
      },
      {
        id: 'amount',
        value: (item: LogItem) => <CellLight>{item.amount}</CellLight>,
        label: 'Amount',
        width: 90,
      },
      {
        id: 'terminalId',
        value: (item: LogItem) => <CellLight>{item.terminalId}</CellLight>,
        label: 'Terminal Id',
        width: 150,
      },
      {
        id: 'createdAt',
        value: (item: LogItem) => (
          <CellLight>
            {item.createdTime && timeFormat(item.createdTime)}
          </CellLight>
        ),
        label: 'Created At',
        isSorted: true,
        sortField: 'API_LOGS_CREATED_AT',
        width: 200,
      },
      {
        id: 'transactionDate',
        value: (item: LogItem) => (
          <CellLight>
            {item.transactionDate && timeFormat(item.transactionDate)}
          </CellLight>
        ),
        label: 'Transaction Date',
        isSorted: false,
        sortField: 'API_LOGS_CREATED_AT',
        width: 200,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
};
