import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import { rowsPerPageOptions } from 'utils/rowsPerPageOption';
import { LoadingSwitch } from 'components/Loading';
import { useAppDispatch } from 'hooks/redux';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

export type PaginationProps = {
  total: number;
  page: number;
  rowsPerPage: number;
  endReached: boolean;
  paginationLoading: boolean;
  itemsLoading: boolean;
};

type TableFooterProps = {
  pagination: PaginationProps;
  setPage: any;
  totalNumber: any;
  setRowsPerPage: any;
};

const TableFooter: FC<TableFooterProps> = ({
  pagination,
  setPage,
  totalNumber = null,
  setRowsPerPage,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [disabled, setDisabled] = useState<boolean>(true);

  const { rowsPerPage, paginationLoading, itemsLoading } = pagination;

  useEffect(() => {
    if (disabled && !paginationLoading && !itemsLoading) {
      setDisabled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationLoading, itemsLoading]);

  const handleRowsPerPageChange = (event: any, option: any) => {
    if (rowsPerPage !== option.value) {
      dispatch(setRowsPerPage(option.value));
    }
  };

  const handleNumberPerPageChange = (event: any, option: any) => {
    dispatch(setPage(option));
  };

  const activeRowsPerPage = useMemo(
    () => rowsPerPageOptions.find((item) => item.value === rowsPerPage),
    [rowsPerPage]
  );

  const activePages = useMemo(
    () => Math.ceil(totalNumber / rowsPerPage),
    [rowsPerPage, totalNumber]
  );

  return (
    <div
      className="relative flex items-center justify-between p-2 py-3 -mt-px border-t border-gray-300"
      style={{ background: '#F0F4F8' }}
    >
      <div className="items-center hidden text-sm text-black md:flex">
        {t('kyc.table.results')}
        <span className="ml-1">
          <LoadingSwitch loading={itemsLoading || paginationLoading}>
            <span className="font-medium">{totalNumber}</span>
          </LoadingSwitch>
        </span>
      </div>
      {totalNumber ? (
        <div>
          <Stack spacing={2}>
            <Pagination
              variant="outlined"
              color="primary"
              shape="rounded"
              disabled={itemsLoading}
              count={activePages}
              onChange={handleNumberPerPageChange}
              showFirstButton
              showLastButton
            />
          </Stack>
        </div>
      ) : null}
      <div className="items-center hidden md:flex gap-2">
        <span className="text-sm text-black">{t('kyc.table.rowsPerPage')}</span>
        <div className="ml-3">
          <Select
            onChange={handleRowsPerPageChange}
            disabled={itemsLoading || !totalNumber}
            value={activeRowsPerPage}
          >
            {rowsPerPageOptions.map((e) => (
              <Option key={e.value} value={e}>
                {e.label}
              </Option>
            ))}
          </Select>
        </div>
      </div>
    </div>
  );
};

export default TableFooter;
