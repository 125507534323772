import { useMemo } from 'react';
import { CellLight, ColumnProps } from 'components/table/Table';
import { CardPanItem } from '../utils';
import { useSelector } from 'react-redux';
import { dateTimeFormat, dateTimeZone } from 'domain/account/selectors';
import moment from 'moment/moment';
import {
  dateFormatUtil,
  dateTimeFormatUtil,
  timeFormatUtil,
} from 'components/dateTimeFormatUtil';

export const useColumns = () => {
  const dateFormat = useSelector(dateTimeFormat);
  const dateZone = useSelector(dateTimeZone);

  const zone = useMemo(
    () => (dateZone ? dateZone : moment.tz.guess()),
    [dateZone]
  );

  const format = useMemo(
    () => (dateFormat ? dateFormat : dateFormatUtil),
    [dateFormat]
  );

  const timeFormat = (value: string) =>
    dateTimeFormatUtil(value, format + timeFormatUtil, zone);
  return useMemo(
    (): ColumnProps[] => [
      {
        id: 'cardNumber',
        label: 'PAN/Card Number',
        value: (item: CardPanItem) => (
          <CellLight>
            {item.cardNumber.replace(/(.{4})/g, '$1 ').trim()}
          </CellLight>
        ),
      },
      {
        id: 'blockedOn',
        label: 'Blocked On',
        value: (item: CardPanItem) => (
          <CellLight>{timeFormat(item.blockedOn)}</CellLight>
        ),
        isSorted: true,
        sortField: 'BLOCKED_CARD_NUMBER_BLOCKED_ON',
      },
      {
        id: 'paymentAttempts',
        label: 'Payment Attempts',
        value: (item: CardPanItem) => (
          <CellLight>{item.paymentAttempts}</CellLight>
        ),
        isSorted: true,
        sortField: 'BLOCKED_CARD_NUMBER_PAYMENT_ATTEMPTS',
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
};
